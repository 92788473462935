import React, { ReactNode } from "react"

type PanelActionStyle = "outline" | "well" | "breakout" | "none"
type PanelActionVariant = "grey" | "danger" | "primary"

export interface PanelActionProps {
    /**
     * Descriptive text of the action.
     */
    description: string

    /**
     * Style of the PanelAction component (default none).
     */
    style?: PanelActionStyle

    /**
     * Color of the PanelAction component (default grey).
     */
    variant?: PanelActionVariant

    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    children: ReactNode
}

/**
 * A PanelAction component, consisting of some text and an action.
 */
export default function PanelAction ( props: PanelActionProps ): JSX.Element {

    const style: PanelActionStyle = props.style || "none"
    const variant: PanelActionVariant = props.variant || "grey"
    let classes = "flex justify-between"
    if ( style === "outline" ) {
        const classMap: {[variant: string]: string} = {
            grey:    " items-center border border-gray-200 rounded-md p-5 text-gray-800",
            danger:  " items-center border border-red-200 rounded-md p-5 text-red-600",
            primary: " items-center border border-brand-200 rounded-md p-5 text-brand-600",
        }
        classes += classMap[variant]
    }
    if ( style === "well" ) {
        const classMap: {[variant: string]: string} = {
            grey:    " items-center bg-gray-50 rounded-md p-5 text-gray-800",
            danger:  " items-center bg-red-100 rounded-md p-5 text-red-700",
            primary: " items-center bg-brand-100 rounded-md p-5 text-brand-700",
        }
        classes += classMap[variant]
    }
    if ( style === "breakout" ) {
        const classMap: {[variant: string]: string} = {
            grey:    " items-center my-6 -mx-4 sm:-mx-6 bg-gray-50 py-4 px-6 border-b border-t border-gray-200 text-gray-800 text-sm",
            danger:  " items-center my-6 -mx-4 sm:-mx-6 bg-red-50 py-4 px-6 border-b border-t border-gray-200 text-red-800 text-sm",
            primary: " items-center my-6 -mx-4 sm:-mx-6 bg-brand-50 py-4 px-6 border-b border-t border-gray-200 text-brand-800 text-sm",
        }
        classes += classMap[variant]
    }
    if ( props.className ) {
        classes += ` ${props.className}`
    }

    return (
        <div className={classes}>
            <span className="max-w-md lg:max-w-xl xl:max-w-2xl">{props.description}</span>
            {props.children}
        </div>
    )
}
