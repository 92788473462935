import { Response, useHttpClient } from "modules/api-client/src/http-client"
import { UUID } from "modules/api-client/src/http-client/src/types"
import { CreateTransferRequest } from "modules/api-client/src/transfers/requests/create-request"
import { AcceptTransferResponse } from "modules/api-client/src/transfers/responses/accept-response"
import { Transfer } from "modules/api-client/src/transfers/types"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface TransferApiSpec {
    transferRequest(): Promise<Response<Transfer[]>>
    transferDetailRequest( transfer: string ): Promise<Response<Transfer>>
    createTransferRequest( data: CreateTransferRequest ): Promise<Response<{id: UUID}>>
    rejectTransferRequest( transfer: string ): Promise<Response<{message: string}>>
    acceptTransferRequest( transfer: string ): Promise<Response<AcceptTransferResponse>>
    cancelTransferRequest( transfer: string ): Promise<Response<{message: string}>>
}

export function useTransferApi (): TransferApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, postRequest } = useHttpClient( baseUrl, customer )

    const transferRequest = useCallback( (): Promise<Response<Transfer[]>> => {
        return getRequest<{data: Transfer[]}>( "transfers" )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const transferDetailRequest = useCallback( ( transfer: string ): Promise<Response<Transfer>> => {
        return getRequest<{data: Transfer}>( `transfers/${transfer}` )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const createTransferRequest = useCallback( ( data ): Promise<Response<{id: UUID}>> => {
        return postRequest<{id: UUID}>( "transfers/initiate", data )
    }, [postRequest] )

    const rejectTransferRequest = useCallback( ( transfer: string ): Promise<Response<{message: string}>> => {
        return postRequest<{message: string}>( `transfers/${transfer}/reject` )
    }, [postRequest] )

    const acceptTransferRequest = useCallback( ( transfer: string ): Promise<Response<AcceptTransferResponse>> => {
        return postRequest<AcceptTransferResponse>( `transfers/${transfer}/accept` )
    }, [postRequest] )

    const cancelTransferRequest = useCallback( ( transfer: string ): Promise<Response<{message: string}>> => {
        return postRequest<{message: string}>( `transfers/${transfer}/cancel`, {} )
    }, [postRequest] )

    return {
        transferRequest,
        transferDetailRequest,
        createTransferRequest,
        rejectTransferRequest,
        acceptTransferRequest,
        cancelTransferRequest,
    }
}
