import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.password-set.finish": {
        "en-US": "Finish your registration",
        "nl-NL": "Rond registratie af",
    },

    "pages.password-set.finish_action": {
        "en-US": "Finish setup",
        "nl-NL": "Afronden",
    },

    "pages.password-set.finished": {
        "en-US": "Your account is ready!",
        "nl-NL": "Account klaar voor gebruik!",
    },

} as TranslationDictionary
