import { Forward } from "modules/api-client/src/forwards/types"
import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface ForwardsApiSpec {
    listForwards( domain: string, options?: RequestOptions ): Promise<Response<Forward[]>>
    createForward( domain: string, forward: Forward, options?: RequestOptions ): Promise<Response<Forward>>
    updateForward( domain: string, oldForward: Forward, newForward: Forward, options?: RequestOptions ): Promise<Response<{ message: string}>>
    deleteForward( domain: string, forward: Forward, options?: RequestOptions ): Promise<Response<{ message: string}>>
}

export function useForwardsApi (): ForwardsApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, postRequest, putRequest, deleteRequest } = useHttpClient( baseUrl, customer )

    const listForwards = useCallback( ( domain: string, options?: RequestOptions ): Promise<Response<Forward[]>> => {
        return getRequest<{ data: Forward[] }>( `dns/${domain}/forwards`, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const createForward = useCallback( ( domain: string, forward: Forward, options?: RequestOptions ): Promise<Response<Forward>> => {
        return postRequest<{ data: Forward }>( `dns/${domain}/forwards`, forward, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [postRequest] )

    const updateForward = useCallback( ( domain: string, oldForward: Forward, newForward: Forward, options?: RequestOptions ): Promise<Response<{ message: string}>> => {
        return putRequest<{ message: string }>( `dns/${domain}/forwards`, {
            old: oldForward,
            new: newForward,
        }, {}, options )
    }, [putRequest] )

    const deleteForward = useCallback( ( domain: string, forward: Forward, options?: RequestOptions ): Promise<Response<{ message: string}>> => {
        return deleteRequest<{ message: string }>( `dns/${domain}/forwards`, {
            source: forward.source,
        }, {}, options )
    }, [deleteRequest] )

    return {
        listForwards,
        createForward,
        updateForward,
        deleteForward,
    }
}
