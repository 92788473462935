import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.domain.link-domains.page-header": {
        "en-US": "Link domain contact",
        "nl-NL": "Koppel domein contact",
    },
    "pages.domain.link-domains.form-header": {
        "en-US": "Domain contact",
        "nl-NL": "Domein contact",
    },
    "pages.domain.link-domains.description": {
        "en-US": "Choose a domain contact you want to link to your domain.",
        "nl-NL": "Kies een domein contact die u aan uw domein wilt linken.",
    },
    "pages.domain.link-domain.modal.description": {
        "en-US": "In the process of linking the selected domain contact, the whois details will be updated for your domain.",
        "nl-NL": "Tijdens het koppelen van de domein contact wordt de whois gegevens van het domein aangepast.",
    },
    "pages.domain.link-domain.single-select": {
        "en-US": "Please select one domain contact.",
        "nl-NL": "Selecteer één domein contact alstublieft.",
    },

} as TranslationDictionary
