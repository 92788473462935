import { RequestOptions, RequestPipelineStep, Response } from "modules/api-client/src/http-client/src/types"
import { useAuth } from "modules/auth"
import { useNotifications } from "modules/notifications"
import { useTranslations } from "modules/translations"
import { useCallback } from "react"
import { NotificationProps } from "ui-kit/overlays/notification"

interface HttpClientErrorHandlingHook {
    handleErrors: RequestPipelineStep
}

export default function useHttpErrorHandling (): HttpClientErrorHandlingHook {

    /* Mark the context properties as possibly undefined in case this is called
     * without the context present, e.g. in a test. */
    const { pushNotification }: { pushNotification: undefined | ( ( props: NotificationProps ) => void ) } = useNotifications()
    const { translate }: { translate: undefined | ( ( index: string ) => string ) } = useTranslations()
    const { logout }: { logout: undefined | ( () => void ) } = useAuth()

    const handleErrors = useCallback( <T> ( response: Promise<Response<T>>, options?: RequestOptions ): Promise<Response<T>> => {
        response.then( ( result: Response<T> ) => {
            if ( !result.error ) {
                return
            }
            if ( options?.logoutOnUnauthorized !== false && result.statusCode === 401 ) {
                pushNotification && pushNotification( {
                    type: "error",
                    children: translate( "modules.api-client.http_unauthorized_notification" ),
                } )

                logout && logout()
                return response
            }
            if ( options?.notifyOnError !== false && result.statusCode !== 422 ) {
                pushNotification && pushNotification( {
                    type: "error",
                    children: options?.errorNotification || result.error.message || translate( "modules.api-client.http_unsuccesful_notification" ),
                } )
            }
        } )
        return response

        // We are ignoring the pushNotification dependency here. This is because of the fact that this callback needs updating
        // every time a notification is spawned or dismissed. This previously caused all HTTP hooks to update, making many requests
        // execute twice.
    }, [translate, logout] ) // eslint-disable-line react-hooks/exhaustive-deps

    return { handleErrors }
}
