import generalFormLabelsTranslations from "modules/translations/src/locale/general/form-labels"
import generalTranslations from "modules/translations/src/locale/general/index"
import generalNavigationTranslations from "modules/translations/src/locale/general/navigation"
import generalNotificationTranslations from "modules/translations/src/locale/general/notifications"
import generalProductTranslations from "modules/translations/src/locale/general/product"
import generalProductGroupTranslations from "modules/translations/src/locale/general/product-groups"
import generalTabsTranslations from "modules/translations/src/locale/general/tabs"
import generalUnitTranslations from "modules/translations/src/locale/general/units"
import modulesApiClientTranslations from "modules/translations/src/locale/modules/api-client"
import modulesOrdersTranslations from "modules/translations/src/locale/modules/orders"
import modulesProductSpecsTranslations from "modules/translations/src/locale/modules/product/specs"
import pages404Translations from "modules/translations/src/locale/pages/404"
import pagesAddDnsTranslations from "modules/translations/src/locale/pages/add-dns"
import pagesAffiliateTranslations from "modules/translations/src/locale/pages/affiliate"
import pagesConfiguration from "modules/translations/src/locale/pages/configuration"
import pagesContactTranslations from "modules/translations/src/locale/pages/contact"
import pagesCreateCustomerTranslations from "modules/translations/src/locale/pages/create-customer"
import pagesCreateUserTranslations from "modules/translations/src/locale/pages/create-user"
import pagesCustomersTranslations from "modules/translations/src/locale/pages/customers"
import pagesDnsTranslations from "modules/translations/src/locale/pages/dns"
import pagesDnsTemplates from "modules/translations/src/locale/pages/dns-templates"
import pagesDnsTemplateLinkDomains from "modules/translations/src/locale/pages/dns-templates/link-domains"
import pagesDnsTemplateAdd from "modules/translations/src/locale/pages/dns-templates/new"
import pagesDnsTemplateDetails from "modules/translations/src/locale/pages/dns-templates/template/details"
import pagesDnsTemplateEdit from "modules/translations/src/locale/pages/dns-templates/template/edit"
import pagesDnsSecTranslations from "modules/translations/src/locale/pages/dnssec"
import pagesDiscontinue from "modules/translations/src/locale/pages/domain/discontinue"
import pagesDomainTranslations from "modules/translations/src/locale/pages/domain/domain"
import pagesDnsPremiumTranslations from "modules/translations/src/locale/pages/domain/domain/dns-premium"
import pagesDomainContactChoose from "modules/translations/src/locale/pages/domain/domain/domain-contact"
import pagesModifyNameServer from "modules/translations/src/locale/pages/domain/domain/modify-nameservers"
import pagesWhoisTranslations from "modules/translations/src/locale/pages/domain/domain/whois"
import pagesWhoisPrivacyTranslations from "modules/translations/src/locale/pages/domain/domain/whois-privacy"
import pagesHostingTranslations from "modules/translations/src/locale/pages/domain/hosting"
import pagesHostingMailTranslations from "modules/translations/src/locale/pages/domain/hosting/mail"
import pagesSslTranslations from "modules/translations/src/locale/pages/domain/ssl"
import pagesSslDownloadCertificateTranslations from "modules/translations/src/locale/pages/domain/ssl/download-certificate"
import pagesSslPrivateKeyTranslations from "modules/translations/src/locale/pages/domain/ssl/private-key"
import pagesSslReissueTranslations from "modules/translations/src/locale/pages/domain/ssl/reissue"
import pagesDomainContact from "modules/translations/src/locale/pages/domain-contact"
import pagesDomainContactDetails from "modules/translations/src/locale/pages/domain-contact/details/details"
import pagesDomainContactLinkDomains from "modules/translations/src/locale/pages/domain-contact/link-domains"
import pagesDomainContactAdd from "modules/translations/src/locale/pages/domain-contact/new"
import pagesForwardTranslations from "modules/translations/src/locale/pages/forwards"
import pagesLoginTranslations from "modules/translations/src/locale/pages/login"
import pagesLogoutTranslations from "modules/translations/src/locale/pages/logout"
import pagesOrderTranslations from "modules/translations/src/locale/pages/order"
import pagesPasswordResetTranslations from "modules/translations/src/locale/pages/password-reset"
import pagesPasswordSetTranslations from "modules/translations/src/locale/pages/password-set"
import pagesProfileTranslations from "modules/translations/src/locale/pages/profile"
import pagesRegisterTranslations from "modules/translations/src/locale/pages/register"
import pagesRegisterConfirmationTranslations from "modules/translations/src/locale/pages/register-confirmation"
import pagesResellerStatusTranslations from "modules/translations/src/locale/pages/reseller/index"
import pagesResellerOrderTranslations from "modules/translations/src/locale/pages/reseller/order"
import pagesSecurityTranslations from "modules/translations/src/locale/pages/security"
import pagesServersTranslations from "modules/translations/src/locale/pages/servers"
import pagesServicesTranslations from "modules/translations/src/locale/pages/services"
import pagesSubscriptionsTranslations from "modules/translations/src/locale/pages/subscriptions"
import pagesSwitchCustomerTranslations from "modules/translations/src/locale/pages/switch-customer"
import pagesTransfersTranslations from "modules/translations/src/locale/pages/transfers"
import pagesUsersTranslations from "modules/translations/src/locale/pages/users"
import uiKitFormsFormFieldTranslations from "modules/translations/src/locale/ui-kit/forms/form-field"
import uiKitPageContainerTranslations from "modules/translations/src/locale/ui-kit/layout/page-container"
import uiKitListsInteractiveTableTranslations from "modules/translations/src/locale/ui-kit/lists/interactive-table"
import uiKitNavigationTabsTranslations from "modules/translations/src/locale/ui-kit/navigation/tabs"
import { TranslationDictionary } from "modules/translations/src/translation-context"

export const dictionary: TranslationDictionary = {

    // General
    ...generalTranslations,
    ...generalNotificationTranslations,
    ...generalNavigationTranslations,
    ...generalTabsTranslations,
    ...generalUnitTranslations,
    ...generalProductTranslations,
    ...generalProductGroupTranslations,
    ...generalFormLabelsTranslations,

    // Modules
    ...modulesApiClientTranslations,
    ...modulesOrdersTranslations,
    ...modulesProductSpecsTranslations,

    // Pages
    ...pages404Translations,
    ...pagesAffiliateTranslations,
    ...pagesCreateCustomerTranslations,
    ...pagesCreateUserTranslations,
    ...pagesCustomersTranslations,
    ...pagesDnsTranslations,
    ...pagesDnsSecTranslations,
    ...pagesDnsPremiumTranslations,
    ...pagesDiscontinue,
    ...pagesLoginTranslations,
    ...pagesLogoutTranslations,
    ...pagesPasswordResetTranslations,
    ...pagesPasswordSetTranslations,
    ...pagesProfileTranslations,
    ...pagesRegisterConfirmationTranslations,
    ...pagesRegisterTranslations,
    ...pagesSecurityTranslations,
    ...pagesServersTranslations,
    ...pagesServicesTranslations,
    ...pagesSubscriptionsTranslations,
    ...pagesSwitchCustomerTranslations,
    ...pagesUsersTranslations,
    ...pagesOrderTranslations,
    ...pagesDomainTranslations,
    ...pagesHostingTranslations,
    ...pagesWhoisPrivacyTranslations,
    ...pagesWhoisTranslations,
    ...pagesSslTranslations,
    ...pagesSslReissueTranslations,
    ...pagesSslPrivateKeyTranslations,
    ...pagesSslDownloadCertificateTranslations,
    ...pagesModifyNameServer,
    ...pagesAddDnsTranslations,
    ...pagesDnsTemplates,
    ...pagesDnsTemplateDetails,
    ...pagesDnsTemplateEdit,
    ...pagesDnsTemplateAdd,
    ...pagesDnsTemplateLinkDomains,
    ...pagesForwardTranslations,
    ...pagesDomainContact,
    ...pagesDomainContactAdd,
    ...pagesDomainContactDetails,
    ...pagesDomainContactLinkDomains,
    ...pagesConfiguration,
    ...pagesTransfersTranslations,
    ...pagesResellerOrderTranslations,
    ...pagesResellerStatusTranslations,
    ...pagesHostingMailTranslations,
    ...pagesDomainContactChoose,
    ...pagesContactTranslations,

    // UI-kit
    ...uiKitFormsFormFieldTranslations,
    ...uiKitListsInteractiveTableTranslations,
    ...uiKitNavigationTabsTranslations,
    ...uiKitPageContainerTranslations,

}
