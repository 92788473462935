import { Response, useProductsApi } from "modules/api-client"
import { Product, ProductData } from "modules/api-client/src/product/types"
import { useAuth } from "modules/auth"
import React, { createContext, useCallback, useEffect, useState } from "react"

export interface ProductsDataContextType {
    products?: Product[]
    getProduct( details: Partial<Product> ): Product | undefined
    isLoading: boolean
    refresh(): void
}

export const ProductsDataContext = createContext<ProductsDataContextType>( {} as ProductsDataContextType )

interface ProductsDataContextProps {
    children: React.ReactNode
}

export function ProductsDataContextProvider ( { children }: ProductsDataContextProps ): JSX.Element {

    const { productDataRequest } = useProductsApi()
    const { customer } = useAuth()

    const [isLoading, setIsLoading] = useState( false )
    const [productData, setProductData] = useState<ProductData>()
    const fetchResource = useCallback( () => {
        setIsLoading( true )
        productDataRequest().then( ( response: Response<ProductData> ): void => {
            if ( response.success ) {
                setProductData( response.data! )
            }
            setIsLoading( false )
        } )
    }, [productDataRequest] )

    /**
     * Fetch resource, also reload if customer changed.
     */
    useEffect( () => {
        if ( !isLoading ) {
            fetchResource()
        }
        // We ignore the isLoading dependency, as we do not want to
        // fetch if just that has changed.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer, fetchResource] )

    const getProduct = useCallback( ( details: Partial<Product> ): Product | undefined => {
        if ( !productData ) {
            return undefined
        }
        return productData.products.find( product =>
            ( Object.keys( details ) as Array<keyof Product> )
                .every( key => details[key] === product[key] ) )
    }, [productData] )

    return (
        <ProductsDataContext.Provider
            value={{
                products: productData?.products.sort( ( a, b ) => a.weight > b.weight ? 1 : -1 ),
                getProduct,
                isLoading,
                refresh: () => fetchResource(),
            }}>
            {children}
        </ProductsDataContext.Provider>
    )
}
