import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.order.domain": {
        "en-US": "Domain",
        "nl-NL": "Domeinnaam",
    },
    "pages.order.hosting": {
        "en-US": "Hosting",
        "nl-NL": "Hosting",
    },
    "pages.order.ssl": {
        "en-US": "SSL",
        "nl-NL": "SSL",
    },
    "pages.order.order": {
        "en-US": "Order",
        "nl-NL": "Bestellen",
    },
    "pages.order.voucher": {
        "en-US": "Active vouchers",
        "nl-NL": "Geldige vouchers",
    },
    "pages.order.privacy_protection": {
        "en-US": "Add whois privacy protection",
        "nl-NL": "Voeg whois privacybescherming toe",
    },
    "pages.order.forward_url": {
        "en-US": "Forward URL",
        "nl-NL": "Doorstuur URL",
    },
    "pages.order.transfer_code": {
        "en-US": "Transfer code",
        "nl-NL": "Verhuiscode",
    },
    "pages.order.install_wordpress": {
        "en-US": "Install WordPress",
        "nl-NL": "Installeer WordPress",
    },
    "pages.order.transfer-add": {
        "en-US": "Add code",
        "nl-NL": "Code toevoegen",
    },
    "pages.order.select_customer": {
        "en-US": "Select customer",
        "nl-NL": "Select klant",
    },
    "pages.order.select_customer_description": {
        "en-US": "Because you have your own customers, you can place this order for one of your customers.",
        "nl-NL": "Omdat u eigen klanten heeft kunt u deze bestelling opleveren voor een van uw eigen klanten.",
    },
    "pages.order.finish": {
        "en-US": "Finish",
        "nl-NL": "Afronden",
    },
    "pages.order.period": {
        "en-US": "Period",
        "nl-NL": "Periode",
    },
    "pages.order.overview": {
        "en-US": "Overview",
        "nl-NL": "Overzicht",
    },
    "pages.order.config": {
        "en-US": "Needs Configuration",
        "nl-NL": "Configuratie benodigd",
    },
    "pages.order.cart_remove_item": {
        "en-US": "Remove",
        "nl-NL": "Verwijder",
    },
    "pages.order.search_domain":{
        "en-US": "Search domain",
        "nl-NL": "Zoek domein",
    },
    "pages.order.order.transfer":{
        "en-US": "Transfer",
        "nl-NL": "Verhuizen",
    },
    "pages.order.order.order":{
        "en-US": "Order",
        "nl-NL": "Bestellen",
    },
    "pages.order.order.success":{
        "en-US": "The order is completed",
        "nl-NL": "De order is voltooid",
    },
    "pages.order.order.failed":{
        "en-US": "Ordering failed, please try again later",
        "nl-NL": "Bestellen mislukt, probeer het later nog eens",
    },
    "pages.order.order.in_cart":{
        "en-US": "In cart",
        "nl-NL": "In winkelmandje",
    },
    "pages.order.order.product_coupling_upsell": {
        "en-US": "A {parentProductName} {parentProductType} comes with a {coupledProductName} {coupledProductType}.",
        "nl-NL": "Een {parentProductName} {parentProductType} komt met een {coupledProductName} {coupledProductType}",
    },
    "pages.order.cart.cart":{
        "en-US": "Cart",
        "nl-NL": "Winkelmandje",
    },
    "pages.order.cart.clear_cart":{
        "en-US": "Empty cart",
        "nl-NL": "Leeg winkelmandje",
    },
    "pages.order.cart.cart_empty":{
        "en-US": "Cart is empty, order products first.",
        "nl-NL": "Winkelmandje is leeg, bestel eerst producten.",
    },
    "pages.order.cart.select_first_item":{
        "en-US": "Select a domain to start ordering.",
        "nl-NL": "Selecteer een domein om te starten.",
    },
    "pages.order.cart.domain":{
        "en-US": "Domain",
        "nl-NL": "Domein",
    },
    "pages.order.cart.total":{
        "en-US": "Total",
        "nl-NL": "Totaal",
    },
    "pages.order.cart.vat-rate":{
        "en-US": "VAT ({rate}%)",
        "nl-NL": "BTW ({rate}%)",
    },
    "pages.order.cart.sub-total":{
        "en-US": "Subtotal",
        "nl-NL": "Subtotaal",
    },

    "pages.order.select-customer-header": {
        "en-US": "Select customer",
        "nl-NL": "Kies klant",
    },
    "pages.order.select-customer-label": {
        "en-US": "Place this order for",
        "nl-NL": "Plaats bestelling op naam van",
    },
    "pages.order.select-customer-self": {
        "en-US": "Myself",
        "nl-NL": "Mijzelf",
    },
    "pages.order.verify-csr-success": {
        "en-US": "The CSR is validated",
        "nl-NL": "De CSR is valide",
    },
    "pages.order.server-select": {
        "en-US": "Select server",
        "nl-NL": "Selecteer server",
    },
    "pages.order.handle-select": {
        "en-US": "Select domain contact",
        "nl-NL": "Selecteer domein houder",
    },
    "pages.order.shared-server": {
        "en-US": "Shared server",
        "nl-NL": "Gedeelde server",
    },
    "pages.order.month": {
        "en-US": "months",
        "nl-NL": "maanden",
    },
    "pages.order.ssl-automatic-install": {
        "en-US": "Install automatically",
        "nl-NL": "Automatisch installeren",
    },
    "pages.order.ssl-csr-validate": {
        "en-US": "Validate",
        "nl-NL": "Valideer",
    },
    "pages.order.ssl-csr-input": {
        "en-US": "Fill in the Certificate Signing Request (CSR)",
        "nl-NL": "Vul het Certificate Signing Request (CSR) in",
    },
    "pages.order.add-voucher-header": {
        "en-US": "Add voucher",
        "nl-NL": "Voucher toevoegen",
    },
    "pages.order.add-voucher-success": {
        "en-US": "The voucher is added to your order",
        "nl-NL": "De voucher is toegevoegd aan de bestelling",
    },
    "pages.order.add-voucher": {
        "en-US": "Add voucher",
        "nl-NL": "Voucher toevoegen",
    },
    "pages.order.voucher-code": {
        "en-US": "Voucher code",
        "nl-NL": "Voucher code",
    },
    "pages.order.complete_title": {
        "en-US": "Your order has been placed",
        "nl-NL": "Je bestelling is geplaatst",
    },
    "pages.order.complete_no_regret": {
        "en-US": "Thank you for your order. You will not regret this!",
        "nl-NL": "Bedankt voor jouw bestelling! Daar ga je geen spijt van krijgen!",
    },
    "pages.order.complete_what_now": {
        "en-US": "What happens next?",
        "nl-NL": "Wat gaat er nu gebeuren?",
    },
    "pages.order.complete_what_now_we_process": {
        "en-US": "We are going to work on processing your order.",
        "nl-NL": "Wij gaan aan de slag om jouw bestelling te verwerken.",
    },
    "pages.order.complete_what_now_usually_minutes": {
        "en-US": "Usually this happens automatically within a couple of mintues.",
        "nl-NL": "In veel gevallen gebeurt dit automatisch en binnen enkele minuten.",
    },
    "pages.order.complete_what_otherwise_1_workday": {
        "en-US": "In other cases, we process your order within one working day.",
        "nl-NL": "In andere gevallen verwerken we jouw order binnen 1 werkdag.",
    },
    "pages.order.complete_what_do_i_do": {
        "en-US": "Wat do I do now?",
        "nl-NL": "Wat moet ik nu doen?",
    },
    "pages.order.complete_we_might_contact_you": {
        "en-US": "If we need extra information, we will contact you.",
        "nl-NL": "Mochten wij extra informatie nodig hebben, zullen wij zelf contact opnemen.",
    },
    "pages.order.complete_questions_or_remarks": {
        "en-US": "Questions or remarks?",
        "nl-NL": "Vragen of opmerkingen?",
    },
    "pages.order.complete_contact_us": {
        "en-US": "Contact us via",
        "nl-NL": "Neem dan contact met ons op via",
    },

} as TranslationDictionary
