import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.security.2fa-intro": {
        "en-US": "You can improve the security of your account by enabling two factor authentication. Besides your username and password, you also need a generated code to log in. You can configure this using (for example) Google Authenticator or Authy.",
        "nl-NL": "Met two factor authentication kan je de beveiliging van je account verbeteren. Naast je gebruikersnaam en wachtwoord, heb je ook een gegenereerde cijferreeks nodig. Dit kan je doen met bijvoorbeeld Google Authenticator of Authy.",
    },
    "pages.security.2fa": {
        "en-US": "Two factor authentication",
        "nl-NL": "Twee-factor authenticatie",
    },
    "pages.security.verification-code": {
        "en-US": "Verification code",
        "nl-NL": "Verificatie code",
    },
    "pages.security.verification-code-hint": {
        "en-US": "Use an authentication app to scan the QR code and fill in the verification code.",
        "nl-NL": "Gebruik een authenticatie app om de QR code te scannen en vul de verificatie code in.",
    },
    "pages.security.verify-code": {
        "en-US": "Verify code",
        "nl-NL": "Verifieer code",
    },
    "pages.security.2fa-invalid": {
        "en-US": "Invalid confirmation code",
        "nl-NL": "Foutive bevestigingscode",
    },
    "pages.security.2fa-confirm-enabled": {
        "en-US": "Setup complete! Your account is now protected with two factor authentication.",
        "nl-NL": "Klaar! Je account wordt nu beschermd met twee factor authenticatie.",
    },
    "pages.security.2fa-confirm-disabled": {
        "en-US": "Two factor authentication is no longer enabled on your account",
        "nl-NL": "Twee factor authenticatie is uit gezet voor je account",
    },

} as TranslationDictionary
