import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.modify_nameservers.primary_nameserver": {
        "en-US": "Primary name server",
        "nl-NL": "Primaire name server",
    },
    "pages.modify_nameservers.secondary_nameserver": {
        "en-US": "Secondary name server",
        "nl-NL": "Secundaire name server",
    },
    "pages.modify_nameservers.extra_nameserver": {
        "en-US": "Extra name server",
        "nl-NL": "Extra name server",
    },
    "pages.modify_nameservers.description": {
        "en-US": "Set up your own name servers or use our provided name servers.",
        "nl-NL": "Stel je eigen name servers in of gebruik aangeleverde name servers.",
    },
    "pages.modify_nameservers.toggle": {
        "en-US": "Use provided name servers",
        "nl-NL": "Gebruik aangeleverde name servers",
    },
    "pages.modify_nameservers.go_back": {
        "en-US": "Return to your domain overview page.",
        "nl-NL": "Ga terug naar uw domein overzicht pagina.",
    },
    "pages.modify_nameservers.submit": {
        "en-US": "Update",
        "nl-NL": "Wijzigen",
    },
    "pages.modify_nameservers.success_notification": {
        "en-US": "Name servers changed successfully for {domain}",
        "nl-NL": "Name servers zijn succesvol gewijzigd voor {domain}",
    },
    "pages.modify_nameservers.form_heading": {
        "en-US": "Configure name servers",
        "nl-NL": "Name servers instellen",
    },

} as TranslationDictionary
