import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.domain.domain.index.subscription-status": {
        "en-US": "Subscription status",
        "nl-NL": "Abonnementstatus",
    },
    "pages.domain.domain.index.renewal-date": {
        "en-US": "Renewal date",
        "nl-NL": "Verlengdatum",
    },
    "pages.domain.domain.index.order": {
        "en-US": "Order domainname ",
        "nl-NL": "Bestel domeinnaam ",
    },
    "pages.domain.domain.index.period": {
        "en-US": "Duration",
        "nl-NL": "Looptijd",
    },
    "pages.domain.domain.index.period-suffix": {
        "en-US": "months",
        "nl-NL": "maanden",
    },
    "pages.domain.domain.index.whois-data": {
        "en-US": "WHOIS data",
        "nl-NL": "WHOIS-gegevens",
    },
    "pages.domain.domain.index.domain-owner": {
        "en-US": "Domain owner",
        "nl-NL": "Domein eigenaar",
    },
    "pages.domain.domain.index.private-data": {
        "en-US": "Your data will stay private",
        "nl-NL": "Jouw gegevens blijven privé",
    },
    "pages.domain.domain.index.not-private-data": {
        "en-US": "Your data is publicly available",
        "nl-NL": "Jouw gegevens zijn publiek beschikbaar",
    },
    "pages.domain.domain.index.privacy-protection": {
        "en-US": "Privacy protection",
        "nl-NL": "Privacybescherming",
    },
    "pages.domain.domain.index.privacy-protection.contact-support": {
        "en-US": "Please contact support to change your privacy protection.",
        "nl-NL": "Neem contact op met de klantenservice om je privacybescherming aan te vragen.",
    },
    "pages.domain.domain.index.dns-admin": {
        "en-US": "DNS administration",
        "nl-NL": "DNS beheer",
    },
    "pages.domain.domain.index.nameservers": {
        "en-US": "Nameservers",
        "nl-NL": "Nameservers",
    },
    "pages.domain.domain.index.off": {
        "en-US": "Uit",
        "nl-NL": "Off",
    },
    "pages.domain.domain.index.on": {
        "en-US": "On",
        "nl-NL": "Aan",
    },
    "pages.domain.domain.dnssec": {
        "en-US": "DNSSEC",
        "nl-NL": "DNSSEC",
    },
    "pages.domain.domain.dnssec.enable-failure": {
        "en-US": "An error occured while enabling DNSSEC. Please try again later.",
        "nl-NL": "Er is een fout opgetreden bij het aanzetten van DNSSEC. Probeer het later opnieuw.",
    },
    "pages.domain.domain.dnssec.enable-title": {
        "en-US": "Enable DNSSEC.",
        "nl-NL": "DNSSEC aanzetten.",
    },
    "pages.domain.domain.dnssec.enable-description": {
        "en-US": "Are you sure you want to enable DNSSEC?",
        "nl-NL": "Weet u zeker dat u DNSSEC wilt aanzetten?",
    },
    "pages.domain.domain.dnssec.disable-failure": {
        "en-US": "An error occured while disabling DNSSEC. Please try again later.",
        "nl-NL": "Er is een fout opgetreden bij het uitzetten van DNSSEC. Probeer het later opnieuw.",
    },
    "pages.domain.domain.dnssec.disable-title": {
        "en-US": "Disable DNSSEC.",
        "nl-NL": "DNSSEC uitzetten.",
    },
    "pages.domain.domain.index.dns-premium": {
        "en-US": "Premium DNS",
        "nl-NL": "Premium DNS",
    },
    "pages.domain.domain.index.dns-premium-data": {
        "en-US": "Premium DNS is configured for your domain",
        "nl-NL": "Jouw domein maakt gebruik van Premium DNS",
    },
    "pages.domain.domain.index.not-dns-premium-data": {
        "en-US": "Premium DNS is currently not configured for your domain",
        "nl-NL": "Jouw domein maakt momenteel geen gebruik van Premium DNS",
    },
    "pages.domain.domain.dnssec.disable-description": {
        "en-US": "Are you sure you want to disable DNSSEC?",
        "nl-NL": "Weet u zeker dat u DNSSEC wilt uitzetten?",
    },
    "pages.domain.domain.index.hosting-elsewhere": {
        "en-US": "We are currently not (yet) hosting this domain.",
        "nl-NL": "Wij hebben deze domeinnaam (nog) niet in beheer.",
    },
    "pages.domain.domain.hosting-upgrades.upgrade-title": {
        "en-US": "Take your website to the next level",
        "nl-NL": "Haal meer uit je website",
    },
    "pages.domain.domain.hosting-upgrades.upgrade-sub-title": {
        "en-US": "Select one of the possibilities",
        "nl-NL": "Kies een van de mogelijke pakketten",
    },
    "pages.domain.domain.hosting-upgrades.max_box_spec" : {
        "en-US": "{value} mailbox(es)",
        "nl-NL": "{value} mailbox(en)",
    },
    "pages.domain.domain.hosting-upgrades.max_db" : {
        "en-US": "{value} database(s)",
        "nl-NL": "{value} database(s)",
    },
    "pages.domain.domain.hosting-upgrades.max_traffic" : {
        "en-US": "{value} verkeer",
        "nl-NL": "{value} verkeer",
    },
    "pages.domain.domain.hosting-upgrades.charge" : {
        "en-US": "To upgrade your hosting package we have to charge {value} as an one time fee for the current term.",
        "nl-NL": "Om je hosting package upgrade door te voeren wordt er eenmalig {value} in rekening gebracht als extra kosten voor de huidige looptijd.",
    },
    "pages.domain.domain.hosting-upgrades.title" : {
        "en-US": "Take your website to the next level!",
        "nl-NL": "Haal meer uit je website!",
    },
    "pages.domain.domain.hosting-upgrades.subtitle" : {
        "en-US": "Select one of the possible upgrades.",
        "nl-NL": "Kies een van de mogelijke upgrades.",
    },
    "pages.domain.domain.hosting-upgrades.upgrade-success" : {
        "en-US": "You have successfully upgraded the hosting package to: {value}.",
        "nl-NL": "Je hebt het hosting package succesvol geüpgraded naar: {value}.",
    },
    "pages.domain.domain.index.discontinue" : {
        "en-US": "Cancel or transfer away",
        "nl-NL": "Opzeggen of wegverhuizen",
    },
    "pages.domain.index.whois-fetch-error": {
        "en-US": "Failed to retreive WHOIS information.",
        "nl-NL": "Fout bij het ophalen van WHOIS informatie.",
    },
    "pages.domain.index.domain-contact-detail": {
        "en-US": "Details",
        "nl-NL": "Details",
    },
    "pages.domain.index.domain-contact-update": {
        "en-US": "Update",
        "nl-NL": "Wijzigen",
    },
} as TranslationDictionary
