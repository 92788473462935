import useConfig from "next/config"
import React from "react"

export interface LogoProps {
    /**
     * Optional className to extend default styling.
     */
    className?: string
}

/**
 * Simple brand logo (changes automatically with the `BRAND_THEME` env variable)
 */
export default function Logo ( props: LogoProps ): JSX.Element {

    const { brandTheme } = useConfig().publicRuntimeConfig as { brandTheme: string }
    const logoUrl = `/img/logo-${brandTheme}-mono.png`
    const logoAlt = brandTheme

    return (
        <img className={`h-6 w-auto ${props.className || ""}`} src={logoUrl} alt={logoAlt} />
    )
}
