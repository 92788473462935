import { useTranslations } from "modules/translations"

/**
 * Get an instance of formatDate provisioned with the current locale according
 * to the translations module.
 */
export default function useDateFormatter (): { formatDate: ( dateString: string ) => string} {
    const { language } = useTranslations() || "en-US"
    return {
        formatDate ( dateString: string ): string {
            return dateFormatter( dateString, language )
        },
    }
}

/**
 * Format date according to given locale
 */
export function dateFormatter ( dateString: string, locale: string ): string {
    const date = new Date( dateString )
    return new Intl.DateTimeFormat( locale ).format( date )
}
