import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.add-dns.form-heading": {
        "en-US": "Add DNS",
        "nl-NL": "DNS toevoegen",
    },
    "pages.add-dns.add": {
        "en-US": "Add",
        "nl-NL": "Toevoegen",
    },
    "pages.add-dns.domain": {
        "en-US": "Domain",
        "nl-NL": "Domein",
    },
    "pages.add-dns.success": {
        "en-US": "The DNS subscription has been made",
        "nl-NL": "Het DNS abonnement is aangemaakt",
    },
    "pages.add-dns.description": {
        "en-US": "Create a DNS service for a domain without requiring a hosting package.",
        "nl-NL": "Maak een DNS service voor een domein zonder hostingpakket aan.",
    },
} as TranslationDictionary
