import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.login.email": {
        "en-US": "E-mail address",
        "nl-NL": "E-mailadres",
    },
    "pages.login.password": {
        "en-US": "Password",
        "nl-NL": "Wachtwoord",
    },
    "pages.login.2fa": {
        "en-US": "Verification code",
        "nl-NL": "Verificatiecode",
    },
    "pages.login.login": {
        "en-US": "Log in",
        "nl-NL": "Log in",
    },
    "pages.login.forgot-password": {
        "en-US": "Forgot password?",
        "nl-NL": "Wachtwoord vergeten?",
    },
    "pages.login.register": {
        "en-US": "Register",
        "nl-NL": "Registreren",
    },
    "pages.login.throttled": {
        "en-US": "Too many login attempts, please try again in a minute.",
        "nl-NL": "Te veel login pogingen, probeer a.u.b. over een minuut nog een keer.",
    },

} as TranslationDictionary
