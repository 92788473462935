import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.dns.section.title": {
        "en-US": "DNS records",
        "nl-NL": "DNS records",
    },
    "pages.dns.button.add": {
        "en-US": "Add record",
        "nl-NL": "Voeg record toe",
    },
    "pages.dns.button.create_zone": {
        "en-US": "Create DNS zone",
        "nl-NL": "Maak DNS zone aan",
    },
    "pages.dns.button.reset_filter": {
        "en-US": "Reset search query",
        "nl-NL": "Reset zoekopdracht",
    },
    "pages.dns.delete_error": {
        "en-US": "Something went wrong while trying to delete this record. Please try again later.",
        "nl-NL": "Er ging iets mis bij het verwijderen van dit record. Probeer het later opnieuw.",
    },
    "pages.dns.fetch_error": {
        "en-US": "Currently unable to retrieve the DNS records. Please try again later.",
        "nl-NL": "Fout bij het ophalen van de DNS records. Probeer het later opnieuw.",
    },
    "pages.dns.heading.add_record": {
        "en-US": "Create a new record",
        "nl-NL": "Record toevoegen",
    },
    "pages.dns.heading.records": {
        "en-US": "DNS Records",
        "nl-NL": "DNS Records",
    },
    "pages.dns.labels.hostname": {
        "en-US": "Hostname",
        "nl-NL": "Hostname",
    },
    "pages.dns.labels.priority": {
        "en-US": "Priority",
        "nl-NL": "Priority",
    },
    "pages.dns.labels.weight": {
        "en-US": "Weight",
        "nl-NL": "Weight",
    },
    "pages.dns.labels.port": {
        "en-US": "Port",
        "nl-NL": "Port",
    },
    "pages.dns.labels.value": {
        "en-US": "Value",
        "nl-NL": "Waarde",
    },
    "pages.dns.no_zone": {
        "en-US": "A DNS zone has not been created yet for this domain.",
        "nl-NL": "Er is voor dit domein nog geen DNS zone aangemaakt.",
    },
    "pages.dns.table_header.actions": {
        "en-US": "Actions",
        "nl-NL": "Acties",
    },
    "pages.dns.table_header.content": {
        "en-US": "Value",
        "nl-NL": "Waarde",
    },
    "pages.dns.table_header.name": {
        "en-US": "Name",
        "nl-NL": "Naam",
    },
    "pages.dns.table_header.ttl": {
        "en-US": "TTL",
        "nl-NL": "TTL",
    },
    "pages.dns.table_header.type": {
        "en-US": "Type",
        "nl-NL": "Type",
    },
    "pages.dns.ttl_options.600": {
        "en-US": "10 minutes",
        "nl-NL": "10 minuten",
    },
    "pages.dns.ttl_options.1800": {
        "en-US": "30 minutes",
        "nl-NL": "30 minuten",
    },
    "pages.dns.ttl_options.3600": {
        "en-US": "1 hour",
        "nl-NL": "1 uur",
    },
    "pages.dns.ttl_options.7200": {
        "en-US": "2 hours",
        "nl-NL": "2 uren",
    },
    "pages.dns.ttl_options.14400": {
        "en-US": "4 hours",
        "nl-NL": "4 uren",
    },
    "pages.dns.ttl_options.86400": {
        "en-US": "1 day",
        "nl-NL": "1 dag",
    },
    "pages.dns.linked.description": {
        "en-US": "Your DNS is linked to a DNS template: {name}. You can either make changes to the template, or unlink the template from this zone.",
        "nl-NL": "Deze DNS is gekoppeld aan een template: {name}. U kunt wijzigingen doorvoeren bij de template, of de DNS zone onkoppelen van de template.",
    },
    "pages.dns.linked.to_template": {
        "en-US": "Manage template",
        "nl-NL": "Beheer template.",
    },
    "pages.dns.linked.unlink": {
        "en-US": "Unlink template",
        "nl-NL": "Ontkoppel template.",
    },

    "pages.dns.no_results": {
        "en-US": "No results found for \"{query}\"",
        "nl-NL": "Geen resultaten voor \"{query}\"",
    },
    "pages.dns.no_records": {
        "en-US": "You don't have any records yet",
        "nl-NL": "Deze zone heeft nog geen records",
    },
    "pages.dns.remove-record.confirmation-title": {
        "en-US": "Caution! Are you sure that you want to delete a DNS record?",
        "nl-NL": "Let op! Weet u het zeker dat u een DNS record wilt verwijderen?",
    },
    "pages.dns.remove-record.confirmation-body": {
        "en-US": "You are about to delete a DNS record, this can have big consequences. Are you sure?",
        "nl-NL": "U staat op het punt om een DNS record te verwijderen, dit kan grote gevolgen hebben. Weet u het zeker?",
    },
    "pages.dns.remove-record.confirmation-button": {
        "en-US": "Yes, I am sure",
        "nl-NL": "Ja, ik weet het zeker",
    },
    "pages.dns.remove-record.dismiss": {
        "en-US": "Cancel",
        "nl-NL": "Annuleren",
    },
} as TranslationDictionary
