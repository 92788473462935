import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.reseller.order.page-heading": {
        "en-US": "Become a reseller",
        "nl-NL": "Word een reseller",
    },
    "pages.reseller.order.form-title": {
        "en-US": "Order",
        "nl-NL": "Bestel",
    },
    "pages.reseller.order.submit-button": {
        "en-US": "Order",
        "nl-NL": "Bestellen",
    },
    "pages.reseller.order.fill_all_fields": {
        "en-US": "All fields have to be filled in correctly.",
        "nl-NL": "Alle velden dienen correct ingevuld te zijn.",
    },
    "pages.reseller.order.server-error": {
        "en-US": "An error occurred while activating the Reseller status. Please try again later.",
        "nl-NL": "Er is een fout opgetreden bij het activeren van de Reseller status. Probeer het later nog eens.",
    },
    "pages.reseller.order.success":{
        "en-US": "Reseller status successfully activated.",
        "nl-NL": "Reseller status succesvol geactiveerd.",
    },
    "pages.reseller.order.confirmation": {
        "en-US": "I accept the above mentioned prices to become a reseller.",
        "nl-NL": "Ik accepteer de bovengenoemde kosten om een reseller te worden.",
    },
    "pages.reseller.order.price": {
        "en-US": "Price *",
        "nl-NL": "Prijs *",
    },
    "pages.reseller.order.price_without_vat": {
        "en-US": "Prices are displayed without VAT",
        "nl-NL": "Getoonde prijzen zijn excl. BTW.",
    },
    "pages.reseller.order.period": {
        "en-US": "Period",
        "nl-NL": "Periode",
    },
    "pages.reseller.order.one_year": {
        "en-US": "1 year",
        "nl-NL": "1 jaar",
    },
} as TranslationDictionary
