import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.create_user.back_to_overview": {
        "en-US": "Back to the overview",
        "nl-NL": "Terug naar het overzicht",
    },
    "pages.create_user.create_succesful": {
        "en-US": "The user has been created succesfully.",
        "nl-NL": "De gebruiker is aangemaakt.",
    },
    "pages.create_user.form.email": {
        "en-US": "Email address",
        "nl-NL": "E-mailadres",
    },
    "pages.create_user.form.first_name": {
        "en-US": "First name",
        "nl-NL": "Voornaam",
    },
    "pages.create_user.form.last_name": {
        "en-US": "Last name",
        "nl-NL": "Achternaam",
    },
    "pages.create_user.form.role": {
        "en-US": "Role",
        "nl-NL": "Rol",
    },
    "pages.create_user.load_user_roles_error": {
        "en-US": "User roles could not be loaded. Please try again later.",
        "nl-NL": "De rollen voor de gebruikers konden niet geladen worden. Probeer het later opnieuw.",
    },
    "pages.create_user.page_title": {
        "en-US": "Create a new user",
        "nl-NL": "Nieuwe gebruiker maken",
    },

} as TranslationDictionary
