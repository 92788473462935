import { CartItem, useCart } from "modules/orders"
import { useTranslations } from "modules/translations"
import React, { useState } from "react"
import Input from "ui-kit/forms/input"

export function ConfigureForwardingUrl ( { domain, item }: {domain: string, item: CartItem} ): JSX.Element {
    const [url, setUrl] = useState( "" )
    const { translate } = useTranslations()

    const { updateItem } = useCart()
    function updateUrl ( newState: string ): void {
        setUrl( newState )
        const newItem: CartItem = {
            ...item,
            product: {
                ...item.product,
                config: {
                    ...item.product.config,
                    forwardingUrl: newState,
                },
            },
        }
        updateItem( domain, newItem )
    }

    return <div className="mb-8">
        <Input
            id="url"
            label={translate( "pages.order.forward_url" )}
            onChange={( newValue ) => updateUrl( newValue )}
            value={url}
            className="pr-1"
        />
    </div>
}
