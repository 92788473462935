import * as Icons from "icons/font-awesome/light"
import { useTranslations } from "modules/translations"
import useConfig from "next/config"
import { useRouter } from "next/router"
import { FC } from "react"
import HyperLink from "ui-kit/elements/hyper-link"

export interface NavItem {
    icon: string
    category?: string
    title: string
    location: string
    display: boolean
    permission?: string
    external?: boolean
}

interface SidebarNavigationProps {
    items: NavItem[]
}

const DEFAULT_CATEGORY = "__default"

/**
 * The standard sidebar for the app layout, which shows the main navigation.
 */
export default function SidebarNavigation ( props: SidebarNavigationProps ): JSX.Element {
    const { versionNumber } = useConfig().publicRuntimeConfig as { versionNumber: string}
    const { translate } = useTranslations()

    const categories: { [category: string]: NavItem[] } = {}
    props.items.forEach( ( item: NavItem ): void => {
        const category = item.category || DEFAULT_CATEGORY
        if ( typeof categories[category] === "undefined" ) {
            categories[category] = []
        }
        categories[category].push( item )
    } )

    return (
        <nav className="flex-1 space-y-1 flex flex-col justify-between">
            <ul>
                {Object.entries( categories ).map( ( [category, items]: [string, NavItem[]], index: number ) => <NavigationCategory category={category} items={items} key={index} /> )}
            </ul>
            <div className="text-center py-3 px-3">
                <span className="text-xs text-gray-600 uppercase border border-gray-600 rounded-sm px-1">
                    {translate( "general.navigation.version", { value: versionNumber } )}
                </span>
            </div>
        </nav>
    )
}

function NavigationCategory ( props: { category: string, items: NavItem[]} ): JSX.Element {
    const { translate } = useTranslations()
    return <li>
        {props.category !== DEFAULT_CATEGORY && <>
            <span className="inline-block mt-6 mb-1 text-xs text-gray-400 uppercase tracking-wide font-medium mx-3">
                {translate( props.category )}
            </span>
        </>}
        <ul>
            {props.items.map( ( item, index ) => <NavigationItem item={item} key={index} /> )}
        </ul>
    </li>
}

function NavigationItem ( props: { item: NavItem} ): JSX.Element {
    const router = useRouter()
    const { translate } = useTranslations()
    const isActive = router.pathname.startsWith( props.item.location )
    const activeClass = "group flex items-center mx-1 px-3 py-2 text-sm leading-5 font-medium text-white bg-brand-600 rounded focus:bg-brand-500 transition ease-in-out duration-150"
    const inactiveClass = "group flex items-center mx-1 px-3 py-2 text-sm leading-5 font-medium text-gray-100 rounded hover:white hover:bg-gray-600 focus:white focus:bg-gray-600 transition ease-in-out duration-150"
    const iconSet = Icons as { [name: string]: FC<{className?: string}> }
    const NavIcon: FC<{className?: string}> | undefined = iconSet[props.item.icon]
    if ( typeof NavIcon === "undefined" ) {
        throw new Error( `Cannot resolve navigation icon of type: ${props.item.icon}` )
    }

    return <HyperLink href={props.item.location} newTab={props.item.external} className={isActive ? activeClass : inactiveClass} variant="unstyled">
        <NavIcon className={`-ml-1 mr-3 text-lg ${isActive ? "text-white" : "text-brand-400"}`} />
        {translate( props.item.title )}
    </HyperLink>
}
