import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "product-spec.hosting.php-settings.memory_limit": {
        "en-US": "PHP Memory Limit",
        "nl-NL": "PHP Geheugen Limiet",
    },
    "product-spec.hosting.permissions.manage_crontab": {
        "en-US": "Manage Cronjobs",
        "nl-NL": "Cronjob Beheer",
    },
    "product-spec.hosting.services.forwarding": {
        "en-US": "Forward traffic",
        "nl-NL": "Verkeer doorsturen",
    },
    "product-spec.hosting.services.wordpress": {
        "en-US": "WordPress",
        "nl-NL": "WordPress",
    },
    "product-spec.hosting.limits.disk_space": {
        "en-US": "Disk space",
        "nl-NL": "Schijfruimte",
    },
    "product-spec.hosting.limits.max_box": {
        "en-US": "Email addresses",
        "nl-NL": "Aantal e-mailadressen",
    },
    "product-spec.hosting.limits.max_db": {
        "en-US": "Number of databases",
        "nl-NL": "Aantal databases",
    },
    "product-spec.hosting.limits.max_traffic": {
        "en-US": "Max traffic",
        "nl-NL": "Maximaal dataverkeer",
    },
    "product-spec.hosting.permissions.ext_permission_acronis_backup": {
        "en-US": "Acronis backups",
        "nl-NL": "Acronis backups",
    },
    "product-spec.hosting.permissions.ext_permission_acronis_backup_acronis_backup": {
        "en-US": "Acronis backups",
        "nl-NL": "Acronis backups",
    },
} as TranslationDictionary
