import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.register_confirmation.title": {
        "en-US": "Your registration is successful!",
        "nl-NL": "Jouw registratie is gelukt!",
    },
    "pages.register_confirmation.text": {
        "en-US": "Your account has been created, you have received an email to activate your account.",
        "nl-NL": "Jouw account is aangemaakt, je hebt een email ontvangen om het account te activeren.",
    },
    "pages.register_confirmation.go_to_login": {
        "en-US": "Go to the login page",
        "nl-NL": "Ga naar de loginpagina",
    },

} as TranslationDictionary
