import useHttpErrorHandling from "modules/api-client/src/http-client/src/error-handling"
import { sendRequest } from "modules/api-client/src/http-client/src/send-request"
import { QueryParameters, RequestOptions, RequestPayload, Response } from "modules/api-client/src/http-client/src/types"
import { Customer } from "modules/auth/src/types"
import { formatApiRoute } from "modules/helpers"
import { useCallback } from "react"

interface HttpClientHook {
    getRequest<T> ( route: string, query?: QueryParameters, options?: RequestOptions ): Promise<Response<T>>
    postRequest<T> ( route: string, payload?: RequestPayload, query?: QueryParameters, options?: RequestOptions ): Promise<Response<T>>
    putRequest<T> ( route: string, payload?: RequestPayload, query?: QueryParameters, options?: RequestOptions ): Promise<Response<T>>
    patchRequest<T> ( route: string, payload?: RequestPayload, query?: QueryParameters, options?: RequestOptions ): Promise<Response<T>>
    deleteRequest<T> ( route: string, payload?: RequestPayload, query?: QueryParameters, options?: RequestOptions ): Promise<Response<T>>
}

export default function useHttpClient ( baseUrl: string, customer?: Customer ): HttpClientHook {

    const { handleErrors } = useHttpErrorHandling()

    interface RequestPipelineOptions {
        route: string
        query: QueryParameters
        requestInit: RequestInit
        options?: RequestOptions
    }
    const createRequestPipeline = useCallback( <T> ( { route, query, requestInit, options }: RequestPipelineOptions ): Promise<Response<T>> => {
        const url = formatApiRoute( baseUrl, route, query )
        const baseRequest = sendRequest<T>(
            url,
            requestInit,
            customer,
            options
        )
        const withErrorHandling = handleErrors( baseRequest, options )
        return withErrorHandling
    }, [ baseUrl, customer, handleErrors ] )

    const getRequest = useCallback( <T> ( route: string, query = {} as QueryParameters, options?: RequestOptions ): Promise<Response<T>> => {
        const requestInit: RequestInit = {
            method: "get",
        }
        return createRequestPipeline( { route, query, requestInit, options } )
    }, [createRequestPipeline] )

    const postRequest = useCallback( <T>( route: string, payload = {} as RequestPayload, query = {} as QueryParameters, options?: RequestOptions ): Promise<Response<T>> => {
        const requestInit = {
            method: "post",
            body: JSON.stringify( payload ),
        }
        return createRequestPipeline( { route, query, requestInit, options } )
    }, [createRequestPipeline] )

    const putRequest = useCallback( <T>( route: string, payload = {} as RequestPayload, query = {} as QueryParameters, options?: RequestOptions ): Promise<Response<T>> => {
        const requestInit = {
            method: "put",
            body: JSON.stringify( payload ),
        }
        return createRequestPipeline( { route, query, requestInit, options } )
    }, [createRequestPipeline] )

    const patchRequest = useCallback( <T>( route: string, payload = {} as RequestPayload, query = {} as QueryParameters, options?: RequestOptions ): Promise<Response<T>> => {
        const requestInit = {
            method: "PATCH",
            body: JSON.stringify( payload ),
        }
        return createRequestPipeline( { route, query, requestInit, options } )
    }, [createRequestPipeline] )

    const deleteRequest = useCallback( <T>( route: string, payload?: RequestPayload, query = {} as QueryParameters, options?: RequestOptions ): Promise<Response<T>> => {
        const requestInit = {
            method: "delete",
            body: payload !== undefined ? JSON.stringify( payload ) : undefined,
        }
        return createRequestPipeline( { route, query, requestInit, options } )
    }, [createRequestPipeline] )


    return {
        getRequest,
        postRequest,
        putRequest,
        patchRequest,
        deleteRequest,
    }
}
