/**
 * Strip TLD from domain name and removes www subdomain.
 */
export default function stripDomainName ( domain: string ): string {
    const cleandomain = cleanDomain( domain )
    let bits = cleandomain.toLocaleLowerCase().split( "." )

    if ( bits.length > 1 ) {
        bits = bits.filter( item => item !== "www" )
        bits.forEach( ( bit, index ) => {
            bits[index] = cleanDomain( bit )
        } )
        bits = bits.filter( item => item !== "" )
        return bits[0]
    }
    return cleandomain
}

function cleanDomain ( domain: string ): string {
    return domain.replace( /^[-]{1,}|[^0-9a-zA-Z\-.]|[.]{2,}|([-]{1,})$/g, "" )
}
