import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { CheckResponse } from "modules/api-client/src/vouchers/reponses/check-response"
import { ClaimResponse } from "modules/api-client/src/vouchers/reponses/claim-response"
import { Voucher } from "modules/api-client/src/vouchers/types"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface VouchersApiSpec {
    vouchersRequest( options?: RequestOptions ): Promise<Response<Voucher[]>>
    claimVoucherRequest( code: string, options?: RequestOptions ): Promise<Response<ClaimResponse>>
    checkVoucherRequest( code: string, options?: RequestOptions ): Promise<Response<CheckResponse>>
}
export function useVouchersApi (): VouchersApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, postRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retrieve the vouchers from the API.
     */
    const vouchersRequest = useCallback( ( options?: RequestOptions ): Promise<Response<Voucher[]>> => {
        return getRequest<{ data: Voucher[] }>( "vouchers", {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const claimVoucherRequest = useCallback( ( code: string, options?: RequestOptions ): Promise<Response<ClaimResponse>> => {
        return postRequest( "vouchers/claim", { "code" : code }, {}, options )
    }, [postRequest] )

    const checkVoucherRequest = useCallback( ( code: string, options?: RequestOptions ): Promise<Response<CheckResponse>> => {
        return postRequest<{ data: CheckResponse }>( "vouchers/check", { "code" : code }, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [postRequest] )


    return {
        vouchersRequest,
        claimVoucherRequest,
        checkVoucherRequest,
    }
}
