import React, { createContext, useCallback, useEffect, useState } from "react"
import { ModalProps } from "ui-kit/overlays/modal"


export interface ModalContextType {
    modal?: ModalProps
    spawnModal( props: ModalProps ): void
    confirmModal(): void
    dismissModal(): void
}

export const ModalContext = createContext<ModalContextType>( {} as ModalContextType )

interface ModalContextProps {
    children: React.ReactNode
}

export function ModalContextProvider ( { children }: ModalContextProps ): JSX.Element {

    const [queue, setQueue] = useState<ModalProps[]>( [] )
    const [modal, setModal] = useState<ModalProps>()

    const spawnModal = ( props: ModalProps ): void => {
        setQueue( [...queue, props] )
    }

    const confirmModal = useCallback( (): void => {
        if ( modal?.onConfirm ) {
            modal.onConfirm()
        }
        setModal( undefined )
    }, [modal] )

    const dismissModal = useCallback( (): void => {
        if ( modal?.onDismiss ) {
            modal.onDismiss()
        }
        setModal( undefined )
    }, [modal] )

    useEffect( () => {
        if ( ! modal && queue.length > 0 ) {
            setModal( queue.shift() )
            setQueue( queue )
        }
    }, [modal, setModal, queue, setQueue] )

    return (
        <ModalContext.Provider
            value={{
                modal,
                spawnModal,
                confirmModal,
                dismissModal,
            }}>
            {children}
        </ModalContext.Provider>
    )
}
