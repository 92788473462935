import { CartItem, useCart } from "modules/orders"
import { useTranslations } from "modules/translations"
import React, { useState } from "react"
import Toggle from "ui-kit/forms/toggle"

export function ConfigureWordPress ( { domain, item }: {domain: string, item: CartItem} ): JSX.Element {
    const [installWordPress, setInstallWordPress] = useState( false )
    const { updateItem } = useCart()
    const { translate } = useTranslations()

    function addWordPressInstallToCartItem ( newState: boolean ): void {
        setInstallWordPress( newState )
        const newItem: CartItem = {
            ...item,
            product: {
                ...item.product,
                config: {
                    ...item.product.config,
                    installWordPress: newState,
                },
            },
        }
        updateItem( domain, newItem )
    }

    return <div className="mb-8">
        <Toggle name="install-wordpress" onToggle={( enabled: boolean ) => { setInstallWordPress( enabled ); addWordPressInstallToCartItem( enabled ) }} enabled={installWordPress}>
            {translate( "pages.order.install_wordpress" )}
        </Toggle>
    </div>
}
