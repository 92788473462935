import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { OrderResponse } from "modules/api-client/src/orders/responses/order-response"
import { Order } from "modules/api-client/src/orders/types"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

export interface OrdersApiSpec {
    orderRequest( parameters: Order, options?: RequestOptions ): Promise<Response<OrderResponse>>
}

export function useOrdersApi (): OrdersApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { postRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to order a subscription.
     */
    const orderRequest = useCallback( ( parameters: Order ): Promise<Response<OrderResponse>> => {
        return postRequest<OrderResponse>( "order/send", parameters )
    }, [postRequest] )

    return {
        orderRequest,
    }
}
