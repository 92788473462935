import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.configuration.heading": {
        "en-US": "Configuration",
        "nl-NL": "Configuratie",
    },
    "pages.configuration.dns-template.section-title": {
        "en-US": "DNS template",
        "nl-NL": "DNS template",
    },
    "pages.configuration.dns-template.section-description": {
        "en-US": "By managing DNS templates you can easily set DNS for multiple domains.",
        "nl-NL": "Met het beheren van DNS templates kan je heel gemakkelijk voor meerdere domeinen DNS instellen.",
    },
    "pages.configuration.dns-templates.list.manage-title": {
        "en-US": "Manage DNS templates",
        "nl-NL": "Beheren DNS templates",
    },
    "pages.configuration.dns-templates.list.manage-description":{
        "en-US": "See all your DNS templates.",
        "nl-NL": "All uw DNS templates.",
    },
    "pages.configuration.dns-templates.list.create-title": {
        "en-US": "Add DNS templates",
        "nl-NL": "DNS template aanmaken",
    },
    "pages.configuration.dns-templates.list.create-description": {
        "en-US": "Add a new DNS template here.",
        "nl-NL": "Maak hier een nieuwe DNS template aan.",
    },
    "pages.configuration.domain-contact.section-title":{
        "en-US": "Domain Contact",
        "nl-NL": "Domein houder",
    },
    "pages.configuration.domain-contact.section-description":{
        "en-US": "With domain contacts you can apply the same information to multiple domains.",
        "nl-NL": "Met domein houders kun je de houder op meerdere domeinen toepassen. ",
    },
    "pages.configuration.domain-contact.list.manage-title":{
        "en-US": "Manage domain contacts",
        "nl-NL": "Beheer domein houders",
    },
    "pages.configuration.domain-contact.list.manage-description":{
        "en-US": "In this overview you will find your domain contacts.",
        "nl-NL": "In dit overzicht kunt u uw domein houders vinden.",
    },
    "pages.configuration.domain-contact.list.create-handle":{
        "en-US": "Add domain contact",
        "nl-NL": "Domein houder aanmaken",
    },
    "pages.configuration.domain-contact.list.create-description":{
        "en-US": "Add domain contact",
        "nl-NL": "Domein houder aanmaken",
    },
    "pages.configuration.security.list.title":{
        "en-US": "Two step verification",
        "nl-NL": "Twee staps verificatie",
    },
    "pages.configuration.security.list.description":{
        "en-US": "You can turn on/off your two step verification.",
        "nl-NL": "Zet uw twee staps verificatie aan of uit.",
    },
} as TranslationDictionary
