import { ExchangeAlt, Search } from "icons/font-awesome/light"
import { PlusOctagon } from "icons/font-awesome/solid"
import { Response, useDomainNameApi } from "modules/api-client"
import { DomainCheckResult } from "modules/api-client/src/domain-name/types"
import { Product, ProductPrice, ProductPriceType } from "modules/api-client/src/product/types"
import { useProductsData } from "modules/cached-data"
import { formatCurrency, stripDomainName } from "modules/helpers"
import { useCart } from "modules/orders"
import { getPrice } from "modules/orders/src/cart-context/cart-context"
import { useTranslations } from "modules/translations"
import { useRouter } from "next/router"
import React, { FormEvent, useEffect, useState } from "react"
import Button from "ui-kit/elements/button"
import Spinner from "ui-kit/elements/spinner"
import Input from "ui-kit/forms/input"
import SubmitButton from "ui-kit/forms/submit-button"
import PanelDivider from "ui-kit/layout/panel-divider"

interface DomainSearch {
    product: Product
    domain: string
    promise: Promise<DomainCheckResult|undefined>
}

export default function OrderDomainStep (): JSX.Element {
    const { products } = useProductsData()
    const { query } = useRouter()
    const [ searchQuery, setSearchQuery ] = useState( String( query.domain || "" ) )
    const [ isLoading, setIsLoading ] = useState<boolean>( false )
    const [ isPristine, setIsPristine ] = useState<boolean>( true )
    const [ searchResults, setSearchResults ] = useState<DomainSearch[]>( [] )
    const { checkDomainRequest } = useDomainNameApi()
    const { translate } = useTranslations()
    const tlds: Product[] = products?.filter( ( product: Product ): boolean => product.type === "extension" ) || []

    function search ( event: FormEvent ): void {
        event.preventDefault()
        setIsLoading( true )
        setIsPristine( false )
        setSearchResults( [] )
        const query = stripDomainName( searchQuery )

        const requests: DomainSearch[] = tlds.map( ( product: Product ): DomainSearch => {
            const domainName = query + product.name
            const request: Promise<DomainCheckResult | undefined> = checkDomainRequest( { domainName } ).then( ( response: Response<DomainCheckResult> ): DomainCheckResult | undefined => {
                if ( response.success ) {
                    return response.data
                }
            } )

            return {
                product,
                domain: domainName,
                promise: request,
            }
        } )
        setSearchResults( requests )

        Promise.all( requests.map( pendingSearch => pendingSearch.promise ) ).then( () => {
            setIsLoading( false )
        } )
    }

    return <>
        <form noValidate onSubmit={search} className="flex items-end space-x-4">
            <Input
                id="domain"
                label={translate( "pages.order.search_domain" )}
                className="flex-1"
                maxWidth="none"
                value={searchQuery}
                onChange={setSearchQuery}
                autoComplete="off"
                name="domain"
            />
            <div>
                <SubmitButton icon={Search}>{translate( "pages.order.search_domain" )}</SubmitButton>
            </div>
        </form>

        {!isPristine && <DomainSearchResults loading={isLoading} results={searchResults} />}
    </>
}


function DomainSearchResults ( props: { loading: boolean, results: DomainSearch[] } ): JSX.Element {

    return <>
        <div className="flex flex-col">
            {props.results.map( ( result: DomainSearch, i: number ): JSX.Element => <DomainSearchResult result={result} key={i} /> )}
        </div>

        {props.loading && <>
            <PanelDivider/>
            <div className="flex justify-center">
                <Spinner className="text-2xl text-brand-500 my-4" />
            </div>
        </>}
    </>
}

function DomainSearchResult ( props: { result: DomainSearch } ): JSX.Element {

    const { cart, addItem, updateItem } = useCart()
    const [ data, setData ] = useState<DomainCheckResult>()
    const { translate } = useTranslations()
    props.result.promise.then( setData )

    const productCouplings = props.result.product.productCouplings ? props.result.product.productCouplings : undefined

    useEffect( () => {
        setData( undefined )
    }, [props.result.domain] )
    const type: ProductPriceType = data
        ? data.status === "active" ? "transfer" : "registration"
        : "registration"
    const price = props.result.product.prices.find( ( price: ProductPrice ): boolean => {
        return price.type === type
    } )

    function addToCart (): void {
        if ( ! price ) {
            return
        }
        addItem( props.result.domain, {
            name: translate( "pages.order.cart.domain" ),
            slug: props.result.product.slug,
            product: props.result.product,
            price: price,
        } )


        if( productCouplings ) {
            addCoupledProductToCart( productCouplings )
        }
    }

    function addCoupledProductToCart ( coupledProducts: Product[] ): void
    {
        coupledProducts.forEach( ( coupledProduct ) => {
            const price = coupledProduct.prices.find( ( price: ProductPrice ): boolean => {
                return price.type === "conditional" && price.period === 12
            } )

            if( ! price ){
                return
            }

            const domain = coupledProduct.type === "extension" ? stripDomainName( props.result.domain ) + coupledProduct.name : props.result.domain

            const cartItem = {
                name: coupledProduct.type === "extension" ? translate( "pages.order.cart.domain" ) : coupledProduct.name,
                slug: coupledProduct.slug,
                product: coupledProduct,
                price: price,
            }

            if( Object.keys( cart.domains ).includes( domain ) ){
                updateItem( domain, cartItem )
            } else {
                addItem( domain, cartItem )
            }
        } )
    }

    const inCart = Object.keys( cart.domains ).includes( props.result.domain )

    return data ? <div>
        <PanelDivider />
        <div className="flex flex-row items-center h-15 -my-2">
            <div className="flex-1 flex justify-between">
                <span>
                    {props.result.domain}
                </span>
                <span className="mx-4 font-medium text-gray-700">
                    {!! price && !inCart && formatCurrency( getPrice( price, "net" ) / 100 )}
                </span>
            </div>

            {data.status === "active" && !inCart && <>
                <Button onClick={() => addToCart()}>
                    <ExchangeAlt className="mr-2" /> {translate( "pages.order.order.transfer" )}
                </Button>
            </>}

            {data.status === "free" && !inCart && <>
                <Button onClick={() => addToCart()}>
                    {translate( "pages.order.order.order" )}
                </Button>
            </>}

            {inCart && <>
                <span className="text-sm text-gray-700">{translate( "pages.order.order.in_cart" )}</span>
            </>}
        </div>
        {!! props.result.product.productCouplings && <ProductCoupling product={props.result.product} productCouplings={props.result.product.productCouplings}/>}
    </div> : <></>
}

function ProductCoupling ( props: { product: Product, productCouplings: Product[]} ): JSX.Element {
    const { translate } = useTranslations()
    //We just show the first productCoupling. If there is no couplings we wouldn't even come in this component.
    const coupledProduct: Product = props.productCouplings[0]

    return <div className="-mx-4 sm:-mx-6 flex flex-row justify-between text-white text-sm py-2 pl-4 -mb-5 mt-4 bg-brand-500">
        <span className="mx-6 my-1 font-semibold">
            <PlusOctagon className="mr-2"/>
            {translate(
                "pages.order.order.product_coupling_upsell", {
                    parentProductName:props.product.name,
                    parentProductType: translate( `general.product-group.${props.product.type}` ),
                    coupledProductName:coupledProduct.name,
                    coupledProductType:translate( `general.product-group.${coupledProduct.type}` ),
                }
            )}
        </span>
    </div>
}
