import { RequestOptions, Response, ResponseError } from "modules/api-client/src/http-client/src/types"
import { Customer } from "modules/auth/src/types"

export async function sendRequest<T> ( url: string, config: RequestInit, customer?: Customer, options?: RequestOptions ): Promise<Response<T>> {

    const responseContentType = options?.responseContentType ?? "application/json"

    const headers: {[key: string]: string} = {
        "Accept": responseContentType,
        "Content-Type": "application/json",
    }

    if ( customer ) {
        headers["X-Customer-Id"] = `${customer.id}`
    }

    const response = await fetch( url, {
        headers,
        credentials: "include",
        ...config,
    } )

    const body = responseContentType === "application/json"
        ? await response.json() as T | ResponseError
        : await response.text() as string

    if ( response.status < 200 || response.status >= 400 ) {
        return {
            success: false,
            statusCode: response.status,
            error: body as ResponseError,
        }
    }
    return {
        success: true,
        statusCode: response.status,
        data: body as T,
    }
}
