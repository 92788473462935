import classNames from "classnames"
import React from "react"
import FormField, { FormFieldBaseProps } from "ui-kit/forms/form-field"

export interface SelectProps extends FormFieldBaseProps {

    /**
     * The available options for this select. If given a string array, it will
     * use the strings as both value and label. If given string pairs, it will
     * use the first string as value and the second as label.
     */
    options: ReadonlyArray<string | [string, string]>

    /**
     * Optional hint for the type of data to suggest for auto-completing this
     * field
     */
    autoComplete?: string

    /**
     * Optional ref object to connect to the select element
     */
    selectRef?: React.RefObject<HTMLSelectElement>
}

/**
 * A dropdown with a pre-defined set of options for the user to choose from.
 */
export default function Select ( props: SelectProps ): JSX.Element {
    const hasErrors = props.errors ? props.errors.length > 0 : false

    return (
        <FormField {...props}>
            <select ref={props.selectRef} id={props.id} name={props.name} value={props.value}
                onChange={ev => props.onChange( ev.target.value, props.id )}
                required={props.required ?? true}
                autoComplete={props.autoComplete}
                aria-invalid={hasErrors}
                aria-describedby={`${props.id}_errors`}
                className={classNames( [
                    "mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 rounded-md shadow-sm sm:text-sm sm:leading-5",
                    { "border-red-500": hasErrors },
                ] )}>
                {/* https://github.com/microsoft/TypeScript/issues/7294 */}
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                {( props.options as any[] ).map( ( option: string | [string, string] ) => {
                    const value = typeof option === "string" ? option : option[0]
                    const label = typeof option === "string" ? option : option[1]
                    return <option value={value} key={value}>{label}</option>
                } )}
            </select>
        </FormField>
    )
}
