import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.subscriptions.download_csv": {
        "en-US": "Download CSV",
        "nl-NL": "Download CSV",
    },
    "pages.subscriptions.page_heading": {
        "en-US": "Subscriptions",
        "nl-NL": "Abonnementen",
    },
    "pages.subscriptions.retrieval_error": {
        "en-US": "Currently unable to retrieve subscription data. Please try again later.",
        "nl-NL": "Het is niet gelukt om de abonnementsgegevens op te halen. Probeer het later nog eens.",
    },
    "pages.subscriptions.table.header.customer": {
        "en-US": "Customer",
        "nl-NL": "Klant",
    },
    "pages.subscriptions.table.header.domain": {
        "en-US": "Domain",
        "nl-NL": "Domein",
    },
    "pages.subscriptions.table.header.end_date": {
        "en-US": "End date",
        "nl-NL": "Einddatum",
    },
    "pages.subscriptions.table.header.period": {
        "en-US": "Period",
        "nl-NL": "Periode",
    },
    "pages.subscriptions.table.header.product_group": {
        "en-US": "Product group",
        "nl-NL": "Productgroep",
    },
    "pages.subscriptions.table.header.product_name": {
        "en-US": "Product name",
        "nl-NL": "Productnaam",
    },
    "pages.subscriptions.table.header.status": {
        "en-US": "Status",
        "nl-NL": "Status",
    },
    "pages.subscriptions.table.status.active": {
        "en-US": "Active",
        "nl-NL": "Actief",
    },
    "pages.subscriptions.table.status.canceled": {
        "en-US": "Canceled",
        "nl-NL": "Geannuleerd",
    },
    "pages.subscriptions.table.status.deleted": {
        "en-US": "Deleted",
        "nl-NL": "Verwijderd",
    },
    "pages.subscriptions.table.type.dns": {
        "en-US": "DNS",
        "nl-NL": "DNS",
    },
    "pages.subscriptions.table.type.domein-uitbreiding": {
        "en-US": "Domain extension",
        "nl-NL": "Domeinuitbreiding",
    },
    "pages.subscriptions.table.type.extension": {
        "en-US": "Extension",
        "nl-NL": "Extensie",
    },
    "pages.subscriptions.table.type.hosting": {
        "en-US": "Hosting",
        "nl-NL": "Hosting",
    },
    "pages.subscriptions.table.type.other": {
        "en-US": "Other",
        "nl-NL": "Overig",
    },
    "pages.subscriptions.table.type.server": {
        "en-US": "Server",
        "nl-NL": "Server",
    },
    "pages.subscriptions.table.type.ssl": {
        "en-US": "SSL",
        "nl-NL": "SSL",
    },
    "pages.subscriptions.table.type.vps": {
        "en-US": "VPS",
        "nl-NL": "VPS",
    },
    "pages.subscriptions.table.type.partnerprogramma": {
        "en-US": "Partner program",
        "nl-NL": "Partnerprogramma",
    },
    "pages.subscriptions.table.type.server-addon": {
        "en-US": "Server addon",
        "nl-NL": "Server uitbreiding",
    },

} as TranslationDictionary
