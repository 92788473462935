import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { BecomeResellerRequest } from "modules/api-client/src/reseller/request/become-reseller-params"
import { BecomeResellerResponse } from "modules/api-client/src/reseller/response/become-reseller-response"
import { Reseller } from "modules/api-client/src/reseller/types"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

export interface ResellerApiSpec {
    becomeReseller( parameters: BecomeResellerRequest ): Promise<Response<BecomeResellerResponse>>
    resellerRequest( options?: RequestOptions ): Promise<Response<Reseller>>
}

export function useResellerApi (): ResellerApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, postRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to create a reseller subscription
     */
    const becomeReseller = useCallback( ( parameters: BecomeResellerRequest ): Promise<Response<BecomeResellerResponse>> => {
        return postRequest<BecomeResellerResponse>( "reseller", parameters )
    }, [postRequest] )

    /**
     * Request to retrieve Reseller status information and product prices.
     */
    const resellerRequest = useCallback( ( options?: RequestOptions ): Promise<Response<Reseller>> => {
        return getRequest<Reseller>( "reseller", {}, options )
    }, [getRequest] )

    return {
        becomeReseller,
        resellerRequest,
    }
}
