import { Response, useHttpClient } from "modules/api-client/src/http-client"
import { ResetNameServersParams } from "modules/api-client/src/nameservers/requests/reset-nameservers-params"
import { RetrieveNameServersParams } from "modules/api-client/src/nameservers/requests/retrieve-nameservers-params"
import { UpdateNameServersParams } from "modules/api-client/src/nameservers/requests/update-nameservers-params"
import { ResetNameServerResponse } from "modules/api-client/src/nameservers/response/reset-nameservers-response"
import { UpdateNameServerResponse } from "modules/api-client/src/nameservers/response/update-nameservers-response"
import { NameServers } from "modules/api-client/src/nameservers/types"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface NameServersApiSpec {
    nameServersRequest( parameters: RetrieveNameServersParams ): Promise<Response<NameServers>>
    updateNameServerRequest( parameters: UpdateNameServersParams ): Promise<Response<UpdateNameServerResponse>>
    resetNameServerRequest( parameters: ResetNameServersParams ): Promise<Response<ResetNameServerResponse>>
}

export function useNameServersApi (): NameServersApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, putRequest, postRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retreive nameservers info from the API.
     */
    const nameServersRequest = useCallback( ( parameters: RetrieveNameServersParams ): Promise<Response<NameServers>> => {
        return getRequest<{ data: NameServers }>( `domain-name/${parameters.domain}/nameservers` )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    /**
     * Request to update nameservers info.
     */
    const updateNameServerRequest = useCallback( ( parameters: UpdateNameServersParams ): Promise<Response<UpdateNameServerResponse>> => {
        return putRequest<UpdateNameServerResponse>( `domain/${parameters.domain}/nameservers`, parameters )
    }, [putRequest] )

    /**
     * Request to reset nameservers
     */
    const resetNameServerRequest = useCallback( ( parameters: ResetNameServersParams ): Promise<Response<ResetNameServerResponse>> => {
        return postRequest<ResetNameServerResponse>( `domain/${parameters.domain}/nameservers/reset` )
    }, [postRequest] )

    return {
        nameServersRequest,
        updateNameServerRequest,
        resetNameServerRequest,
    }
}
