import * as Sentry from "@sentry/node"
import getConfig from "next/config"
import React, { createContext, useCallback, useState } from "react"

const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: { defaultLocale: string } }

interface Translation {
    [language: string]: string
}
export interface TranslationDictionary {
    [index: string]: Translation
}

export interface TranslationParameters {
    [name: string]: string
}

export interface TranslationContextType {
    translations: TranslationDictionary
    language: string

    /**
     * Return the translated string with the given index in the current language.
     * When named parameters are given, these will be replaced in the translated
     * string.
     *
     * When there is no translation for the given index, an error message is
     * returned instead.
     *
     * When there are named parameter markers in the translation but no such
     * parameter is given, the translation is appended with an error message.
     *
     * @param index The index of the translation to return
     * @param parameters The named parameters to replace in the translated string.
     * Because the parameters are replaced using a RegEx, the parameter names may
     * not contain these special characters: \ ^ $ * + ? . ( ) | { } [ ]
     */
    translate( index: string, parameters?: TranslationParameters ): string
    loadTranslation( index: string, translation: Translation ): void
    setLocale: React.Dispatch<React.SetStateAction<string>>
}

export const TranslationContext = createContext<TranslationContextType>( {} as TranslationContextType )

interface TranslationContextProps {
    dictionary: TranslationDictionary
    children: React.ReactNode
}

export function TranslationContextProvider ( props: TranslationContextProps ): JSX.Element {
    const [translations, setTranslations] = useState<TranslationDictionary>( props.dictionary )
    const [language, setLanguage] = useState<string>( publicRuntimeConfig.defaultLocale )

    const checkMissingParameters = useCallback( ( originalTranslation: string, translationKey: string ): string => {
        let translation: string = originalTranslation
        const missingParameters = translation.match( /\{\w+\}/g ) || []
        missingParameters.forEach( missing => {
            translation += ` [No parameter given for marker ${missing} in ${translationKey}<${language}>]`
        } )
        return translation
    }, [language] )

    const replaceParameters = useCallback( ( originalTranslation: string, parameters: TranslationParameters ): string => {
        let translation: string = originalTranslation
        Object.entries( parameters ).forEach( ( [name, value] ) => {
            translation = translation!.replace( new RegExp( `{${name}}`, "g" ), value )
        } )
        return translation
    }, [] )

    const translate = useCallback( ( index: string, parameters?: TranslationParameters ): string => {
        let translation: string | undefined = undefined
        if ( index in translations ) {
            if ( language in translations[index] ) {
                translation = translations[index][language]
            }
        }
        if ( !translation ) {
            if ( process.env.NODE_ENV === "production" ) {
                // Silenty send a missing translation message to Sentry.
                Sentry.captureException(
                    new Error( `[Missing translation: ${index}<${language}>]` )
                )
                // Return the last portion of the translation index in order to get an acceptable
                // looking missing translation. Example: general.product-ssl.wildcard becomes wildcard.
                return index.split( /\.+/ ).pop() || index
            }
            return `[Missing translation: ${index}<${language}>]`
        }
        return checkMissingParameters(
            parameters ? replaceParameters( translation, parameters ) : translation,
            index
        )
    }, [checkMissingParameters, language, replaceParameters, translations] )

    const loadTranslation = useCallback( ( index: string, translation: Translation ): void => {
        const newTranslations = Object.assign( translations ) as TranslationDictionary
        newTranslations[index] = translation
        setTranslations( newTranslations )
    }, [translations] )

    return (
        <TranslationContext.Provider
            value={{
                translations,
                language,
                translate,
                loadTranslation,
                setLocale: setLanguage,
            }}>
            {props.children}
        </TranslationContext.Provider>
    )
}
