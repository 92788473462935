import React, { FC, ReactNode } from "react"
import { ButtonSize, ButtonVariant } from "ui-kit/elements/button"
import HyperLink from "ui-kit/elements/hyper-link"

export interface LinkButtonProps {
    /**
     * The URL for the hyper link to point to
     */
    href: string

    /**
     * The variant of LinkButton to show, default is 'white' (see `<Button>`)
     */
    variant?: ButtonVariant

    /**
     * Size of button to show, default is 'md'
     */
     size?: ButtonSize

    /**
     * Icon to show with the button
     */
    icon?: FC<{className?: string}>

    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    children: ReactNode
}

/**
 * A hyperlink, displayed as a button.
 */
export default function LinkButton ( props: LinkButtonProps ): JSX.Element {
    return (
        <HyperLink href={props.href} className={`btn btn-${props.variant || "white"} btn-${props.size || "md"} ${props.className || ""}`} variant="unstyled">
            {props.icon && <props.icon className="mr-3 -ml-1" />}
            {props.children}
        </HyperLink>
    )
}
