import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "general.no": {
        "en-US": "No",
        "nl-NL": "Nee",
    },
    "general.yes": {
        "en-US": "Yes",
        "nl-NL": "Ja",
    },
    "general.delete": {
        "en-US": "Delete",
        "nl-NL": "Verwijder",
    },
    "general.cancel": {
        "en-US": "Cancel",
        "nl-NL": "Annuleer",
    },
    "general.edit": {
        "en-US": "Edit",
        "nl-NL": "Bewerken",
    },
    "general.save": {
        "en-US": "Save",
        "nl-NL": "Bewaar",
    },
    "general.back":{
        "en-US": "Back",
        "nl-NL": "Terug",
    },
    "general.finish":{
        "en-US": "Finish",
        "nl-NL": "Afronden",
    },
    "general.search":{
        "en-US": "Search",
        "nl-NL": "Zoeken",
    },
    "general.on": {
        "en-US": "On",
        "nl-NL": "Aan",
    },
    "general.off": {
        "en-US": "Off",
        "nl-NL": "Uit",
    },
    "general.saved": {
        "en-US": "Your changes have been saved.",
        "nl-NL": "Uw wijzigingen zijn opgeslagen.",
    },
    "general.active": {
        "en-US": "Active",
        "nl-NL": "Actief",
    },
    "general.in-active": {
        "en-US": "Inactive",
        "nl-NL": "Inactief",
    },
    "general.actions": {
        "en-US": "Actions",
        "nl-NL": "Acties",
    },
    "general.asterisk": {
        "en-US": "*",
        "nl-NL": "*",
    },
    "general.n_a": {
        "en-US": "n/a",
        "nl-NL": "n/a",
    },
    "general.product-not-found": {
        "en-US": "The product could not be found.",
        "nl-NL": "Het product is niet gevonden",
    },
    "general.configure": {
        "en-US": "Configure",
        "nl-NL": "Instellen",
    },
    "general.modify": {
        "en-US": "Modify",
        "nl-NL": "Wijzigen",
    },
    "general.turn-on": {
        "en-US": "Turn on",
        "nl-NL": "Aanzetten",
    },
    "general.turn-off": {
        "en-US": "Turn off",
        "nl-NL": "Uitzetten",
    },
    "general.save-button": {
        "en-US": "Save changes",
        "nl-NL": "Wijzigingen opslaan",
    },
    "general.confirm": {
        "en-US": "Confirm",
        "nl-NL": "Akkoord",
    },
    "general.expand": {
        "en-US": "Expand",
        "nl-NL": "Uitklappen",
    },
    "general.collapse": {
        "en-US": "Collapse",
        "nl-NL": "Inklappen",
    },
    "general.page": {
        "en-US": "Page",
        "nl-NL": "Pagina",
    },
    "general.search-no-result":{
        "en-US": "We can’t find anything with that term at the moment.",
        "nl-NL": "We konden helaas niets vinden met jouw zoekterm.",
    },
} as TranslationDictionary
