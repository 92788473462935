import { PlusOctagon, Trash } from "icons/font-awesome/regular"
import { useCustomersApi } from "modules/api-client"
import { useDomainContactApi } from "modules/api-client/src/domain-contact/hook"
import { DomainContact } from "modules/api-client/src/domain-contact/types"
import { Product } from "modules/api-client/src/product/types"
import { useServersApi } from "modules/api-client/src/servers/hook"
import { Server } from "modules/api-client/src/servers/types"
import { useAuth } from "modules/auth"
import { Customer } from "modules/auth/src/types"
import { useServicesData } from "modules/cached-data"
import { can, formatCurrency, useFetchedApiResource } from "modules/helpers"
import { CartItem, CartItemGroup, useCart } from "modules/orders"
import { getAddonPrice, getPrice } from "modules/orders/src/cart-context/cart-context"
import { ConfigureContactHandle } from "modules/orders/src/components/configuration/configure-contact-handle"
import { ConfigureForwardingUrl } from "modules/orders/src/components/configuration/configure-forwarding-url"
import { ConfigurePeriod } from "modules/orders/src/components/configuration/configure-period"
import { ConfigurePrivacyProtection } from "modules/orders/src/components/configuration/configure-privacy-protection"
import { ConfigureServer } from "modules/orders/src/components/configuration/configure-server"
import { ConfigureSsl } from "modules/orders/src/components/configuration/configure-ssl"
import { ConfigureTransferCode } from "modules/orders/src/components/configuration/configure-transfer-code"
import { ConfigureWordPress } from "modules/orders/src/components/configuration/configure-wordpress"
import { useTranslations } from "modules/translations"
import React, { useEffect, useState } from "react"
import Button from "ui-kit/elements/button"
import Paragraph from "ui-kit/elements/paragraph"
import Spinner from "ui-kit/elements/spinner"
import Select from "ui-kit/forms/select"
import PanelDivider from "ui-kit/layout/panel-divider"

export const isPrivacyProduct = ( productSlug: string ): boolean => ["extension_privacy_bescherming", "extension_privacy_protection"].includes( productSlug )
export const isForwardingProduct = ( productSlug: string ): boolean => ["hosting_forwarding", "hosting_doorsturen"].includes( productSlug )
export const isContactHandleProduct = ( productSlug: string ): boolean => ["extension_domein_houder", "extension_domain_contact"].includes( productSlug )
export const isEmailProduct = ( productSlug: string ): boolean => ["hosting_mail_only"].includes( productSlug )

export default function OrderOverviewStep (): JSX.Element {
    const { serversRequest } = useServersApi()
    const { customersRequest } = useCustomersApi()
    const { domainContactRequest } = useDomainContactApi()
    const { cart } = useCart()
    const { customer } = useAuth()

    const {
        resource: domainContacts,
        fetchResource: fetchDomainContacts,
    } = useFetchedApiResource( () => domainContactRequest() )
    const {
        resource: servers,
        fetchResource: fetchServers,
    } = useFetchedApiResource( () => serversRequest() )
    const {
        resource: customers,
        fetchResource: fetchCustomers,
        isLoading: loadingCustomers,
    } = useFetchedApiResource( () => customersRequest() )

    // Only retrieve server data if hosting item is present in cart.
    const hostingProductInCart = Object.values( cart.domains || {} )
        .some( ( cartItemGroup ) => cartItemGroup.items
            .some( ( cartItem ) => cartItem.product.type === "hosting" )
        )

    useEffect( () => {
        if ( ! domainContacts ) {
            fetchDomainContacts()
        }
    }, [domainContacts, fetchDomainContacts] )

    useEffect( () => {
        if ( hostingProductInCart && ! servers ) {
            fetchServers()
        }
    }, [hostingProductInCart, servers, fetchServers] )
    useEffect( () => {
        if ( can( customer!, "canGiveWhitelabelAccess" ) ) {
            fetchCustomers()
        }
    }, [customer, fetchCustomers] )

    return <>
        {!!customer?.is_partner && loadingCustomers && <>
            <div className="flex flex-row items-center justify-center h-32">
                <Spinner className="text-3xl text-brand-500" />
            </div>
            <PanelDivider />
        </>}
        {customers && customers.length > 0 && <CustomerSelect customers={customers} />}
        <CartOverview servers={servers || []} domainContact={domainContacts || []} />
    </>
}

function CustomerSelect ( { customers }: {customers: Customer[]} ): JSX.Element {
    const { translate } = useTranslations()
    const { customer } = useAuth()
    const { cart, setCustomerId } = useCart()
    const { serviceByDomainAndType } = useServicesData()

    const customerOptions: Array<[string, string]> = customers
        .filter( c => c.id !== customer!.id )
        .map( c => [`${c.id}`, c.name] )
    customerOptions.sort( ( a, b ) => a[1].localeCompare( b[1] ) )
    customerOptions.unshift( [`${customer!.id}`, translate( "pages.order.select-customer-self" )] )

    const [ options, setOptions ] = useState( customerOptions )

    useEffect( () => {
        if ( ! cart.inSingleProductMode || Object.keys( cart.domains ).length === 0 || options.length === 1 ) {
            return
        }

        const resolveExistingServiceCustomerId = ( cartDomain: string ): number | undefined => {
            const existingService = serviceByDomainAndType( cartDomain, "extension" ) ??
                serviceByDomainAndType( cartDomain, "hosting" ) ??
                serviceByDomainAndType( cartDomain, "ssl" )

            return existingService?.customer_id
        }

        const domain = Object.keys( cart.domains )[0]
        const customerId = resolveExistingServiceCustomerId( domain )

        if ( customerId !== undefined ) {
            setCustomerId( customerId )
            setOptions( Object.values( options ).filter( option => option[0] === String( customerId ) ) )
        }
    }, [customer, cart, options, setOptions, serviceByDomainAndType, setCustomerId] )

    const changeSelection = ( id: string ): void => {
        setCustomerId( parseInt( id, 10 ) )
    }

    return <>
        {Object.keys( cart ).length === 0 ? <>
            <p>{translate( "modules.orders.error-no-domain-chosen" )}</p>
        </> : <>
            <section>
                <Paragraph>
                    {translate( "pages.order.select_customer_description" )}
                </Paragraph>
                <Select id="select-customer" name="select-customer" label={translate( "pages.order.select-customer-label" )}
                    options={options} value={`${cart.customerId}`} onChange={changeSelection} />
            </section>
        </>}

        <PanelDivider />
    </>
}

function CartOverview ( props: { servers: Server[], domainContact: DomainContact[] } ): JSX.Element {
    const { translate } = useTranslations()
    const { customer } = useAuth()
    const { cart, totalPrice } = useCart()
    const rate = customer!.vat_rate === null ? 0 : Math.round( customer!.vat_rate )
    const exVatPrice = totalPrice
    const vatPrice = exVatPrice * ( rate / 100 )
    const incVatPrice = exVatPrice + vatPrice

    const groups = Object.entries( cart.domains ).map( params => {
        const domain: string = params[0]
        const group: CartItemGroup = params[1]
        return <CartOverviewGroup key={domain} domain={domain} group={group} servers={props.servers} domainContact={props.domainContact} />
    } )

    if ( groups.length === 0 ) {
        return <Paragraph className="text-center">
            {translate( "pages.order.cart.cart_empty" )}
        </Paragraph>
    }

    return <div className="flex flex-col space-y-4">
        {/* Cart entries */}
        {groups}
        {/* Totals */}
        <div className="flex justify-end pr-8 py-4">
            <div className="space-y-2 w-full md:w-1/2 lg:w-1/3">
                <div className="flex justify-between">
                    <span>{translate( "pages.order.cart.sub-total" )}</span>
                    <span>{formatCurrency( exVatPrice / 100 )}</span>
                </div>
                <div className="flex justify-between">
                    <span>{translate( "pages.order.cart.vat-rate", { rate: String( rate ) } )}</span>
                    <span>{formatCurrency( vatPrice / 100 )}</span>
                </div>
                <div className="flex justify-between font-bold">
                    <span>{translate( "pages.order.cart.total" )}</span>
                    <span>{formatCurrency( incVatPrice / 100 )}</span>
                </div>
            </div>
        </div>
    </div>
}

function CartOverviewItem ( props: { domain?: string, item: CartItem, servers: Server[], domainContact: DomainContact[] } ): JSX.Element {
    const { translate } = useTranslations()
    const { removeItem } = useCart()
    const addons = props.item.addons || []

    return <div className="border border-gray-200 rounded">
        <div className="py-8 px-8 flex items-center">
            {/* Main content */}
            <div className="flex-1 flex flex-col sm:flex-row">
                {/* Information */}
                <div className="flex-1">
                    <div className="flex items-center">
                        <span className="font-bold text-lg">{props.domain}</span>
                        <span className="block mx-2">&middot;</span>
                        <span className="mt-1 block text-gray-500 uppercase font-medium text-sm tracking-wide">{props.item.name}</span>
                    </div>
                    {/* Period */}
                    <div className="pt-4 w-48">
                        {props.domain && <ConfigurePeriod domain={props.domain} item={props.item} />}
                    </div>
                </div>

                {/* Controls */}
                <div className="flex flex-row items-center mt-4 sm:mt-0">
                    <Button onClick={() => removeItem( props.domain!, props.item )} icon={Trash}>
                        {translate( "pages.order.cart_remove_item" )}
                    </Button>
                </div>
            </div>

            {/* Price */}
            <div className="font-bold ml-8 w-20 text-right">
                {formatCurrency( getPrice( props.item.price, "net" ) / 100 )}
            </div>
        </div>
        {/* Addons */}
        {addons.length > 0 && props.domain && <>
            <div className="p-8 border-t border-gray-200">
                {addons.map( ( addon, index, addons ) => <CartOverviewItemAddon domain={props.domain!} item={props.item} addon={addon} key={index} isLast={index === addons.length - 1} /> )}
            </div>
        </>}
        {/* Configuration */}
        {props.domain && <CartOverviewItemConfig domain={props.domain} item={props.item} domainContact={props.domainContact} servers={props.servers} />}
    </div>
}

/* eslint-disable-next-line complexity */
function CartOverviewItemConfig ( props: {domain: string, item: CartItem, servers: Server[], domainContact: DomainContact[]} ): JSX.Element {
    // Flags
    const hasServersToConfigure = props.item.product.type === "hosting"
        && props.domain
        && props.servers
        && props.servers.length > 0
        && props.item.product.descriptors.small["product-spec.hosting.services.forwarding"] !== true
        && ! isEmailProduct( props.item.product.slug )

    const hasTransferCodeToConfigure = props.item.product.type === "extension"
        && props.domain
        && props.item.price.type === "transfer"

    const hasSslToConfigure = props.item.product.type === "ssl"
        && props.domain

    const hasPrivacyProtectionToConfigure = props.item.product.type === "extension"
        && props.domain
        && ( props.item.price.type === "registration" || props.item.price.type === "conditional" )
        && props.item.product.descriptors.small["product-spec.domain.allow_whois_private"] === "yes"
        && ! props.item.addons?.find( product => isPrivacyProduct( product.slug ) )

    const hasContactHandleToSelect = props.item.product.type === "extension"
        && props.domain
        && ( props.item.price.type === "registration" || props.item.price.type === "conditional" )
        && ! props.item.addons?.find( product => isContactHandleProduct( product.slug ) )

    const hasForwardingUrlToConfigure = props.item.product.type === "hosting"
        && props.domain
        && isForwardingProduct( props.item.product.slug )

    const hasInstallWordPressOption = props.item.product.type === "hosting"
        && props.domain
        && props.item.product.descriptors.small["product-spec.hosting.services.wordpress"] === true

    // Needs any configuration?
    const needsConfiguration = hasServersToConfigure || hasSslToConfigure || hasTransferCodeToConfigure || hasPrivacyProtectionToConfigure || hasForwardingUrlToConfigure || hasContactHandleToSelect || hasInstallWordPressOption

    return needsConfiguration && props.domain ? <>
        <div className="p-8 border-t border-gray-200 bg-gray-50">
            <div className="-mb-8">
                {hasServersToConfigure && <ConfigureServer domain={props.domain} item={props.item} servers={props.servers} />}
                {hasContactHandleToSelect && <ConfigureContactHandle domain={props.domain} item={props.item} contactHandles={props.domainContact} />}
                {hasSslToConfigure && <ConfigureSsl domain={props.domain} item={props.item} />}
                {hasTransferCodeToConfigure && <ConfigureTransferCode domain={props.domain} item={props.item} />}
                {hasPrivacyProtectionToConfigure && <ConfigurePrivacyProtection domain={props.domain} item={props.item} />}
                {hasForwardingUrlToConfigure && <ConfigureForwardingUrl domain={props.domain} item={props.item} />}
                {hasInstallWordPressOption && <ConfigureWordPress domain={props.domain} item={props.item} />}
            </div>
        </div>
    </> : <></>
}

function CartOverviewItemAddon ( props: {domain: string, item: CartItem, addon: Product, isLast: boolean} ): JSX.Element {
    const { translate } = useTranslations()
    const { updateItem } = useCart()

    function removeItem (): void {
        updateItem( props.domain, {
            ...props.item,
            addons: props.item.addons!.filter( item => item.name !== props.addon.name ),
        } )
    }

    return <div className={`flex items-center w-full ${!props.isLast && "mb-8"}`}>
        <div className="flex-1 flex justify-between items-center text-gray-700">
            <div>
                <PlusOctagon className="mr-2" /> {props.addon.name}
            </div>
            <div className="flex flex-row items-center mt-4 sm:mt-0">
                <Button onClick={removeItem} icon={Trash}>
                    {translate( "pages.order.cart_remove_item" )}
                </Button>
            </div>
        </div>
        {/* Price */}
        <div className="font-bold ml-8 w-20 text-right">
            {formatCurrency( getAddonPrice( props.item, props.addon, "net" ) / 100 )}
        </div>
    </div>
}

function CartOverviewGroup ( props: {domain: string, group: CartItemGroup, servers: Server[], domainContact: DomainContact[]} ): JSX.Element {
    return <>{props.group.items.map( ( item: CartItem, i: number ) => <CartOverviewItem domain={props.domain} item={item} servers={props.servers} domainContact={props.domainContact} key={i} /> )}</>
}
