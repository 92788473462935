import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.switch-customer.customer": {
        "en-US": "Customer",
        "nl-NL": "Klant",
    },
    "pages.switch-customer.organization": {
        "en-US": "Organization",
        "nl-NL": "Organisatie",
    },
    "pages.switch-customer.switch": {
        "en-US": "Switch",
        "nl-NL": "Overschakelen",
    },
    "pages.switch-customer.switch-customer": {
        "en-US": "Switch to customer",
        "nl-NL": "Schakel over naar klant",
    },

} as TranslationDictionary
