import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.logout.logout": {
        "en-US": "Logout",
        "nl-NL": "Log uit",
    },
    "pages.logout.confirmation": {
        "en-US": "Do you want to log out?",
        "nl-NL": "Wil je uitloggen?",
    },
    "pages.logout.cancel": {
        "en-US": "Stay logged in",
        "nl-NL": "Blijf ingelogd",
    },


} as TranslationDictionary
