import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.contact.newsletter": {
        "en-US": "Newsletter",
        "nl-NL": "Nieuwsbrief",
    },
    "pages.contact.newsletter-intro": {
        "en-US": "Subscribe to our newsletter to stay up to date regarding our services and discounts. Emails regarding your service subscriptions can't be turned off.",
        "nl-NL": "Schrijf je in op onze nieuwsbrief om op de hoogte te blijven van de laatste ontwikkelingen omtrent onze diensten en leuke acties. E-mails met betrekking tot jouw diensten ontvang je altijd en kun je niet uitschakelen.",
    },
    "pages.contact.confirm-subscribe": {
        "en-US": "You are subscribed to our newsletter.",
        "nl-NL": "Je bent aangemeld voor onze nieuwsbrief.",
    },
    "pages.contact.confirm-unsubscribe": {
        "en-US": "You are no longer subscribed to our newsletter.",
        "nl-NL": "Je bent uitgeschreven van onze nieuwsbrief.",
    },
} as TranslationDictionary
