import { CartItem, useCart } from "modules/orders"
import { useTranslations } from "modules/translations"
import React, { useCallback, useState } from "react"
import Select from "ui-kit/forms/select"

export function ConfigurePeriod ( { domain, item }: {domain: string, item: CartItem} ): JSX.Element {
    const [period, setPeriod] = useState( String( item.price.period ) )
    const { updateItem } = useCart()
    const { translate } = useTranslations()

    const changePeriod = useCallback( ( period: string ) => {
        setPeriod( String( period ) )
        const newItem: CartItem = {
            ...item,
            price: item.product.prices.find( productPrice => productPrice.period === + period && productPrice.type === "registration" )!,
        }
        updateItem( domain, newItem )
    }, [domain, updateItem, item] )

    return <Select
        id="period"
        label={translate( "pages.order.period" )}
        onChange={( newValue ) => changePeriod( newValue )}
        options={
            item.product.prices.filter( productPrice => productPrice.type === "registration" )
                .map( productPrice => [String( productPrice.period ), `${productPrice.period} ${translate( "pages.order.month" )}` ] )
        }
        value={period}
    />
}
