import { EntityWithACL } from "modules/api-client/src/http-client/src/types"

/**
 * Check wether your current session can execute an action on a given entity.
 */
export default function can <T, U extends T> ( entity: EntityWithACL<T>, permission: U ): boolean {
    if ( !entity.available_actions ) {
        return false
    }
    return entity.available_actions.includes( permission )
}
