import classNames from "classnames"
import React from "react"
import FormField, { FormFieldBaseProps } from "ui-kit/forms/form-field"

export type InputType = "email" | "number" | "password" | "search" | "tel" | "text" | "url"
export type InputInputmode = "decimal" | "email" | "numeric" | "search" | "tel" | "text" | "url"
export type InputAutoCapitalizationMode = "none" | "sentences" | "words" | "characters"

export interface InputProps extends FormFieldBaseProps {
    /**
     * Optional prefix to show before the input
     */
    prefix?: string

    /**
     * Optional postfix to show after the input
     */
    postfix?: string

    /**
     * The type of input requested of the user (default "text")
     */
    type?: InputType

    /**
     * Optional override for the kind of on-screen keyboard to show users (in
     * case this is not determined by the type already)
     */
    inputMode?: InputInputmode

    /**
     * Optional hint for how the capitalization in this field should be
     * auto-corrected
     */
    autoCapitalize?: InputAutoCapitalizationMode

    /**
     * Optional hint for the type of data to suggest for auto-completing this
     * field
     */
    autoComplete?: string

    /**
     * Optional placeholder for the input
     */
    placeholder?: string

    /**
     * Optional additional attributes to place on the input
     */ // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalAttributes?: Partial<Record<Exclude<keyof HTMLInputElement, "autoCapitalize" | "placeholder" | "autoComplete" | "id" | "inputMode" | "onChange" | "type" | "value" | "required">, any>>

    /**
     * Optional ref object to connect to the input element
     */
    inputRef?: React.RefObject<HTMLInputElement>
}

/**
 * This is a basic input field that users can type into. It can be used to ask
 * the user for any kind of string or number.
 */
export default function Input ( props: InputProps ): JSX.Element {
    const hasErrors = props.errors ? props.errors.length > 0 : false

    const prefix = props.prefix && <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
        {props.prefix}
    </span>
    const postfix = props.postfix && <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
        {props.postfix}
    </span>
    let roundingClass = "rounded-md"
    if ( props.prefix || props.postfix ) {
        roundingClass = "rounded-none"
        if ( !props.postfix ) {
            roundingClass += " rounded-r-md"
        }
        if ( !props.prefix ) {
            roundingClass += " rounded-l-md"
        }
    }

    return (
        <FormField {...props}>
            <div className="mt-1 flex rounded-md shadow-sm">
                {prefix}
                <input ref={props.inputRef} id={props.id} name={props.name} value={props.value}
                    onChange={ev => props.onChange( ev.target.value, props.id )}
                    type={props.type || "text"} inputMode={props.inputMode}
                    required={props.required ?? true}
                    autoComplete={props.autoComplete} autoCapitalize={props.autoCapitalize}
                    placeholder={props.placeholder ?? ""}
                    aria-invalid={hasErrors}
                    aria-describedby={`${props.id}_errors`}
                    {...( props.additionalAttributes || {} )}
                    className={classNames( [
                        "form-input flex-1 block w-full min-w-0 sm:text-sm sm:leading-5",
                        roundingClass,
                        { "border-red-500": hasErrors },
                    ] )} />
                {postfix}
            </div>
        </FormField>
    )
}
