export interface CountryList {
    [language: string]: {
        [country_code: string]: string
    }
}

export default {
    "en-US": {
        AF: "Afghanistan",
        AX: "Åland Islands",
        AL: "Albania",
        DZ: "Algeria",
        AS: "American Samoa",
        AD: "Andorra",
        AO: "Angola",
        AI: "Anguilla",
        AQ: "Antarctica",
        AG: "Antigua & Barbuda",
        AR: "Argentina",
        AM: "Armenia",
        AW: "Aruba",
        AC: "Ascension Island",
        AU: "Australia",
        AT: "Austria",
        AZ: "Azerbaijan",
        BS: "Bahamas",
        BH: "Bahrain",
        BD: "Bangladesh",
        BB: "Barbados",
        BY: "Belarus",
        BE: "Belgium",
        BZ: "Belize",
        BJ: "Benin",
        BM: "Bermuda",
        BT: "Bhutan",
        BO: "Bolivia",
        BA: "Bosnia & Herzegovina",
        BW: "Botswana",
        BR: "Brazil",
        IO: "British Indian Ocean Territory",
        VG: "British Virgin Islands",
        BN: "Brunei",
        BG: "Bulgaria",
        BF: "Burkina Faso",
        BI: "Burundi",
        KH: "Cambodia",
        CM: "Cameroon",
        CA: "Canada",
        IC: "Canary Islands",
        CV: "Cape Verde",
        BQ: "Caribbean Netherlands",
        KY: "Cayman Islands",
        CF: "Central African Republic",
        EA: "Ceuta & Melilla",
        TD: "Chad",
        CL: "Chile",
        CN: "China",
        CX: "Christmas Island",
        CC: "Cocos (Keeling) Islands",
        CO: "Colombia",
        KM: "Comoros",
        CG: "Congo - Brazzaville",
        CD: "Congo - Kinshasa",
        CK: "Cook Islands",
        CR: "Costa Rica",
        CI: "Côte d'Ivoire",
        HR: "Croatia",
        CU: "Cuba",
        CW: "Curaçao",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DK: "Denmark",
        DG: "Diego Garcia",
        DJ: "Djibouti",
        DM: "Dominica",
        DO: "Dominican Republic",
        EC: "Ecuador",
        EG: "Egypt",
        SV: "El Salvador",
        GQ: "Equatorial Guinea",
        ER: "Eritrea",
        EE: "Estonia",
        ET: "Ethiopia",
        FK: "Falkland Islands",
        FO: "Faroe Islands",
        FJ: "Fiji",
        FI: "Finland",
        FR: "France",
        GF: "French Guiana",
        PF: "French Polynesia",
        TF: "French Southern Territories",
        GA: "Gabon",
        GM: "Gambia",
        GE: "Georgia",
        DE: "Germany",
        GH: "Ghana",
        GI: "Gibraltar",
        GR: "Greece",
        GL: "Greenland",
        GD: "Grenada",
        GP: "Guadeloupe",
        GU: "Guam",
        GT: "Guatemala",
        GG: "Guernsey",
        GN: "Guinea",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HT: "Haiti",
        HN: "Honduras",
        HK: "Hong Kong SAR China",
        HU: "Hungary",
        IS: "Iceland",
        IN: "India",
        ID: "Indonesia",
        IR: "Iran",
        IQ: "Iraq",
        IE: "Ireland",
        IM: "Isle of Man",
        IL: "Israel",
        IT: "Italy",
        JM: "Jamaica",
        JP: "Japan",
        JE: "Jersey",
        JO: "Jordan",
        KZ: "Kazakhstan",
        KE: "Kenya",
        KI: "Kiribati",
        XK: "Kosovo",
        KW: "Kuwait",
        KG: "Kyrgyzstan",
        LA: "Laos",
        LV: "Latvia",
        LB: "Lebanon",
        LS: "Lesotho",
        LR: "Liberia",
        LY: "Libya",
        LI: "Liechtenstein",
        LT: "Lithuania",
        LU: "Luxembourg",
        MO: "Macau SAR China",
        MK: "Macedonia",
        MG: "Madagascar",
        MW: "Malawi",
        MY: "Malaysia",
        MV: "Maldives",
        ML: "Mali",
        MT: "Malta",
        MH: "Marshall Islands",
        MQ: "Martinique",
        MR: "Mauritania",
        MU: "Mauritius",
        YT: "Mayotte",
        MX: "Mexico",
        FM: "Micronesia",
        MD: "Moldova",
        MC: "Monaco",
        MN: "Mongolia",
        ME: "Montenegro",
        MS: "Montserrat",
        MA: "Morocco",
        MZ: "Mozambique",
        MM: "Myanmar (Burma)",
        NA: "Namibia",
        NR: "Nauru",
        NP: "Nepal",
        NL: "Netherlands",
        NC: "New Caledonia",
        NZ: "New Zealand",
        NI: "Nicaragua",
        NE: "Niger",
        NG: "Nigeria",
        NU: "Niue",
        NF: "Norfolk Island",
        KP: "North Korea",
        MP: "Northern Mariana Islands",
        NO: "Norway",
        OM: "Oman",
        PK: "Pakistan",
        PW: "Palau",
        PS: "Palestinian Territories",
        PA: "Panama",
        PG: "Papua New Guinea",
        PY: "Paraguay",
        PE: "Peru",
        PH: "Philippines",
        PN: "Pitcairn Islands",
        PL: "Poland",
        PT: "Portugal",
        PR: "Puerto Rico",
        QA: "Qatar",
        RE: "Réunion",
        RO: "Romania",
        RU: "Russia",
        RW: "Rwanda",
        WS: "Samoa",
        SM: "San Marino",
        ST: "São Tomé and Príncipe",
        SA: "Saudi Arabia",
        SN: "Senegal",
        RS: "Serbia",
        SC: "Seychelles",
        SL: "Sierra Leone",
        SG: "Singapore",
        SX: "Sint Maarten",
        SK: "Slovakia",
        SI: "Slovenia",
        SB: "Solomon Islands",
        SO: "Somalia",
        ZA: "South Africa",
        GS: "South Georgia & South Sandwich Islands",
        KR: "South Korea",
        SS: "South Sudan",
        ES: "Spain",
        LK: "Sri Lanka",
        BL: "St. Barthélemy",
        SH: "St. Helena",
        KN: "St. Kitts & Nevis",
        LC: "St. Lucia",
        MF: "St. Martin",
        PM: "St. Pierre & Miquelon",
        VC: "St. Vincent & Grenadines",
        SD: "Sudan",
        SR: "Suriname",
        SJ: "Svalbard & Jan Mayen",
        SZ: "Swaziland",
        SE: "Sweden",
        CH: "Switzerland",
        SY: "Syria",
        TW: "Taiwan",
        TJ: "Tajikistan",
        TZ: "Tanzania",
        TH: "Thailand",
        TL: "Timor-Leste",
        TG: "Togo",
        TK: "Tokelau",
        TO: "Tonga",
        TT: "Trinidad & Tobago",
        TA: "Tristan da Cunha",
        TN: "Tunisia",
        TR: "Turkey",
        TM: "Turkmenistan",
        TC: "Turks & Caicos Islands",
        TV: "Tuvalu",
        UM: "U.S. Outlying Islands",
        VI: "U.S. Virgin Islands",
        UG: "Uganda",
        UA: "Ukraine",
        AE: "United Arab Emirates",
        GB: "United Kingdom",
        US: "United States",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VU: "Vanuatu",
        VA: "Vatican City",
        VE: "Venezuela",
        VN: "Vietnam",
        WF: "Wallis & Futuna",
        EH: "Western Sahara",
        YE: "Yemen",
        ZM: "Zambia",
        ZW: "Zimbabwe",
    },
    "nl-NL": {
        AF: "Afghanistan",
        AX: "Åland",
        AL: "Albanië",
        DZ: "Algerije",
        AS: "Amerikaans-Samoa",
        VI: "Amerikaanse Maagdeneilanden",
        AD: "Andorra",
        AO: "Angola",
        AI: "Anguilla",
        AQ: "Antarctica",
        AG: "Antigua en Barbuda",
        AR: "Argentinië",
        AM: "Armenië",
        AW: "Aruba",
        AC: "Ascension",
        AU: "Australië",
        AZ: "Azerbeidzjan",
        BS: "Bahama’s",
        BH: "Bahrein",
        BD: "Bangladesh",
        BB: "Barbados",
        BE: "België",
        BZ: "Belize",
        BJ: "Benin",
        BM: "Bermuda",
        BT: "Bhutan",
        BO: "Bolivia",
        BA: "Bosnië en Herzegovina",
        BW: "Botswana",
        BR: "Brazilië",
        IO: "Britse Gebieden in de Indische Oceaan",
        VG: "Britse Maagdeneilanden",
        BN: "Brunei",
        BG: "Bulgarije",
        BF: "Burkina Faso",
        BI: "Burundi",
        KH: "Cambodja",
        CA: "Canada",
        IC: "Canarische Eilanden",
        BQ: "Caribisch Nederland",
        CF: "Centraal-Afrikaanse Republiek",
        EA: "Ceuta en Melilla",
        CL: "Chili",
        CN: "China",
        CX: "Christmaseiland",
        CC: "Cocoseilanden",
        CO: "Colombia",
        KM: "Comoren",
        CG: "Congo-Brazzaville",
        CD: "Congo-Kinshasa",
        CK: "Cookeilanden",
        CR: "Costa Rica",
        CU: "Cuba",
        CW: "Curaçao",
        CY: "Cyprus",
        DK: "Denemarken",
        DG: "Diego Garcia",
        DJ: "Djibouti",
        DM: "Dominica",
        DO: "Dominicaanse Republiek",
        DE: "Duitsland",
        EC: "Ecuador",
        EG: "Egypte",
        SV: "El Salvador",
        GQ: "Equatoriaal-Guinea",
        ER: "Eritrea",
        EE: "Estland",
        ET: "Ethiopië",
        FO: "Faeröer",
        FK: "Falklandeilanden",
        FJ: "Fiji",
        PH: "Filipijnen",
        FI: "Finland",
        FR: "Frankrijk",
        GF: "Frans-Guyana",
        PF: "Frans-Polynesië",
        TF: "Franse Gebieden in de zuidelijke Indische Oceaan",
        GA: "Gabon",
        GM: "Gambia",
        GE: "Georgië",
        GH: "Ghana",
        GI: "Gibraltar",
        GD: "Grenada",
        GR: "Griekenland",
        GL: "Groenland",
        GP: "Guadeloupe",
        GU: "Guam",
        GT: "Guatemala",
        GG: "Guernsey",
        GN: "Guinee",
        GW: "Guinee-Bissau",
        GY: "Guyana",
        HT: "Haïti",
        HN: "Honduras",
        HU: "Hongarije",
        HK: "Hongkong SAR van China",
        IE: "Ierland",
        IS: "IJsland",
        IN: "India",
        ID: "Indonesië",
        IQ: "Irak",
        IR: "Iran",
        IM: "Isle of Man",
        IL: "Israël",
        IT: "Italië",
        CI: "Ivoorkust",
        JM: "Jamaica",
        JP: "Japan",
        YE: "Jemen",
        JE: "Jersey",
        JO: "Jordanië",
        KY: "Kaaimaneilanden",
        CV: "Kaapverdië",
        CM: "Kameroen",
        KZ: "Kazachstan",
        KE: "Kenia",
        KG: "Kirgizië",
        KI: "Kiribati",
        UM: "Kleine afgelegen eilanden van de Verenigde Staten",
        KW: "Koeweit",
        XK: "Kosovo",
        HR: "Kroatië",
        LA: "Laos",
        LS: "Lesotho",
        LV: "Letland",
        LB: "Libanon",
        LR: "Liberia",
        LY: "Libië",
        LI: "Liechtenstein",
        LT: "Litouwen",
        LU: "Luxemburg",
        MO: "Macau SAR van China",
        MK: "Macedonië",
        MG: "Madagaskar",
        MW: "Malawi",
        MV: "Maldiven",
        MY: "Maleisië",
        ML: "Mali",
        MT: "Malta",
        MA: "Marokko",
        MH: "Marshalleilanden",
        MQ: "Martinique",
        MR: "Mauritanië",
        MU: "Mauritius",
        YT: "Mayotte",
        MX: "Mexico",
        FM: "Micronesia",
        MD: "Moldavië",
        MC: "Monaco",
        MN: "Mongolië",
        ME: "Montenegro",
        MS: "Montserrat",
        MZ: "Mozambique",
        MM: "Myanmar (Birma)",
        NA: "Namibië",
        NR: "Nauru",
        NL: "Nederland",
        NP: "Nepal",
        NI: "Nicaragua",
        NC: "Nieuw-Caledonië",
        NZ: "Nieuw-Zeeland",
        NE: "Niger",
        NG: "Nigeria",
        NU: "Niue",
        KP: "Noord-Korea",
        MP: "Noordelijke Marianen",
        NO: "Noorwegen",
        NF: "Norfolk",
        UG: "Oeganda",
        UA: "Oekraïne",
        UZ: "Oezbekistan",
        OM: "Oman",
        TL: "Oost-Timor",
        AT: "Oostenrijk",
        PK: "Pakistan",
        PW: "Palau",
        PS: "Palestijnse gebieden",
        PA: "Panama",
        PG: "Papoea-Nieuw-Guinea",
        PY: "Paraguay",
        PE: "Peru",
        PN: "Pitcairneilanden",
        PL: "Polen",
        PT: "Portugal",
        PR: "Puerto Rico",
        QA: "Qatar",
        RE: "Réunion",
        RO: "Roemenië",
        RU: "Rusland",
        RW: "Rwanda",
        KN: "Saint Kitts en Nevis",
        LC: "Saint Lucia",
        VC: "Saint Vincent en de Grenadines",
        BL: "Saint-Barthélemy",
        MF: "Saint-Martin",
        PM: "Saint-Pierre en Miquelon",
        SB: "Salomonseilanden",
        WS: "Samoa",
        SM: "San Marino",
        ST: "Sao Tomé en Principe",
        SA: "Saoedi-Arabië",
        SN: "Senegal",
        RS: "Servië",
        SC: "Seychellen",
        SL: "Sierra Leone",
        SG: "Singapore",
        SH: "Sint-Helena",
        SX: "Sint-Maarten",
        SI: "Slovenië",
        SK: "Slowakije",
        SD: "Soedan",
        SO: "Somalië",
        ES: "Spanje",
        SJ: "Spitsbergen en Jan Mayen",
        LK: "Sri Lanka",
        SR: "Suriname",
        SZ: "Swaziland",
        SY: "Syrië",
        TJ: "Tadzjikistan",
        TW: "Taiwan",
        TZ: "Tanzania",
        TH: "Thailand",
        TG: "Togo",
        TK: "Tokelau",
        TO: "Tonga",
        TT: "Trinidad en Tobago",
        TA: "Tristan da Cunha",
        TD: "Tsjaad",
        CZ: "Tsjechië",
        TN: "Tunesië",
        TR: "Turkije",
        TM: "Turkmenistan",
        TC: "Turks- en Caicoseilanden",
        TV: "Tuvalu",
        UY: "Uruguay",
        VU: "Vanuatu",
        VA: "Vaticaanstad",
        VE: "Venezuela",
        GB: "Verenigd Koninkrijk",
        AE: "Verenigde Arabische Emiraten",
        US: "Verenigde Staten",
        VN: "Vietnam",
        WF: "Wallis en Futuna",
        EH: "Westelijke Sahara",
        BY: "Wit-Rusland",
        ZM: "Zambia",
        ZW: "Zimbabwe",
        ZA: "Zuid-Afrika",
        GS: "Zuid-Georgia en Zuidelijke Sandwicheilanden",
        KR: "Zuid-Korea",
        SS: "Zuid-Soedan",
        SE: "Zweden",
        CH: "Zwitserland",
    },
} as CountryList
