import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.password-reset.forgot-password": {
        "en-US": "Forgot password",
        "nl-NL": "Wachtwoord vergeten",
    },
    "pages.password-reset.email": {
        "en-US": "Email address",
        "nl-NL": "E-mailadres",
    },
    "pages.password-reset.new-password": {
        "en-US": "New password",
        "nl-NL": "Nieuw wachtwoord",
    },
    "pages.password-reset.confirm-password": {
        "en-US": "Confirm password",
        "nl-NL": "Bevestig wachtwoord",
    },
    "pages.password-reset.copy-password": {
        "en-US": "Copy password",
        "nl-NL": "Kopieer wachtwoord",
    },
    "pages.password-reset.reset": {
        "en-US": "Reset password",
        "nl-NL": "Reset wachtwoord",
    },
    "pages.password-reset.send-link": {
        "en-US": "Send password reset link",
        "nl-NL": "Verstuur wachtwoord reset link",
    },
    "pages.password-reset.success-link": {
        "en-US": "A password reset link has been sent to your e-mail address",
        "nl-NL": "Een wachtwoord reset link is verzonden naar uw e-mailadres",
    },
    "pages.password-reset.success-reset": {
        "en-US": "Your password has been reset",
        "nl-NL": "Je wachtwoord is opnieuw ingesteld",
    },
    "pages.password-reset.password-requirements": {
        "en-US": "At least 8 characters, upper and lowercase, 1 special character (!@#$%^&*_-)",
        "nl-NL": "Ten minste 8 tekens, hoofd- en kleine letters, 1 leesteken (!@#$%^&*_-)",
    },
    "pages.password-reset.password-token-invalid": {
        "en-US": "This link is no longer valid.",
        "nl-NL": "De link is niet meer geldig.",
    },
    "pages.password-reset.go-back": {
        "en-US": "Back to login page",
        "nl-NL": "Terug naar de login pagina",
    },


} as TranslationDictionary
