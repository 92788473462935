import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.register.register": {
        "en-US": "Register",
        "nl-NL": "Registreren",
    },
    "pages.register.form.email": {
        "en-US": "Email address",
        "nl-NL": "E-mailadres",
    },
    "pages.register.form.email_is_login": {
        "en-US": "Heads up: the email address will be your username",
        "nl-NL": "Let op: het e-mailadres wordt je gebruikersnaam",
    },
    "pages.register.form.first_name": {
        "en-US": "First name",
        "nl-NL": "Voornaam",
    },
    "pages.register.form.last_name": {
        "en-US": "Last name",
        "nl-NL": "Achternaam",
    },
    "pages.register.form.phone_number": {
        "en-US": "Phone number",
        "nl-NL": "Telefoonnummer",
    },
    "pages.register.form.organization": {
        "en-US": "Organization",
        "nl-NL": "Bedrijfsnaam",
    },
    "pages.register.form.street_name": {
        "en-US": "Street",
        "nl-NL": "Straat",
    },
    "pages.register.form.street_number": {
        "en-US": "Number + Apartment/Suite",
        "nl-NL": "Huisnummer + toevoeging",
    },
    "pages.register.form.zip_code": {
        "en-US": "Zip code",
        "nl-NL": "Postcode",
    },
    "pages.register.form.city": {
        "en-US": "City",
        "nl-NL": "Plaats",
    },
    "pages.register.form.country": {
        "en-US": "Country",
        "nl-NL": "Land",
    },
    "pages.register.form.i_agree_with_the": {
        "en-US": "Yes, I agree with the",
        "nl-NL": "Ja, ik ga akkoord met de",
    },
    "pages.register.form.terms_of_service": {
        "en-US": "terms of service",
        "nl-NL": "algemene voorwaarden & aanvullende voorwaarden",
    },

} as TranslationDictionary
