import getConfig from "next/config"
import { Dispatch, SetStateAction, useEffect, useState } from "react"

const versionNumber = getConfig().publicRuntimeConfig.versionNumber as string
const KEY_PREFIX = `__sandwave__${versionNumber}__`

function getValue<T> ( key: string ): T | undefined
function getValue<T> ( key: string, fallback: T ): T
function getValue<T> ( key: string, fallback?: T ): T | undefined {

    if ( typeof window === "undefined" ) {
        return fallback
    }

    if ( typeof window?.localStorage === "undefined" ) {
        return fallback
    }

    const savedState = window?.localStorage.getItem( KEY_PREFIX + key )
    if ( !savedState ) {
        return fallback
    }

    try {
        const { value } = JSON.parse( savedState ?? "{}" ) as { value: T }
        return value
    } catch ( e ) {
        return fallback
    }
}

function setValue<T> ( key: string, value: T|undefined ): void {

    if ( window && typeof window?.localStorage !== "undefined" ) {
        window?.localStorage.setItem( KEY_PREFIX + key, JSON.stringify( { value } ) )
    }
}

/**
 * Returns a stateful value, and a function to update it. Persists on page refreshes.
 */
export default function usePersistentState<T> ( key: string ): [T | undefined, Dispatch<SetStateAction<T | undefined>>]
export default function usePersistentState<T> ( key: string, initialState: T ): [T, Dispatch<SetStateAction<T>>]
export default function usePersistentState<T> ( key: string, initialState?: T ): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
    const init = getValue( key, initialState )
    const [state, setState] = useState<T | undefined>( init )

    useEffect( () => {
        setValue( key, state )
    }, [key, state] )

    return [ state, setState ]
}
