import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.domain-contact.new.page-header": {
        "en-US": "Domain Contacts",
        "nl-NL": "Domeinhouders",
    },
    "pages.domain-contact.new.create-successful": {
        "en-US": "Domain contact: {value} successfully created.",
        "nl-NL": "Domeinhouder: {value} succesvol aangemaakt.",
    },
    "pages.domain-contact.new.create-domain-contact": {
        "en-US": "Create domain contact",
        "nl-NL": "Domeinhouder aanmaken",
    },
    "pages.domain-contact.new.first_name": {
        "en-US": "First name",
        "nl-NL": "Voornaam",
    },
    "pages.domain-contact.new.last_name": {
        "en-US": "Last name",
        "nl-NL": "Achternaam",
    },
    "pages.domain-contact.new.email": {
        "en-US": "Email address",
        "nl-NL": "E-mailadres",
    },
    "pages.domain-contact.new.phone_number": {
        "en-US": "Phone number",
        "nl-NL": "Telefoonnummer",
    },
    "pages.domain-contact.new.organization": {
        "en-US": "Organization",
        "nl-NL": "Bedrijfsnaam",
    },
    "pages.domain-contact.new.street_name": {
        "en-US": "Street",
        "nl-NL": "Straat",
    },
    "pages.domain-contact.new.street_number": {
        "en-US": "Number",
        "nl-NL": "Huisnummer",
    },
    "pages.domain-contact.new.zip_code": {
        "en-US": "Zip code",
        "nl-NL": "Postcode",
    },
    "pages.domain-contact.new.city": {
        "en-US": "City",
        "nl-NL": "Plaats",
    },
    "pages.domain-contact.new.country": {
        "en-US": "Country",
        "nl-NL": "Land",
    },

} as TranslationDictionary
