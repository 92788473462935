import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.domain-contact.link-domains.page-header": {
        "en-US": "Link domainnames",
        "nl-NL": "Koppel domeinnamen",
    },
    "pages.domain-contact.link-domains.no-subscriptions": {
        "en-US": "You have no available domain subscriptions.",
        "nl-NL": "U heeft geen beschikbare domein abonnementen.",
    },
    "pages.domain-contact.link-domains.form-header": {
        "en-US": "Select all domains that should be linked",
        "nl-NL": "Selecteer domeinnamen om te koppelen",
    },
    "pages.domain-contact.link-domains.description": {
        "en-US": "All linked domains will be updated when the domain contact is updated.",
        "nl-NL": "Alle gekoppelde domeinen worden geüpdate wanneer er wijzigingen worden aangebracht in de domeinhouder.",
    },
    "pages.domain-contact.link-domains.modal.description": {
        "en-US": "In the process of linking the selected domainnames, the whois details will be updated.",
        "nl-NL": "Tijdens het koppelen van de domeinnamen worden de whois gegevens van de domein(en) aangepast.",
    },

} as TranslationDictionary
