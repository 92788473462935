import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { ProductData } from "modules/api-client/src/product/types"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

export interface ProductApiSpec {
    productDataRequest( options?: RequestOptions ): Promise<Response<ProductData>>
}

export function useProductsApi (): ProductApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retrieve Product info from the API.
     */
    const productDataRequest = useCallback( ( options?: RequestOptions ) => {
        return getRequest<ProductData>( "product", {}, options )
    }, [getRequest] )

    return {
        productDataRequest,
    }
}
