import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.profile.user_data": {
        "en-US": "User information",
        "nl-NL": "Gebruikersgegevens",
    },
    "pages.profile.user_data_description": {
        "en-US": "General and contact information",
        "nl-NL": "Algemene en contactgegevens voor gebruiker",
    },
    "pages.profile.field_email": {
        "en-US": "Email address",
        "nl-NL": "E-mailadres",
    },
    "pages.profile.field_name": {
        "en-US": "Full name",
        "nl-NL": "Voor- en achternaam",
    },

    "pages.profile.customer_data": {
        "en-US": "Customer information",
        "nl-NL": "Klantgegevens",
    },
    "pages.profile.customer_data_description": {
        "en-US": "Contact information",
        "nl-NL": "Contact gegevens",
    },
    "pages.profile.customer_payment": {
        "en-US": "Billing information",
        "nl-NL": "Betaalgegevens",
    },
    "pages.profile.customer_payment_description": {
        "en-US": "Billing information and settings",
        "nl-NL": "Factuur gegevens en instellingen",
    },
    "pages.profile.field_customer_id": {
        "en-US": "Customer ID",
        "nl-NL": "Klantnummer",
    },
    "pages.profile.field_organisation": {
        "en-US": "Organisation",
        "nl-NL": "Organisatie",
    },
    "pages.profile.field_department": {
        "en-US": "Department",
        "nl-NL": "Afdeling",
    },
    "pages.profile.field_phone": {
        "en-US": "Phone",
        "nl-NL": "Telefoon",
    },
    "pages.profile.field_payment_term": {
        "en-US": "Payment term",
        "nl-NL": "Betaaltermijn",
    },
    "pages.profile.field_credit_limit": {
        "en-US": "Credit limit",
        "nl-NL": "Kredietlimiet",
    },
    "pages.profile.field_address": {
        "en-US": "Address",
        "nl-NL": "Adres",
    },
    "pages.profile.field_postal_and_city": {
        "en-US": "Postal code + city",
        "nl-NL": "Postcode + plaats",
    },
    "pages.profile.field_province_and_country": {
        "en-US": "State + country",
        "nl-NL": "Provincie + land",
    },

    "pages.profile.domain_data": {
        "en-US": "Domain information",
        "nl-NL": "Domeingegevens",
    },
    "pages.profile.domain_data_description": {
        "en-US": "Data used for registering domains",
        "nl-NL": "Deze gegevens worden gebruikt voor het registreren van domeinen",
    },

    "pages.profile.edit-profile": {
        "en-US": "Change my profile",
        "nl-NL": "Bewerk mijn klantgegevens",
    },
    "pages.profile.form.user_email": {
        "en-US": "User email",
        "nl-NL": "Gebruiker email",
    },
    "pages.profile.form.customer_email": {
        "en-US": "Customer email",
        "nl-NL": "Klant email",
    },
    "pages.profile.vouchers_title": {
        "en-US": "Vouchers",
        "nl-NL": "Vouchers",
    },

    "pages.profile.vouchers_field_display_name": {
        "en-US": "Name",
        "nl-NL": "Naam",
    },

    "pages.profile.vouchers_field_expiration_date": {
        "en-US": "Expiration date",
        "nl-NL": "Verloopdatum",
    },

    "pages.profile.vouchers_field_amount": {
        "en-US": "Total value",
        "nl-NL": "Totale waarde",
    },

    "pages.profile.vouchers_field_unclaimed_amount": {
        "en-US": "Remaining value",
        "nl-NL": "Resterende waarde",
    },

    "pages.profile.no_vouchers": {
        "en-US": "You don't have any vouchers",
        "nl-NL": "Je hebt geen vouchers",
    },

} as TranslationDictionary
