import { ModifyCustomerParams } from "modules/api-client/src/hosting/requests/modify-customer-params"
import { PasswordResetParams } from "modules/api-client/src/hosting/requests/password-reset-params"
import { RetrieveHostingCustomerConfigParams } from "modules/api-client/src/hosting/requests/retrieve-customer-config-params"
import { RetrieveHostingStatisticsParams } from "modules/api-client/src/hosting/requests/retrieve-statistics-params"
import { SsoParams } from "modules/api-client/src/hosting/requests/sso-params"
import { CustomerConfigResponse } from "modules/api-client/src/hosting/responses/customer-config-response"
import { HostingPasswordResetResponse } from "modules/api-client/src/hosting/responses/password-reset-response"
import { SsoResponse } from "modules/api-client/src/hosting/responses/sso-response"
import { HostingStatisticsResponse } from "modules/api-client/src/hosting/responses/statistics-response"
import { Response, useHttpClient } from "modules/api-client/src/http-client"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface HostingApiSpec {
    customerConfigRequest( parameters: RetrieveHostingCustomerConfigParams ): Promise<Response<CustomerConfigResponse>>
    hostingStatisticsRequest( parameters: RetrieveHostingStatisticsParams ): Promise<Response<HostingStatisticsResponse>>
    modifyCustomerRequest( parameters: ModifyCustomerParams ): Promise<Response<[]>>
    ssoUrlRequest( parameters: SsoParams ): Promise<Response<SsoResponse>>
    sitebuilderSsoRequest( params: SsoParams ): Promise<Response<SsoResponse>>
    passwordResetRequest( parameters: PasswordResetParams ): Promise<Response<HostingPasswordResetResponse>>
}

export function useHostingApi (): HostingApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, putRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retrieve the customer config data from the API.
     */
    const customerConfigRequest = useCallback( ( parameters: RetrieveHostingCustomerConfigParams ): Promise<Response<CustomerConfigResponse>> => {
        return getRequest<CustomerConfigResponse>( `domain/${parameters.domain}/hosting/customer-config` )
    }, [getRequest] )

    /**
     * Request to retrieve the hosting statistics from the API.
     */
    const hostingStatisticsRequest = useCallback( ( parameters: RetrieveHostingStatisticsParams ): Promise<Response<HostingStatisticsResponse>> => {
        return getRequest<HostingStatisticsResponse>( `domain/${parameters.domain}/hosting/stats` )
    }, [getRequest] )

    const modifyCustomerRequest = useCallback( ( parameters: ModifyCustomerParams ): Promise<Response<[]>> => {
        return putRequest<[]>( `domain/${parameters.domain}/hosting/modify-customer`, parameters )
    }, [putRequest] )

    const sitebuilderSsoRequest = useCallback( ( params: SsoParams ): Promise<Response<SsoResponse>> => {
        return getRequest<SsoResponse>( `domain/${params.domain}/sitebuilder/sso` )
    }, [getRequest] )

    const ssoUrlRequest = useCallback( ( parameters: SsoParams ): Promise<Response<SsoResponse>> => {
        return getRequest<SsoResponse>( `domain/${parameters.domain}/hosting/sso` )
    }, [getRequest] )

    const passwordResetRequest = useCallback( ( parameters: PasswordResetParams ): Promise<Response<HostingPasswordResetResponse>> => {
        return getRequest<HostingPasswordResetResponse>( `domain/${parameters.domain}/hosting/reset` )
    }, [getRequest] )

    return {
        customerConfigRequest,
        hostingStatisticsRequest,
        modifyCustomerRequest,
        sitebuilderSsoRequest,
        ssoUrlRequest,
        passwordResetRequest,
    }
}
