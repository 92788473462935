import { SsoParams } from "modules/api-client/src/hosting/requests/sso-params"
import { SsoResponse } from "modules/api-client/src/hosting/responses/sso-response"
import { Response, useHttpClient } from "modules/api-client/src/http-client"
import { MailConfigResponse, MailConfiguration } from "modules/api-client/src/mail-hosting/responses/mail-config-response"
import { MailUser, MailUsersResponse } from "modules/api-client/src/mail-hosting/responses/mail-users-response"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface MailHostingApiSpec {
    mailConfigurationRequest( domain: string ): Promise<Response<MailConfiguration>>
    mailUsersRequest( domain: string ): Promise<Response<MailUser[]>>
    mailSsoRequest( params: SsoParams ): Promise<Response<SsoResponse>>
    createMailUserRequest( domain: string, username: string, password: string ): Promise<Response<[]>>
    resetMailUserRequest( domain: string, username: string, password: string ): Promise<Response<[]>>
    deleteMailUserRequest( domain: string, username: string ): Promise<Response<[]>>
}

export function useMailHostingApi (): MailHostingApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, postRequest, deleteRequest } = useHttpClient( baseUrl, customer )

    const mailConfigurationRequest = useCallback( ( domain: string ): Promise<Response<MailConfiguration>> => {
        return getRequest<MailConfigResponse>( `domain/${domain}/mail` )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const mailUsersRequest = useCallback( ( domain: string ): Promise<Response<MailUser[]>> => {
        return getRequest<MailUsersResponse>( `domain/${domain}/mail/users` )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const createMailUserRequest = useCallback( ( domain: string, username: string, password: string ): Promise<Response<[]>> => {
        return postRequest<[]>( `domain/${domain}/mail/users`, {
            username,
            password,
        } )
    }, [postRequest] )

    const mailSsoRequest = useCallback( ( params: SsoParams ): Promise<Response<SsoResponse>> => {
        return getRequest<SsoResponse>( `domain/${params.domain}/mail/spamexperts` )
    }, [getRequest] )

    const resetMailUserRequest = useCallback( ( domain: string, username: string, password: string ): Promise<Response<[]>> => {
        return postRequest<[]>( `domain/${domain}/mail/users/${username}/reset`, {
            password,
            password_confirmation: password,
        } )
    }, [postRequest] )

    const deleteMailUserRequest = useCallback( ( domain: string, username: string ): Promise<Response<[]>> => {
        return deleteRequest<[]>( `domain/${domain}/mail/users/${username}` )
    }, [deleteRequest] )

    return {
        mailConfigurationRequest,
        mailUsersRequest,
        mailSsoRequest,
        createMailUserRequest,
        resetMailUserRequest,
        deleteMailUserRequest,
    }
}
