import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.domain.domain.dns-premium.enabled-successfully": {
        "en-US": "Premium DNS has been enabled for your domain {domain}.",
        "nl-NL": "Premium DNS is geactiveerd voor je domein {domain}.",
    },
    "pages.domain.domain.dns-premium.form.confirmation": {
        "en-US": "I accept the above-mentioned costs for Premium DNS",
        "nl-NL": "Ik accepteer bovengenoemde kosten voor Premium DNS",
    },
    "pages.domain.domain.dns-premium.form.error.fill_all_fields": {
        "en-US": "All fields have to be filled in correctly.",
        "nl-NL": "Alle velden dienen correct ingevuld te zijn.",
    },
    "pages.domain.domain.dns-premium.form.error.required": {
        "en-US": "This field is required.",
        "nl-NL": "Dit veld is verplicht.",
    },
    "pages.domain.domain.dns-premium.form.submit_button": {
        "en-US": "Activate",
        "nl-NL": "Activeren",
    },
    "pages.domain.domain.dns-premium.intro": {
        "en-US": "Currently, Premium DNS is disabled. Do you want to enable this service?",
        "nl-NL": "Jouw domein maakt momenteel geen gebruik van Premium DNS, wil je dit aanzetten?",
    },
    "pages.domain.domain.dns-premium.page_heading": {
        "en-US": "Premium DNS",
        "nl-NL": "Premium DNS",
    },
    "pages.domain.domain.dns-premium.server-error": {
        "en-US": "An error occurred while enabling Premium DNS. Please try again later.",
        "nl-NL": "Er is een fout opgetreden bij het activeren van Premium DNS. Probeer het later nog eens.",
    },
    "pages.domain.domain.dns-premium.one_year": {
        "en-US": "1 year",
        "nl-NL": "1 jaar",
    },
    "pages.domain.domain.dns-premium.period": {
        "en-US": "Period",
        "nl-NL": "Periode",
    },
    "pages.domain.domain.dns-premium.price": {
        "en-US": "Price",
        "nl-NL": "Prijs",
    },

} as TranslationDictionary
