import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.hosting.status": {
        "en-US": "Status",
        "nl-NL": "Status",
    },
    "pages.hosting.renewal_date": {
        "en-US": "Renewal date",
        "nl-NL": "Verleng datum",
    },
    "pages.hosting.wordpress": {
        "en-US": "WordPress",
        "nl-NL": "WordPress",
    },
    "pages.hosting.period": {
        "en-US": "Duration",
        "nl-NL": "Looptijd",
    },
    "pages.hosting.package": {
        "en-US": "Package",
        "nl-NL": "Pakket",
    },
    "pages.hosting.error-no-package": {
        "en-US": "This hosting package has not been deployed yet",
        "nl-NL": "Dit hosting pakket is nog niet opgeleverd",
    },
    "pages.hosting.server": {
        "en-US": "Server",
        "nl-NL": "Server",
    },
    "pages.hosting.ssh-access": {
        "en-US": "SSH Access",
        "nl-NL": "SSH Toegang",
    },
    "pages.hosting.ssl-security": {
        "en-US": "SSL Security",
        "nl-NL": "SSL Beveiliging",
    },
    "pages.hosting.dns-management": {
        "en-US": "DNS Management",
        "nl-NL": "DNS Beheer",
    },
    "pages.hosting.statistics": {
        "en-US": "Statistics",
        "nl-NL": "Statistieken",
    },
    "pages.hosting.bandwidth": {
        "en-US": "Bandwidth",
        "nl-NL": "Bandbreedte",
    },
    "pages.hosting.disk-usage": {
        "en-US": "Disk usage",
        "nl-NL": "Schijfgebruik",
    },
    "pages.hosting.n-domains": {
        "en-US": "Number of domains",
        "nl-NL": "Aantal domeinen",
    },
    "pages.hosting.information": {
        "en-US": "Information",
        "nl-NL": "Informatie",
    },
    "pages.hosting.configuration": {
        "en-US": "Configuration",
        "nl-NL": "Configuratie",
    },
    "pages.hosting.username": {
        "en-US": "Username",
        "nl-NL": "Gebruikersnaam",
    },
    "pages.hosting.order": {
        "en-US": "Order a hosting package for ",
        "nl-NL": "Bestel een hosting pakket voor ",
    },
    "pages.hosting.password": {
        "en-US": "Password",
        "nl-NL": "Wachtwoord",
    },
    "pages.hosting-mail.password_placeholder": {
        "en-US": "*********",
        "nl-NL": "*********",
    },
    "pages.hosting.index.period-suffix": {
        "en-US": "months",
        "nl-NL": "maanden",
    },
    "pages.hosting.reset_password": {
        "en-US": "Reset password",
        "nl-NL": "Reset wachtwoord",
    },
    "pages.hosting.reset_cancel": {
        "en-US": "Back to hosting & email",
        "nl-NL": "Terug naar hosting & e-mail",
    },
    "pages.hosting.reset_password_description": {
        "en-US": "Change your SSH and FTPS password",
        "nl-NL": "Wijzig uw SSH and FTPS wachtwoord",
    },
    "pages.hosting.reset_password_warning": {
        "en-US": "Warning: Save your password after resetting. You cannot retrieve it after leaving this page",
        "nl-NL": "Let op: Sla het wachtwoord op na het resetten. Deze kun je hierna niet meer achterhalen",
    },
    "pages.hosting.reset_password_warning_long": {
        "en-US": "By click \"Reset password\" your SSH and FTPS password will be modified. We will show the new password once. We recommend you write it down, because after that, it cannot be recovered",
        "nl-NL": "Door op \"Reset wachtwoord\" te klikken wordt jouw SSH en FTPS wachtwoord aangepast. We tonen het nieuwe wachtwoord daarna een keer. Neem het dus direct over, want daarna kan het wachtwoord niet meer achterhaald worden",
    },
    "pages.hosting.reset_password_hidden": {
        "en-US": "Password hidden",
        "nl-NL": "Wachtwoord verborgen",
    },
    "pages.hosting.reset_password_copied": {
        "en-US": "Copied to clipboard",
        "nl-NL": "Gekopiëerd naar klembord",
    },
    "pages.hosting.ftps_host": {
        "en-US": "FTPS host",
        "nl-NL": "FTPS host",
    },
    "pages.hosting.upgrade_text": {
        "en-US": "Take your website to the next level!",
        "nl-NL": "Haal meer uit je website!",
    },
    "pages.hosting.upgrade_action": {
        "en-US": "Upgrade",
        "nl-NL": "Upgrade",
    },

    "pages.hosting.sso-integratedservice": {
        "en-US": "Open Plesk",
        "nl-NL": "Naar Plesk",
    },
    "pages.hosting.sso-directadmin": {
        "en-US": "Open DirectAdmin",
        "nl-NL": "Naar DirectAdmin",
    },
    "pages.hosting.manage-hosting-integratedservice": {
        "en-US": "Manage your hosting package using Plesk",
        "nl-NL": "Beheer je hosting pakket via Plesk",
    },
    "pages.hosting.manage-hosting-directadmin": {
        "en-US": "Manage your hosting package using DirectAdmin",
        "nl-NL": "Beheer je hosting pakket via DirectAdmin",
    },
    "pages.hosting.sso-sitebuilder": {
        "en-US": "Open sitebuilder",
        "nl-NL": "Naar sitebuilder",
    },
    "pages.hosting.manage-hosting-sitebuilder": {
        "en-US": "Manage your website using the sitebuilder",
        "nl-NL": "Beheer je website via de sitebuilder",
    },
} as TranslationDictionary
