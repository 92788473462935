import { Transition } from "@headlessui/react"
import { CheckCircle, Times, TimesOctagon } from "icons/font-awesome/light"
import React, { ReactNode, useCallback, useEffect, useState } from "react"

export type NotificationType = "success" | "error"

export interface NotificationProps {
    /**
     * Type of notification to show
     */
    type: NotificationType

    /**
     * Function to call after the notification is closed (leave animation finished)
     */
    onClose?: () => void

    /**
     * Automatically close the notification after the given number of seconds.
     * If no number is set, the notification will stay open.
     */
    autocloseAfter?: number

    /**
     * Contents of the notification
     */
    children: ReactNode
}

/**
 * A notification item (a.k.a. toast)
 */
export default function Notification ( props: NotificationProps ): JSX.Element {
    const [open, setOpen] = useState( false )
    const close = useCallback( (): void => {
        setOpen( false )
        if ( props.onClose ) {
            setTimeout( () => props.onClose!(), 100 )
        }
    }, [setOpen, props.onClose] )

    useEffect( () => {
        /* To ensure the enter animation plays, only start showing the item
         * after the initial render. */
        setTimeout( () => setOpen( true ) )

        /* Set a timer for autoclosing if desirable, clear it on unmounting. */
        if ( props.autocloseAfter && props.autocloseAfter > 0 ) {

            const closingTimer = setTimeout( () => {
                close()
            }, props.autocloseAfter * 1000 )

            return () => clearTimeout( closingTimer )
        }
    } )

    let icon: JSX.Element
    switch ( props.type ) {
        case "success":
            icon = <CheckCircle className="h-6 w-6 text-green-500" />
            break
        case "error":
            icon = <TimesOctagon className="h-6 w-6 text-red-500" />
            break
    }

    return (
        <Transition
            show={open}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            {
                ( ref ) =>
                    <div ref={ref} className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto" role="alert">
                        <div className="rounded-lg shadow-xs overflow-hidden">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {icon}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        {props.children}
                                    </div>
                                    <div className="ml-4 flex-shrink-0 flex">
                                        <button type="button" onClick={close} className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close alert">
                                            <Times className="h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </Transition>
    )
}
