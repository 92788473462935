import { usePersistentState } from "modules/helpers"
import React, { createContext, useCallback, useEffect, useState } from "react"
import { NotificationProps } from "ui-kit/overlays/notification"

export interface HistoricNotificationProps extends NotificationProps {
    time: Date
}

export interface NotificationContextType {
    notification?: NotificationProps
    history: HistoricNotificationProps[]
    pushNotification( props: NotificationProps ): void
    closeNotification(): void
    clearQueue(): void
    clearHistory(): void
}

export const NotificationContext = createContext<NotificationContextType>( {} as NotificationContextType )

interface NotificationContextProps {
    children: React.ReactNode
}

export function NotificationContextProvider ( { children }: NotificationContextProps ): JSX.Element {

    const [queue, setQueue] = useState<NotificationProps[]>( [] )
    const [history, setHistory] = usePersistentState<HistoricNotificationProps[]>( "notification.history", [] )
    const [notification, setNotification] = useState<NotificationProps>()

    const pushNotification = useCallback( ( props: NotificationProps ): void => {
        setQueue( [...queue, props] )
    }, [queue] )

    const closeNotification = useCallback( (): void => {
        if ( notification ) {
            setHistory( [...history, {
                time: new Date(),
                ...notification,
            }] )
        }
        setNotification( undefined )
    }, [notification, history, setHistory] )

    const clearQueue = useCallback( (): void => {
        setQueue( [] )
    }, [] )

    const clearHistory = useCallback( (): void => {
        setHistory( [] )
    }, [setHistory] )

    useEffect( () => {
        if ( ! notification && queue.length > 0 ) {
            setNotification( queue.shift() )
            setQueue( queue )
        }
    }, [notification, setNotification, queue, setQueue] )

    return (
        <NotificationContext.Provider
            value={{
                notification,
                history,
                pushNotification,
                closeNotification,
                clearQueue,
                clearHistory,
            }}>
            {children}
        </NotificationContext.Provider>
    )
}
