import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.404.header": {
        "en-US": "This page does not exist",
        "nl-NL": "Deze pagina bestaat niet",
    },
    "pages.404.go_back": {
        "en-US": "Go back",
        "nl-NL": "Ga terug",
    },

} as TranslationDictionary
