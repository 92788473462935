import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { RetrieveWhoisParams } from "modules/api-client/src/whois/requests/retrieve-whois-params"
import { UpdateWhoisRequest } from "modules/api-client/src/whois/requests/update-whois-request"
import { UpdateWhoisResponse } from "modules/api-client/src/whois/responses/update-whois-response"
import { Whois } from "modules/api-client/src/whois/types"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface WhoisApiSpec {
    whoisRequest( parameters: RetrieveWhoisParams, options?: RequestOptions ): Promise<Response<Whois>>
    updateWhoisRequest( domain: string, whois: UpdateWhoisRequest, options?: RequestOptions ): Promise<Response<UpdateWhoisResponse>>
    enablePrivateWhois( domain: string, options?: RequestOptions ): Promise<Response<boolean>>
    disablePrivateWhois( domain: string, options?: RequestOptions ): Promise<Response<boolean>>
}

export function useWhoisApi (): WhoisApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, postRequest, putRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retreive whois info from the API.
     */
    const whoisRequest = useCallback( ( parameters: RetrieveWhoisParams, options?: RequestOptions ): Promise<Response<Whois>> => {
        return getRequest<{ data: Whois }>( `domain-name/${parameters.domain}/whois`, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    /**
     * Enable Whois privacy protection for the given domain. Returns true when
     * the action was processed successfully.
     */
    const enablePrivateWhois = useCallback( ( domain: string, options?: RequestOptions ) => {
        return postRequest<{ data: boolean }>( `domain-name/${domain}/whois/enable-private-whois`, undefined, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [postRequest] )

    /**
     * Disable Whois privacy protection for the given domain. Returns true when
     * the action was processed successfully.
     */
    const disablePrivateWhois = useCallback( ( domain: string, options?: RequestOptions ) => {
        return postRequest<{ data: boolean }>( `domain-name/${domain}/whois/disable-private-whois`, undefined, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [postRequest] )

    /**
     * Request to update the whois info.
     */
    const updateWhoisRequest = useCallback( ( domain: string, whois: UpdateWhoisRequest, options?: RequestOptions ): Promise<Response<UpdateWhoisResponse>> => {
        return putRequest<UpdateWhoisResponse>( `domain-name/${domain}/whois`, whois, {}, options )
    }, [putRequest] )

    return {
        whoisRequest,
        updateWhoisRequest,
        enablePrivateWhois,
        disablePrivateWhois,
    }
}
