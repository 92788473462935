/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-return */
import { Response } from "modules/api-client"
import useFetchedResource, { FetchedResource } from "modules/helpers/src/use-fetched-resource"

/**
 * This helper extends {@see useFetchedResource()} by expecting the resource to
 * load to be an API {@see Response} instance: it unwraps the data from the
 * response if the request was successful, and throws an error it it was not.
 */
export default function useFetchedApiResource<T extends Response<any>> (
    fetchCallback: () => Promise<T> ): FetchedResource<T extends Response<infer U> ? U : never> {

    return useFetchedResource( async () => {
        const response = await fetchCallback()
        if ( !response.success ) {
            throw new Error( response.error!.message )
        }
        return response.data!
    } )
}
