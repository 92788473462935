import { Transition } from "@headlessui/react"
import { Times } from "icons/font-awesome/light"
import { useTranslations } from "modules/translations"
import React, { ReactNode } from "react"

export interface SlideOverProps {
    /**
     * Title of the slideover
     */
    title: string

    /**
     * Externally controlled boolean
     */
    show: boolean

    /**
     * Allow to set the external boolean
     */
    set( open: boolean ): void

    /**
     * The extra button in the header of the slideover
     */
    button?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

    /**
     * The items of the slideover
     */
    items: ReactNode
}

export default function SliderOver ( props: SlideOverProps ): JSX.Element {
    const { translate } = useTranslations()

    return (
        <Transition show={props.show}>
            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden" >
                    <div className="absolute inset-0 " aria-hidden="true" onClick={() => props.set( false )} />
                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                        <Transition.Child
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-md mt-16 h-full">
                                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                    <div className="px-4 py-6 sm:px-6">
                                        <div className="flex items-start justify-between">
                                            <h2 id="slide-over-heading" className="text-lg font-medium text-gray-900">{props.title}</h2>
                                            <div className="ml-3 h-7 flex items-center px-4">
                                                {props.button}
                                                <button
                                                    type="button"
                                                    className="bg-white rounded-md text-gray-400 focus:ring-2 focus:ring-indigo-500"
                                                    title={translate( "general.notifications.close-panel" )}
                                                    onClick={() => props.set( !props.show )}
                                                    aria-label="Close sliderover"
                                                >
                                                    <span className="sr-only">{translate( "general.notifications.close-panel" )}</span>
                                                    <Times className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white shadow w-full word-wrap rounded-lg px-0">
                                        {props.items}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </div>
        </Transition>
    )
}
