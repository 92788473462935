import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { Server } from "modules/api-client/src/servers/types"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface ServersApiSpec {
    serversRequest( options?: RequestOptions ): Promise<Response<Server[]>>
    ssoServerUrlRequest( server: string ): Promise<Response<string>>
}

export function useServersApi (): ServersApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retreive the servers from the API.
     */
    const serversRequest = useCallback( ( options?: RequestOptions ): Promise<Response<Server[]>> => {
        return getRequest<{ data: Server[] }>( "server", {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const ssoServerUrlRequest = useCallback( ( server: string ): Promise<Response<string>> => {
        return getRequest<string>( `server/${server}/sso` )
            .then( r => ( { ...r, data: r.data } ) )
    }, [getRequest] )

    return {
        serversRequest,
        ssoServerUrlRequest,
    }
}
