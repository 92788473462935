import { useTranslations } from "modules/translations"

/**
 * Get an instance of formatPeriod to make periods more human readable instead of only displaying in months.
 */
export default function usePeriodFormatter (): { formatPeriod: ( periodInMonths: number ) => string} {
    const { translate } = useTranslations()

    return {
        formatPeriod ( periodInMonths: number ): string {
            if ( periodInMonths === 1 ) {
                return translate( "general.units.month" )
            } else if ( periodInMonths < 12 || periodInMonths % 12 !== 0 ) {
                return `${periodInMonths} ${translate( "general.units.months" )}`
            } else if ( periodInMonths === 12 ) {
                return translate( "general.units.year" )
            } else {
                return `${periodInMonths / 12} ${translate( "general.units.years" )}`
            }
        },
    }
}
