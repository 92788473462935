import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { CancelParams } from "modules/api-client/src/services/requests/cancel-params"
import { Service, ServiceDetails } from "modules/api-client/src/services/types"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface ServicesApiSpec {
    servicesRequest( options?: RequestOptions ): Promise<Response<Service[]>>
    serviceDetailsRequest( id: number, options?: RequestOptions ): Promise<Response<ServiceDetails>>
    cancelServicesRequest( params: CancelParams ): Promise<Response<Service[]>>
}

export function useServicesApi (): ServicesApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, postRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retreive the current user from the API.
     */
    const servicesRequest = useCallback( ( options?: RequestOptions ): Promise<Response<Service[]>> => {
        return getRequest<{ data: Service[] }>( "services", {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const serviceDetailsRequest = useCallback( ( id: number, options?: RequestOptions ): Promise<Response<ServiceDetails>> => {
        return getRequest<{ data: ServiceDetails }>( `services/${id}`, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const cancelServicesRequest = useCallback( ( params: CancelParams ): Promise<Response<Service[]>> => {
        return postRequest<{ data: Service[] }>( "services/cancel", params )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [postRequest] )

    return {
        servicesRequest,
        serviceDetailsRequest,
        cancelServicesRequest,
    }
}
