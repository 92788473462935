import React, { ReactNode } from "react"

export interface SectionHeadingProps {
    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    /**
     * Optional ID for the heading element
     */
    id?: string

    /**
     * Optional flag for columns
     */
    inPanel?: true

    children: ReactNode
}

/**
 * Shows a section heading (`<H3>`).
 */
export default function SectionHeading ( props: SectionHeadingProps ): JSX.Element {
    return (
        <div className={`mb-5 border-b border-gray-200 ${props.inPanel ? "-mx-4 sm:-mx-6 mt-5 pb-5" : "mt-6 pb-3"} ${props.className || ""}`}>
            <h3 className={`leading-5 font-semibold text-gray-800 ${props.inPanel && "px-4 sm:px-6"}`} id={props.id}>
                {props.children}
            </h3>
        </div>
    )
}
