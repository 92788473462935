import React, { FC, MouseEvent, ReactNode } from "react"

export type ButtonVariant = "primary" // btn-primary
    | "secondary" // btn-secondary
    | "white" // btn-white
    | "danger" // btn-danger

export type ButtonSize = "md" | "sm" // btn-md btn-sm


export interface ButtonProps {
    /**
     * Function to call when the button is clicked / enter is pressed on the button
     */
    onClick: ( event: MouseEvent<HTMLButtonElement> ) => unknown

    /**
     * Variant of button to show, default is 'white'
     */
    variant?: ButtonVariant

    /**
     * Size of button to show, default is 'md'
     */
    size?: ButtonSize

    /**
     * Icon to show with the button
     */
    icon?: FC<{className?: string}>

    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    children: ReactNode
}

/**
 * A standard button (`type="button"`) with a required `onClick` handler.
 */
export default function Button ( props: ButtonProps ): JSX.Element {
    return (
        <button type="button" onClick={props.onClick || undefined}
            className={`btn btn-${props.variant || "white"} btn-${props.size || "md"} ${props.className || ""}`}>
            {props.icon && <props.icon className="-ml-1 mr-3" />}
            {props.children}
        </button>
    )
}
