import { EnableDnsPremiumResponse } from "modules/api-client/src/dns-premium/requests/enable-dns-premium-request"
import { RetrieveDnsPremiumParams } from "modules/api-client/src/dns-premium/requests/retrieve-dns-premium-params"
import { DnsPremium } from "modules/api-client/src/dns-premium/types"
import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface DnsPremiumApiSpec {
    domainHasDnsPremium( parameters: RetrieveDnsPremiumParams, options?: RequestOptions ): Promise<Response<DnsPremium>>
    enableDnsPremium( domain: string, options?: RequestOptions ): Promise<Response<EnableDnsPremiumResponse>>
}

export function useDnsPremiumApi (): DnsPremiumApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, postRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retrieve DNS Premium info from the API.
     */
    const domainHasDnsPremium = useCallback( ( parameters: RetrieveDnsPremiumParams, options?: RequestOptions ): Promise<Response<DnsPremium>> => {
        return getRequest<{ data: DnsPremium }>( `domain-name/${parameters.domain}/dns-premium`, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    /**
     * Enable DNS Premium for the given domain. Returns success
     * message when the action was processed successfully.
     */
    const enableDnsPremium = useCallback( ( domain: string ): Promise<Response<EnableDnsPremiumResponse>> => {
        return postRequest<EnableDnsPremiumResponse>( `domain-name/${domain}/dns-premium/enable` )
    }, [postRequest] )

    return {
        domainHasDnsPremium,
        enableDnsPremium,
    }
}
