import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.domain.ssl.private-key.title": {
        "en-US": "Upload the private key",
        "nl-NL": "Upload de private key",
    },
    "pages.domain.ssl.private-key.description": {
        "en-US": "To install the certificate on the hosting package we need the private key from the certificate.",
        "nl-NL": "Om het certificaat op het hostingpakket te kunnen installeren is de private key van het certificaat nodig.",
    },
    "pages.domain.ssl.private-key.text-area": {
        "en-US": "Private key",
        "nl-NL": "Private key",
    },
    "pages.domain.ssl.private-key.install": {
        "en-US": "Install certificate",
        "nl-NL": "Installeer certificaat",
    },
    "pages.domain.ssl.private-key.explanation": {
        "en-US": "A CSR for an SSL certificate has been uploaded. In order to install the certificate the private-key that belongs to the CSR is needed.",
        "nl-NL": "Een CSR voor een SSL certificaat is geupload. Om het certificaat te kunnen installeren is de bijbehorende private-key nodig.",
    },
} as TranslationDictionary
