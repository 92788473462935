import { CartPlus, Check } from "icons/font-awesome/solid"
import { Product, ProductPrice } from "modules/api-client/src/product/types"
import { useProductsData } from "modules/cached-data"
import { formatCurrency, stripDomainName } from "modules/helpers"
import { getPrice } from "modules/orders/src/cart-context/cart-context"
import { CartItem } from "modules/orders/src/cart-context/types"
import { useCart } from "modules/orders/src/cart-context/use-cart"
import { useTranslations } from "modules/translations"
import React, { useCallback, useMemo, useState } from "react"
import Button from "ui-kit/elements/button"
import Spinner from "ui-kit/elements/spinner"
import SectionHeading from "ui-kit/headings/section-heading"
import ProductGrid, { ProductGridItem } from "ui-kit/pricing/product-grid"

export default function OrderSslStep (): JSX.Element {
    const { translate } = useTranslations()
    const { cart } = useCart()
    const domains = Object.keys( cart.domains || {} )

    return <>
        {domains.length === 0 && <p>{translate( "modules.orders.error-no-domain-chosen" )}</p>}
        {domains.map( domain => <SslChooser key={domain} domain={domain}/> )}
    </>
}

function SslChooser ( { domain }: {domain: string } ): JSX.Element {
    const sslProductType = "ssl"
    const sslPriceType = "registration"
    const sslPeriod = 12

    const { addItem, cart, removeItem, updateItem } = useCart()
    const { translate } = useTranslations()
    const { products, isLoading } = useProductsData()

    const [selected, setSelected] = useState<CartItem | undefined>( () => cart.domains[domain].items.find( item => item.product.type === sslProductType ) )

    const sslProducts = useMemo( () => ( products || [] ).filter( product => product.type === sslProductType ), [products] )

    const getSslProductPrice = ( sslProduct: Product ): ProductPrice => {
        return sslProduct.prices.find( product => product.type === sslPriceType && product.period === sslPeriod )!
    }

    const unselect = useCallback( () => {
        if ( selected ) {
            removeItem( domain, selected )
            setSelected( undefined )
        }
    }, [selected, domain, removeItem] )

    const selectSslProduct = ( sslProduct: Product ): void => {
        unselect()

        const newItem: CartItem = {
            name: sslProduct.name,
            slug: sslProduct.slug,
            product: sslProduct,
            price: getSslProductPrice( sslProduct ),
        }
        addItem( domain, newItem )
        setSelected( newItem )

        if( sslProduct.productCouplings ) {
            addCoupledProductToCart( sslProduct.productCouplings, domain )
        }
    }

    function addCoupledProductToCart ( coupledProducts: Product[], queriedDomain: string ): void
    {
        coupledProducts.forEach( ( coupledProduct ) => {
            const price = coupledProduct.prices.find( ( price: ProductPrice ): boolean => {
                return price.type === "conditional" && price.period === 12
            } )

            if( ! price ){
                return
            }

            const domain = coupledProduct.type === "extension" ? stripDomainName( queriedDomain ) + coupledProduct.name : queriedDomain

            const cartItem = {
                name: coupledProduct.type === "extension" ? translate( "pages.order.cart.domain" ) : coupledProduct.name,
                slug: coupledProduct.slug,
                product: coupledProduct,
                price: price,
            }

            if( Object.keys( cart.domains ).includes( domain ) ){
                updateItem( domain, cartItem )
            } else {
                addItem( domain, cartItem )
            }
        } )
    }

    return <>
        <SectionHeading>
            {translate( "modules.orders.ssl-step.select-ssl-for-domain", { domain } )}
        </SectionHeading>

        {isLoading ? <>
            <div className="flex flex-row items-center justify-center h-32">
                <Spinner className="text-3xl text-brand-500" />
            </div>
        </> : <>
            <ProductGrid maxPerRow={3}>
                {sslProducts.map( ( sslProduct: Product ) => {
                    const buyButton = selected?.product.name === sslProduct.name ?
                        <div className="font-4xl border py-2 border-transparent font-bold">
                            <Button onClick={() => unselect()} icon={Check} variant="secondary">
                                {translate( "modules.orders.ssl-step.ssl-product-chosen", { product: sslProduct.name } )}
                            </Button>
                        </div>
                        :
                        <Button onClick={() => selectSslProduct( sslProduct )} icon={CartPlus} variant="primary">
                            {translate( "modules.orders.ssl-step.button-choose-ssl", { product: sslProduct.name } )}
                        </Button>
                    const monthlyAmount = getPrice( getSslProductPrice( sslProduct ), "net" ) / sslPeriod / 100

                    const productCouplings = sslProduct.productCouplings
                    const ProductCoupling = productCouplings ? translate(
                        "pages.order.order.product_coupling_upsell", {
                            parentProductName:sslProduct.name,
                            parentProductType: translate( `general.product-group.${sslProduct.type}` ),
                            coupledProductName: productCouplings[0].name,
                            coupledProductType:translate( `general.product-group.${productCouplings[0].type}` ),
                        }
                    ) : undefined

                    // TODO: replace sslProductName.toLowerCase() with sslProduct.slug when ticket above is done
                    return <ProductGridItem
                        className="rounded-lg"
                        key={sslProduct.name}
                        title={translate( `general.product-ssl.${sslProduct.slug}` )}
                        description={sslProduct.description}
                        price={formatCurrency( monthlyAmount, "EUR" )}
                        period={translate( "general.units.month" )}
                        buyButton={buyButton}
                        upSellText={ProductCoupling}
                    />

                } )}
            </ProductGrid>
        </>}
    </>
}
