import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "modules.orders.error-no-domain-chosen": {
        "en-US": "Please go back and choose a domain first.",
        "nl-NL": "Ga eerst terug en kies een domein uit.",
    },
    "modules.orders.hosting-step.select-hosting-for-domain": {
        "en-US": "Choose hosting for {domain}",
        "nl-NL": "Kies hosting voor {domain}",
    },
    "modules.orders.hosting-step.button-choose-hosting-product": {
        "en-US": "Select {product}",
        "nl-NL": "Kies {product}",
    },
    "modules.orders.hosting-step.hosting-product-chosen": {
        "en-US": "{product} selected",
        "nl-NL": "{product} geselecteerd",
    },
    "modules.orders.hosting-step.specs-title": {
        "en-US": "Specs",
        "nl-NL": "Specs",
    },
    "modules.orders.ssl-step.select-ssl-for-domain": {
        "en-US": "Choose SSL for {domain}",
        "nl-NL": "Kies SSL voor {domain}",
    },
    "modules.orders.ssl-step.button-choose-ssl": {
        "en-US": "Select {product}",
        "nl-NL": "Kies {product}",
    },
    "modules.orders.ssl-step.ssl-product-chosen": {
        "en-US": "{product} selected",
        "nl-NL": "{product} geselecteerd",
    },

} as TranslationDictionary
