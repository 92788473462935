import React, { ReactNode } from "react"

export interface PageHeadingProps {
    /**
     * The text to show as heading.
     */
    text: ReactNode

    /**
     * Optional ID for the header element
     */
    id?: string

    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    /**
     * Space for additional children (assumed to be buttons) to show beneath /
     * besides the page heading.
     */
    children?: ReactNode
}

/**
 * Shows a page heading (`<H1>`).
 */
export default function PageHeading ( props: PageHeadingProps ): JSX.Element {
    return (
        <div className={`mb-8 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0 ${props.className || ""}`}>
            <h1 className="text-3xl font-bold leading-tight text-brand-700" id={props.id}>
                {props.text}
            </h1>
            {
                props.children && <div className="flex space-x-3 sm:pt-1">
                    {props.children}
                </div>
            }
        </div>
    )
}
