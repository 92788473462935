import { ShieldAlt } from "icons/font-awesome/regular"
import { useProductsData } from "modules/cached-data"
import { CartItem, useCart } from "modules/orders"
import { isPrivacyProduct } from "modules/orders/src/components/order-overview-step"
import { useTranslations } from "modules/translations"
import React from "react"
import Button from "ui-kit/elements/button"

export function ConfigurePrivacyProtection ( { domain, item }: {domain: string, item: CartItem} ): JSX.Element {
    const { translate } = useTranslations()
    const { updateItem } = useCart()
    const { products } = useProductsData()

    const privacyProduct = products?.find( ( product ) => isPrivacyProduct( product.slug ) )

    function addPrivacyProtection (): void {
        const privacyProtectionAddon = item.addons?.find( ( product ) => isPrivacyProduct( product.slug ) )
        if ( privacyProtectionAddon || !privacyProduct ) {
            return
        }

        const newItem: CartItem = {
            ...item,
            addons: [
                ...item.addons || [],
                privacyProduct,
            ],
        }
        updateItem( domain, newItem )
    }

    return <div className="mb-8">
        <Button onClick={addPrivacyProtection}>
            <ShieldAlt className="mr-2" />
            {translate( "pages.order.privacy_protection" )}
        </Button>
    </div>
}
