import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.domain.domain.whois.back_to_domain": {
        "en-US": "Back to ",
        "nl-NL": "Terug naar ",
    },
    "pages.domain.domain.whois.form.email": {
        "en-US": "Email address",
        "nl-NL": "E-mailadres",
    },
    "pages.domain.domain.whois.form.first_name": {
        "en-US": "First name",
        "nl-NL": "Voornaam",
    },
    "pages.domain.domain.whois.form.last_name": {
        "en-US": "Last name",
        "nl-NL": "Achternaam",
    },
    "pages.domain.domain.whois.form.phone_number": {
        "en-US": "Phone number",
        "nl-NL": "Telefoonnummer",
    },
    "pages.domain.domain.whois.form.organization": {
        "en-US": "Organization",
        "nl-NL": "Bedrijfsnaam",
    },
    "pages.domain.domain.whois.form.street_name": {
        "en-US": "Street name",
        "nl-NL": "Straatnaam",
    },
    "pages.domain.domain.whois.form.street_number": {
        "en-US": "Street number",
        "nl-NL": "Huisnummer",
    },
    "pages.domain.domain.whois.form.zip_code": {
        "en-US": "Zip code",
        "nl-NL": "Postcode",
    },
    "pages.domain.domain.whois.form.city": {
        "en-US": "City",
        "nl-NL": "Plaats",
    },
    "pages.domain.domain.whois.form.country": {
        "en-US": "Country",
        "nl-NL": "Land",
    },
    "pages.domain.domain.whois.page_heading": {
        "en-US": "Update whois data",
        "nl-NL": "Whois-gegevens wijzigen",
    },
    "pages.domain.domain.whois.update_failure": {
        "en-US": "An error occured while updating your whois data. Please try again later.",
        "nl-NL": "Er is een fout opgetreden bij het opslaan van je whois-gegevens. Probeer het later opnieuw.",
    },
    "pages.domain.domain.whois.update_success": {
        "en-US": "Your whois data have been updated.",
        "nl-NL": "Je whois-gegevens zijn gewijzigd.",
    },

} as TranslationDictionary
