import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "modules.api-client.http_unsuccesful_notification": {
        "en-US": "Something went wrong while processing your request. Please try again, or contact customer service.",
        "nl-NL": "Er ging iets mis bij het verwerken van jouw aanvraag. Probeer het a.u.b. opnieuw, of neem contact op met de klantenservice.",
    },

    "modules.api-client.http_unauthorized_notification": {
        "en-US": "Please log in, the session has expired.",
        "nl-NL": "Log opnieuw in, de sessie is verlopen.",
    },

} as TranslationDictionary
