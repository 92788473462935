import { DisableDnsSecParams } from "modules/api-client/src/dnssec/requests/disable-dnssec-params"
import { EnableDnsSecParams } from "modules/api-client/src/dnssec/requests/enable-dnssec-params"
import { RetrieveDnsSecParams } from "modules/api-client/src/dnssec/requests/retrieve-dnssec-params"
import { UpdateDnsSecParams } from "modules/api-client/src/dnssec/requests/update-dnssec-params"
import { DnsSec } from "modules/api-client/src/dnssec/types"
import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface DnsSecApiSpec {
    dnsSecRequest( parameters: RetrieveDnsSecParams ): Promise<Response<DnsSec[]>>
    enableDnsSecRequest( parameters: EnableDnsSecParams, options?: RequestOptions ): Promise<Response<DnsSec[]>>
    disableDnsSecRequest( parameters: DisableDnsSecParams ): Promise<Response<[]>>
    updateDnsSecRequest ( domain: string, payload: UpdateDnsSecParams, options?: RequestOptions ): Promise<Response<DnsSec[]>>
}

export function useDnsSecApi (): DnsSecApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, postRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retreive DNSSEC info from the API.
     */
    const dnsSecRequest = useCallback( ( parameters: RetrieveDnsSecParams ): Promise<Response<DnsSec[]>> => {
        return getRequest<{ data: DnsSec[] }>( `domain-name/${parameters.domain}/dnssec` )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const enableDnsSecRequest = useCallback( ( parameters: EnableDnsSecParams, options?: RequestOptions ): Promise<Response<DnsSec[]>> => {
        return postRequest<{ data: DnsSec[] }>( `domain-name/${parameters.domain}/enable-dnssec`, undefined, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [postRequest] )

    const updateDnsSecRequest = useCallback( ( domain: string, payload: UpdateDnsSecParams, options?: RequestOptions ): Promise<Response<DnsSec[]>> => {
        return postRequest<{ data: DnsSec[] }>( `domain-name/${domain}/enable-dnssec`, payload, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [postRequest] )

    const disableDnsSecRequest = useCallback( ( parameters: DisableDnsSecParams ): Promise<Response<[]>> => {
        return postRequest<[]>( `domain-name/${parameters.domain}/disable-dnssec` )
    }, [postRequest] )

    return {
        dnsSecRequest,
        enableDnsSecRequest,
        updateDnsSecRequest,
        disableDnsSecRequest,
    }
}
