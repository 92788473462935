import Link from "next/link"
import React, { ReactNode } from "react"

export type HyperLinkVariant = "default" | "unstyled"

export interface HyperLinkProps {
    /**
     * The URL for the hyper link to point to
     */
    href: string

    /**
     * Variant of hyperlink to show, defaults to default styling
     */
    variant?: HyperLinkVariant

    /**
     * Optionally open link in new tab.
     */
    newTab?: boolean

    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    children: ReactNode
}

/**
 * A simple wrapper around `<a>` and `<Link>`.
 */
export default function HyperLink ( props: HyperLinkProps ): JSX.Element {
    const styleVariants = {
        default: `text-brand-500 underline ${props.className || ""}`,
        unstyled: `${props.className}`,
    }
    return props.newTab
        ? <a href={props.href} target="_blank" rel="noreferrer" className={styleVariants[props.variant || "default"]}>{props.children}</a>
        : <Link href={props.href}>
            <a className={styleVariants[props.variant || "default"]}>{props.children}</a>
        </Link>
}
