import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.services.page_heading": {
        "en-US": "Manage",
        "nl-NL": "Beheren",
    },
    "pages.services.table_header_customer": {
        "en-US": "Customer",
        "nl-NL": "Klant",
    },
    "pages.services.table_header_hostname": {
        "en-US": "Hostname",
        "nl-NL": "Hostnaam",
    },
    "pages.services.table_header_dns": {
        "en-US": "DNS",
        "nl-NL": "DNS",
    },
    "pages.services.table_header_domain": {
        "en-US": "Domain",
        "nl-NL": "Domein",
    },
    "pages.services.table_header_hosting": {
        "en-US": "Hosting",
        "nl-NL": "Hosting",
    },
    "pages.services.table_header_ssl": {
        "en-US": "SSL protection",
        "nl-NL": "SSL beveiliging",
    },
    "pages.services.table_element_yes": {
        "en-US": "Yes",
        "nl-NL": "Ja",
    },
    "pages.services.table_element_no": {
        "en-US": "No",
        "nl-NL": "Nee",
    },
    "pages.services.table_element_manage": {
        "en-US": "Manage",
        "nl-NL": "Beheer",
    },
    "pages.services.button_download_csv": {
        "en-US": "Download CSV",
        "nl-NL": "Download CSV",
    },
    "pages.services.button_add_dns": {
        "en-US": "Add DNS",
        "nl-NL": "DNS toevoegen",
    },
    "pages.services.fetch-error": {
        "en-US": "Currently unable to retrieve services data. Please try again later.",
        "nl-NL": "Kon producten niet ophalen, probeer het later opnieuw.",
    },

    "pages.services.welcome-banner": {
        "en-US": "Hi {first_name}, welcome to your customerpanel.",
        "nl-NL": "Hi {first_name}, welkom in jouw klantenpaneel.",
    },
    "pages.services.verified-customer": {
        "en-US": "Verified customer",
        "nl-NL": "Geverifieerde klant",
    },
    "pages.services.unverified-customer" : {
        "en-US": "Unverified customer",
        "nl-NL": "Ongeverifieerde klant",
    },
    "pages.services.reseller" : {
        "en-US": "Reseller",
        "nl-NL": "Reseller",
    },

    "pages.services.subscribed-to-newsletter": {
        "en-US": "Subscribed to newsletter",
        "nl-NL": "Ingeschreven voor nieuwsbrief",
    },
} as TranslationDictionary
