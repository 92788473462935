import { useNotifications } from "modules/notifications"
import useConfig from "next/config"
import Notification from "ui-kit/overlays/notification"

function NotificationContainer (): JSX.Element {

    const { notification, closeNotification } = useNotifications()
    const { autoCloseNotificationsAfter } = useConfig().publicRuntimeConfig as { autoCloseNotificationsAfter: number }

    let notificationElement: JSX.Element | undefined

    if ( notification ) {
        notificationElement = <Notification {...notification} autocloseAfter={autoCloseNotificationsAfter} onClose={() => closeNotification()} />
    }

    return (
        <div className="fixed inset-0 top-16 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
            {notificationElement}
        </div>
    )
}

export { NotificationContainer }
