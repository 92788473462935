import * as Sentry from "@sentry/node"
import { AuthContextProvider, useAuth } from "modules/auth"
import { GlobalSearchDataContextProvider, ProductsDataContextProvider, ServicesDataContextProvider } from "modules/cached-data"
import { AuthorizedPageContainer, UnauthorizedPageContainer } from "modules/core"
import { ModalContextProvider } from "modules/modals/src/modal-context"
import { NotificationContextProvider } from "modules/notifications"
import { CartContextProvider } from "modules/orders"
import { TranslationContextProvider } from "modules/translations"
import { dictionary } from "modules/translations/src/locale/dictionary"
import type { AppProps } from "next/app"
import getConfig from "next/config"
import Head from "next/head"
import { useRouter } from "next/router"
import { ReactNode, useEffect, useState } from "react"

if ( process.env.NEXT_PUBLIC_SENTRY_DSN ) {
    Sentry.init( {
        enabled: process.env.NODE_ENV === "production",
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        environment: process.env.SENTRY_ENVIRONMENT,
    } )
}

const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: { unauthorizedRoutes: string[], versionNumber: string, brandTheme: string, appTitle: string, gtmId: string } }

export default function HostingFrameworkFrontend ( { Component, pageProps }: AppProps ): ReactNode {

    const unauthorizedRoutes: string[] = publicRuntimeConfig.unauthorizedRoutes
    const versionNumber = publicRuntimeConfig.versionNumber
    const brandTheme = publicRuntimeConfig.brandTheme
    const appTitle = publicRuntimeConfig.appTitle
    const { pathname, asPath } = useRouter()

    /**
     * Only run this code on page load in the browser.
     */
    useEffect( () => {
        if ( typeof window !== "undefined" && typeof window.navigator?.serviceWorker !== "undefined" ) {
            navigator.serviceWorker.getRegistrations()
                .then( workers => workers.forEach( w => w.unregister() ) )
                .then( x => console.log( "Unregistered old service workers" ) ) //eslint-disable-line
        }
    }, [] )

    // Only use the sidebar layout on authorized routes.
    const content = unauthorizedRoutes.includes( pathname ) ?
        <UnauthorizedPageContainer>
            <Component {...pageProps} />
        </UnauthorizedPageContainer>
        :
        <GlobalSearchDataContextProvider>
            <AuthorizedPageContainer>
                <ServicesDataContextProvider>
                    <ProductsDataContextProvider>
                        <CartContextProvider>
                            <Component {...pageProps} />
                        </CartContextProvider>
                    </ProductsDataContextProvider>
                </ServicesDataContextProvider>
            </AuthorizedPageContainer>
        </GlobalSearchDataContextProvider>

    return (
        <div>
            <Head>
                <title>{appTitle}</title>
                <link href={`/css/all.css?version=${versionNumber}`} rel="stylesheet" />
                <link rel="apple-touch-icon" sizes="180x180" href={`/img/${brandTheme}/apple-touch-icon.png`} />
                <link rel="icon" type="image/png" sizes="32x32" href={`/img/${brandTheme}/favicon-32x32.png`} />
                <link rel="icon" type="image/png" sizes="16x16" href={`/img/${brandTheme}/favicon-16x16.png`} />
                <link rel="manifest" href={`/img/${brandTheme}/site.webmanifest`} />
                <link rel="mask-icon" href={`/img/${brandTheme}/safari-pinned-tab.svg`} color="#5bbad5" />
                <link rel="shortcut icon" href={`/img/${brandTheme}/favicon.ico`} />
                <meta name="msapplication-config" content={`/img/${brandTheme}/browserconfig.xml`} />
            </Head>

            <TranslationContextProvider dictionary={dictionary}>
                <AuthContextProvider>
                    <GoogleTagManagerInitializer/>
                    <ModalContextProvider>
                        <NotificationContextProvider>
                            <div id={`page${asPath.replace( /\//g, "_" )}`}>
                                {content}
                            </div>
                        </NotificationContextProvider>
                    </ModalContextProvider>
                </AuthContextProvider>
            </TranslationContextProvider>
        </div>
    )
}

function GoogleTagManagerInitializer (): JSX.Element
{
    const { user } = useAuth()
    const [ gtmInitialized, setGtmInitialized ] = useState( false )

    useEffect( () => {
        if ( ! user || ! publicRuntimeConfig.gtmId || gtmInitialized ) {
            return
        }

        // add head script tag
        const head = document.querySelector( "head" )!
        const script = document.createElement( "script" )
        script.type = "text/javascript"
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${publicRuntimeConfig.gtmId}');`
        head.prepend( script )

        // add iframe tag
        const body = document.querySelector( "body" )!
        const noscript = document.createElement( "noscript" )

        const iframe = document.createElement( "iframe" )
        iframe.src = `https://www.googletagmanager.com/ns.html?id=${publicRuntimeConfig.gtmId}`
        iframe.height = "0"
        iframe.width = "0"
        iframe.setAttribute( "style", "display:none;visibility:hidden" )

        noscript.appendChild( iframe )
        body.prepend( noscript )

        setGtmInitialized( true )
    }, [gtmInitialized, user] )

    return <></>
}
