import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.dnssec.dnssec": {
        "en-US": "DNSSEC",
        "nl-NL": "DNSSEC",
    },
    "pages.dnssec.type": {
        "en-US": "Type",
        "nl-NL": "Type",
    },
    "pages.dnssec.algorithm": {
        "en-US": "Algorithm",
        "nl-NL": "Algoritme",
    },
    "pages.dnssec.public_key": {
        "en-US": "Public key",
        "nl-NL": "Publieke sleutel",
    },
    "pages.dnssec.not-found": {
        "en-US": "DNSSEC could not be found.",
        "nl-NL": "DNSSEC kon niet gevonden worden.",
    },
} as TranslationDictionary
