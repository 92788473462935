import { ChatSession, Customer, User } from "modules/auth/src/types"
import { usePersistentState } from "modules/helpers"
import getConfig from "next/config"
import { useRouter } from "next/router"
import React, { createContext, useCallback, useEffect, useState } from "react"

const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: {
    defaultRoute: string
    loginRoute: string
    unauthorizedRoutes: string[]
} }

const clearStorage = (): void => {
    if ( ! process.browser ) {
        return
    }
    if ( typeof window?.localStorage === "undefined" ) {
        return
    }
    localStorage.clear()
}

export interface AuthContextType {
    customer?: Customer
    customers: Customer[]
    user?: User
    originalUser?: User
    pendingRedirect?: string
    setPendingRedirect( pendingRedirect?: string ): void
    login( user: User, token?: string, chatSession?: ChatSession, originalUser?: User ): void
    switchCustomer( customerId: number ): void
    logout(): void
}

export const AuthContext = createContext<AuthContextType>( {} as AuthContextType )

interface AuthContextProps {
    children: React.ReactNode
}

export function AuthContextProvider ( { children }: AuthContextProps ): JSX.Element {
    const { pathname, asPath, push, isReady } = useRouter()

    const [user, setUser] = usePersistentState<User>( "auth.user" )
    const [originalUser, setOriginalUser] = usePersistentState<User>( "auth.original_user" )
    const [customer, setCustomer] = usePersistentState<Customer>( "auth.customer" )
    const [customers, setCustomers] = usePersistentState<Customer[]>( "auth.customers", [] )
    const [pendingRedirect, setPendingRedirect] = useState<string>()

    const switchCustomer = useCallback( ( customerId: number ): void => {
        if ( ! user ) {
            return
        }
        const customer = user.customers.find( c => c.id === customerId )
        if ( ! customer ) {
            return
        }
        setCustomer( customer )
        push( publicRuntimeConfig.defaultRoute )
    }, [user, setCustomer, push] )

    // The push function is updated every route change.
    // We do not want to redefine the redirect function every time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const toLoginRoute = useCallback( () => push( publicRuntimeConfig.loginRoute ), [] )

    const logout = useCallback( (): void => {
        setUser( undefined )
        setCustomer( undefined )
        setOriginalUser( undefined )
        setCustomers( [] )
        clearStorage()
        toLoginRoute()
    }, [setUser, setCustomer, setOriginalUser, setCustomers, toLoginRoute] )

    const login = useCallback( ( user: User, token?: string, chatSession?: ChatSession, originalUser?: User ): void => {
        setUser( user )
        setCustomers( user.customers )

        if ( originalUser ) {
            setOriginalUser( originalUser )
        }

        const customer = user.customers.find( c => c.id === user.customer_id )
        if ( ! customer ) {
            logout()
            return
        }
        setCustomer( customer )
    }, [setUser, setCustomer, setCustomers, setOriginalUser, logout] )

    /**
     * Check if user is logged in every time pathname changes within the router.
     */
    useEffect( () => {
        if( ! isReady ){
            return
        }

        const unauthorizedRoutes: string[] = publicRuntimeConfig.unauthorizedRoutes
        if ( !unauthorizedRoutes.includes( pathname ) && !user ) {
            if ( pathname !== "/logout" ) {
                setPendingRedirect( asPath )
            }
            toLoginRoute()
        }
        /*
         * We disable the exhaustive deps array here. We can unfortunately not add `asPath` to the
         * dependency array, as this causes an infinite loop. Within the NextJS Router, the `pathname` and `asPath`
         * are updated right after eachother, triggering this useEffect indefinitely.
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, pathname, toLoginRoute, setPendingRedirect, isReady] )

    return (
        <AuthContext.Provider
            value={{
                customer,
                customers: customers ?? [],
                user,
                originalUser,
                pendingRedirect,
                setPendingRedirect,
                login,
                switchCustomer,
                logout,
            }}>
            {children}
        </AuthContext.Provider>
    )
}
