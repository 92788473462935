import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.dns-templates.index.page-header": {
        "en-US": "DNS templates",
        "nl-NL": "DNS templates",
    },
    "pages.dns-templates.index.button-add-dns-template": {
        "en-US": "Add template",
        "nl-NL": "Template toevoegen",
    },
    "pages.dns-templates.index.domain": {
        "en-US": "Domain",
        "nl-NL": "Domein",
    },
    "pages.dns-templates.index.amount-domains-linked": {
        "en-US": "Domains linked",
        "nl-NL": "Gekoppelde domeinen",
    },
    "pages.dns-templates.index.save-template": {
        "en-US": "Save",
        "nl-NL": "Opslaan",
    },
    "pages.dns-templates.index.cancel": {
        "en-US": "Cancel",
        "nl-NL": "Annuleren",
    },
    "pages.dns-templates.index.template-name": {
        "en-US": "Template name",
        "nl-NL": "Template naam",
    },
} as TranslationDictionary
