import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.dns-templates.template.edit.edit-template-name": {
        "en-US": "Edit template name",
        "nl-NL": "Template naam bewerken",
    },
    "pages.dns-templates.template.edit.update-successful": {
        "en-US": "DNS template: {value} successfully updated.",
        "nl-NL": "DNS template: {value} succesvol geüpdate.",
    },
    "pages.dns-templates.template.edit.update-failure": {
        "en-US": "An error occured while updating your DNS template. Please try again later.",
        "nl-NL": "Er is een fout opgetreden bij het opslaan van je DNS template. Probeer het later opnieuw.",
    },
    "pages.dns-templates.template.edit.configure-template": {
        "en-US": "Manage template",
        "nl-NL": "Template beheren",
    },

} as TranslationDictionary
