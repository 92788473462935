import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "ui-kit.forms.form-field.optional": {
        "en-US": "optional",
        "nl-NL": "optioneel",
    },

} as TranslationDictionary
