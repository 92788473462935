import { CheckTwoFactorParams } from "modules/api-client/src/auth/requests/check-2fa-params"
import { LoginAsParams } from "modules/api-client/src/auth/requests/login-as-params"
import { LoginParams } from "modules/api-client/src/auth/requests/login-params"
import { PasswordEmailParams } from "modules/api-client/src/auth/requests/password-email-params"
import { PasswordResetParams } from "modules/api-client/src/auth/requests/password-reset-params"
import { PasswordSetParams } from "modules/api-client/src/auth/requests/password-set-params"
import { PasswordTokenParams } from "modules/api-client/src/auth/requests/password-token-params"
import { RegisterUserParams } from "modules/api-client/src/auth/requests/register-user-params"
import { WaterfrontRedirectParams } from "modules/api-client/src/auth/requests/waterfront-redirect-params"
import { CheckTwoFactorResponse } from "modules/api-client/src/auth/responses/check-2fa-response"
import { LoginAsResponse } from "modules/api-client/src/auth/responses/login-as-response"
import { LoginResponse } from "modules/api-client/src/auth/responses/login-response"
import { LogoutResponse } from "modules/api-client/src/auth/responses/logout-response"
import { PasswordEmailResponse } from "modules/api-client/src/auth/responses/password-email-response"
import { PasswordResetResponse } from "modules/api-client/src/auth/responses/password-reset-response"
import { PasswordSetResponse } from "modules/api-client/src/auth/responses/password-set-response"
import { PasswordTokenResponse } from "modules/api-client/src/auth/responses/password-token-response"
import { RegisterUserResponse } from "modules/api-client/src/auth/responses/register-user-response"
import { ResetTwoFactorResponse } from "modules/api-client/src/auth/responses/reset-2fa-response"
import { SetTwoFactorResponse } from "modules/api-client/src/auth/responses/set-2fa-response"
import { WaterfrontRedirectResponse } from "modules/api-client/src/auth/responses/waterfront-redirect-response"
import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface AuthApiSpec {
    loginRequest( parameters: LoginParams, options?: RequestOptions ): Promise<Response<LoginResponse>>
    loginAsRequest( parameters: LoginAsParams, options?: RequestOptions ): Promise<Response<LoginAsResponse>>
    loginLighthouseRequest( parameters: WaterfrontRedirectParams ): Promise<Response<WaterfrontRedirectResponse>>
    logoutRequest ( options?: RequestOptions ): Promise<Response<LogoutResponse>>
    setTwoFactorRequest ( options?: RequestOptions ): Promise<Response<SetTwoFactorResponse>>
    checkTwoFactorRequest ( parameters: CheckTwoFactorParams, options?: RequestOptions ): Promise<Response<CheckTwoFactorResponse>>
    resetTwoFactorRequest ( options?: RequestOptions ): Promise<Response<ResetTwoFactorResponse>>
    passwordEmailRequest ( parameters: PasswordEmailParams, options?: RequestOptions ): Promise<Response<PasswordEmailResponse>>
    passwordResetRequest ( parameters: PasswordResetParams, options?: RequestOptions ): Promise<Response<PasswordResetResponse>>
    passwordSetRequest ( parameters: PasswordSetParams, options?: RequestOptions ): Promise<Response<PasswordSetResponse>>
    passwordTokenValidRequest ( parameters: PasswordTokenParams ): Promise<Response<PasswordTokenResponse>>
    registerUserRequest ( user: RegisterUserParams, options?: RequestOptions ): Promise<Response<RegisterUserResponse>>
}

export function useAuthApi (): AuthApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { postRequest, getRequest } = useHttpClient( baseUrl, customer )

    const loginRequest = useCallback( ( parameters: LoginParams, options?: RequestOptions ): Promise<Response<LoginResponse>> => {
        return postRequest<LoginResponse>( "login", parameters, {}, options )
    }, [postRequest] )

    const loginAsRequest = useCallback( ( parameters: LoginAsParams, options?: RequestOptions ): Promise<Response<LoginAsResponse>> => {
        return postRequest<LoginAsResponse>( `login-as/${parameters.user}`, parameters, {}, options )
    }, [postRequest] )

    const loginLighthouseRequest = useCallback( ( parameters: WaterfrontRedirectParams ): Promise<Response<WaterfrontRedirectResponse>> => {
        return postRequest<WaterfrontRedirectResponse>( "lighthouse/start-session", parameters )
    }, [postRequest] )

    const logoutRequest = useCallback( ( options?: RequestOptions ): Promise<Response<LogoutResponse>> => {
        return postRequest<LogoutResponse>( "logout", {}, {}, options )
    }, [postRequest] )

    const checkTwoFactorRequest = useCallback( ( parameters: CheckTwoFactorParams, options?: RequestOptions ): Promise<Response<CheckTwoFactorResponse>> => {
        return postRequest<CheckTwoFactorResponse>( "2fa/check", parameters, {}, options )
    }, [postRequest] )

    const resetTwoFactorRequest = useCallback( ( options?: RequestOptions ): Promise<Response<ResetTwoFactorResponse>> => {
        return postRequest<ResetTwoFactorResponse>( "2fa/reset", {}, {}, options )
    }, [postRequest] )

    const setTwoFactorRequest = useCallback( ( options?: RequestOptions ): Promise<Response<SetTwoFactorResponse>> => {
        return postRequest<SetTwoFactorResponse>( "2fa/set", {}, {}, options )
    }, [postRequest] )

    const passwordEmailRequest = useCallback( ( parameters: PasswordEmailParams, options?: RequestOptions ): Promise<Response<PasswordEmailResponse>> => {
        return postRequest<PasswordEmailResponse>( "password/email", parameters, {}, options )
    }, [postRequest] )

    const passwordResetRequest = useCallback( ( parameters: PasswordResetParams, options?: RequestOptions ): Promise<Response<PasswordResetResponse>> => {
        return postRequest<PasswordResetResponse>( "password/reset", parameters, {}, options )
    }, [postRequest] )

    const passwordSetRequest = useCallback( ( parameters: PasswordSetParams, options?: RequestOptions ): Promise<Response<PasswordSetResponse>> => {
        return postRequest<PasswordSetResponse>( "password/set", parameters, {}, options )
    }, [postRequest] )

    const passwordTokenValidRequest = useCallback( ( parameters: PasswordTokenParams ): Promise<Response<PasswordTokenResponse>> => {
        return getRequest<{data: PasswordTokenResponse}>( `password/reset/${parameters.token}`, { email: parameters.email } )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const registerUserRequest = useCallback( ( user: RegisterUserParams, options?: RequestOptions ): Promise<Response<RegisterUserResponse>> => {
        return postRequest<RegisterUserResponse>( "register", user, {}, options )
    }, [postRequest] )

    return {
        loginRequest,
        loginAsRequest,
        loginLighthouseRequest,
        logoutRequest,
        setTwoFactorRequest,
        checkTwoFactorRequest,
        resetTwoFactorRequest,
        passwordEmailRequest,
        passwordResetRequest,
        passwordSetRequest,
        passwordTokenValidRequest,
        registerUserRequest,
    }
}
