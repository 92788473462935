import { Transition } from "@headlessui/react"
import { ChevronRight } from "icons/font-awesome/solid"
import { useTranslations } from "modules/translations"
import Link from "next/link"
import React, { FormEvent, KeyboardEvent, useEffect, useState } from "react"
import { ListItem } from "ui-kit/lists/stacked-list"

export interface SearchProps {
    maxResults?: number
    data: ListItem[]
    show: boolean
    onClose: ( newValue: boolean ) => void
}

export default function Search ( props: SearchProps ): JSX.Element {
    const { translate } = useTranslations()
    const [searchTerm, setSearchTerm] = useState( "" )
    const [previousFocus, setPreviousFocus] = useState<HTMLElement>()
    const searchInput = React.createRef<HTMLInputElement>()
    const filteredItems = filterItems( props.data, searchTerm )
    function handleSearchSubmit ( event: FormEvent ): void {
        setSearchTerm( searchInput.current!.value! )
        event.preventDefault()
    }

    /**
     * To ensure the enter animation plays, only start showing the item
     * after the initial render.
     */
    useEffect( () => {
        setPreviousFocus( document.activeElement as HTMLElement )
        setTimeout( () => {
            searchInput.current?.focus()
        } )
    }, [searchInput] )

    function keyDownHandler ( event: KeyboardEvent ): void {
        /**
         * Official keys are defined in the DOM Level 3 Event Spec
         * @see https://www.w3.org/TR/uievents-key/#named-key-attribute-values
         */
        const dismissKeys = ["Escape"]

        if ( dismissKeys.includes( event.key ) ) {
            closeModal()
        }
    }

    function closeModal (): void
    {
        props.onClose( false )
        setTimeout( () => {
            previousFocus!.focus()
        } )
    }

    return <>
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex flex-row justify-center px-4 py-4 sm:pb-20">
                <Transition
                    show={props.show}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    {
                        ( ref ) =>
                            <div ref={ref} className="fixed inset-0 transition-opacity" onKeyDown={keyDownHandler} role="document" aria-hidden="true">
                                <div className="absolute inset-0 bg-gray-500 opacity-75" role="document" aria-hidden="true" onClick={() => closeModal()}></div>
                            </div>
                    }
                </Transition>
                <Transition
                    show={props.show}
                    enter="ease-out duration-300"
                    enterFrom="translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    {
                        ( ref ) =>
                            <div ref={ref} onKeyDown={keyDownHandler} className="overflow-hidden text-left transition-all transform sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-hidden="true" aria-modal="true" aria-labelledby="modal-headline">
                                <div className="flex flex-col w-full space-y-5 ">
                                    <div className="z-50 h-auto">
                                        <form onSubmit={handleSearchSubmit}>
                                            <input id="listSearch" name="searchterm" ref={searchInput} type="search"
                                                onChange={( event ) => setSearchTerm( event.target.value )}
                                                placeholder={translate( "general.form-labels.search-placeholder" )}
                                                className="w-full text-gray-900 form-input sm:text-sm sm:leading-5" />
                                        </form>
                                    </div>
                                    <Transition
                                        show={searchTerm !== "" || filteredItems.length >= 1}
                                        enter="ease-in duration-300"
                                        enterFrom="translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="translate-y-0 sm:scale-100 duration-6000"
                                        leave="ease-out duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                        {searchTerm !== "" && <SearchResultComponent maxResults={props.maxResults} filteredItems={filteredItems} filteringItems={false} onNavigation={() => closeModal()} />}
                                    </Transition>
                                </div>
                            </div>
                    }
                </Transition>
            </div>
        </div>
    </>
}


function filterItems ( items: ListItem[], searchTerm: string, ): ListItem[] {
    if ( searchTerm === "" ) {
        return items
    }
    return items.filter( value => {
        return String( value.title ).toLocaleLowerCase().includes( searchTerm.toLocaleLowerCase() )
    } )
}

function SearchResultComponent ( props: { maxResults?: number, filteredItems: ListItem[], filteringItems: boolean, onNavigation: () => void } ): JSX.Element
{
    const maxResults = props?.maxResults || 10

    return <ul className="h-auto overflow-hidden bg-white divide-y divide-gray-200 rounded-lg">
        {props.filteredItems.length > 0 ? props.filteredItems.slice( 0, maxResults ).map( ( item, key ) => {
            return <ResultItem item={item} key={key} onNavigation={() => props.onNavigation()} />
        } ) : <ItemEmptyState />}
    </ul>
}

function ResultItem ( props: { item: ListItem, onNavigation: () => void} ): JSX.Element
{
    const ConditionalLinkWrapper = ( props: { link?: string, children: JSX.Element } ): JSX.Element => {
        return props.link ? <Link href={props.link}><a>{props.children}</a></Link> : props.children
    }

    return <li className="cursor-pointer hover:bg-gray-50 focus:border-brand-500" aria-hidden="true" onClick={props.onNavigation}>
        <ConditionalLinkWrapper link={props.item.location}>
            <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
                    <div>
                        <div className="flex text-sm font-medium truncate text-brand-600">
                            <p>{props.item.title}</p>
                        </div>
                        {props.item.description && <div className="flex mt-2">
                            <div className="flex items-center h-3 text-sm text-gray-500">
                                <span className="truncate">
                                    {props.item.description}
                                </span>
                            </div>
                        </div>}
                    </div>
                    <div className="flex-shrink-0 mt-4 sm:mt-0">
                        <div className="flex text-sm text-gray-500 truncate overflow-ellipsis">
                            {props.item.detail}
                        </div>
                    </div>
                </div>
                {props.item.location && <div className="flex-shrink-0 ml-5">
                    <ChevronRight className="text-brand-500"/>
                </div>}
            </div>
        </ConditionalLinkWrapper>
    </li>
}

function ItemEmptyState (): JSX.Element {
    const { translate } = useTranslations()

    return <li className="cursor-pointer hover:bg-gray-50 focus:border-brand-500 focus:border-t focus:border-b">
        <div className="flex items-center px-4 py-4 sm:px-6">
            <div className="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
                <div>
                    <div className="flex text-sm font-medium truncate text-brand-600">
                        {translate( "general.search-no-result" )}
                    </div>
                </div>
                <div className="flex-shrink-0 mt-4 sm:mt-0">
                    <div className="flex text-sm text-gray-500 truncate overflow-ellipsis">

                    </div>
                </div>
            </div>
        </div>
    </li>
}
