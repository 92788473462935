import { QueryParameters } from "modules/api-client"

export default function formatApiRoute ( baseUrl: string, route: string, query: QueryParameters ): string {
    const params = new URLSearchParams()
    for ( const [key, value] of Object.entries( query ) ) {
        params.append( key, value )
    }
    return params.toString() === ""
        ? `${baseUrl}/${route}`
        : `${baseUrl}/${route}?${params.toString()}`
}
