import { StoreDnsTemplateParams } from "modules/api-client/src/dns/requests/create-dns-template"
import { CreateDnsZoneRequestParams } from "modules/api-client/src/dns/requests/create-dns-zone-params"
import { DeleteDnsRecordRequestParams } from "modules/api-client/src/dns/requests/delete-dns-params"
import { DnsRecordsRequestParams } from "modules/api-client/src/dns/requests/retrieve-dns-params"
import { StoreDnsRecordRequestParams } from "modules/api-client/src/dns/requests/store-dns-params"
import { UpdateDnsRecordRequestParams } from "modules/api-client/src/dns/requests/update-dns-params"
import { UpdateDnsTemplateParams } from "modules/api-client/src/dns/requests/update-dns-template"
import { DeleteDnsRecordResponse } from "modules/api-client/src/dns/responses/delete-dns-record-response"
import { StoreDnsRecordResponse } from "modules/api-client/src/dns/responses/store-dns-record-response"
import { UpdateDnsTemplateResponse } from "modules/api-client/src/dns/responses/update-dns-template-response"
import { DnsRecord, DnsRecordTypes, DnsTemplate, DnsTTLValues } from "modules/api-client/src/dns/types"
import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

import { AvailableDomainsResponse } from "./responses/available-domains-response"
import { StoreDnsTemplateResponse } from "./responses/store-dns-template-response"
import { UpdateDnsRecordResponse } from "./responses/update-dns-record-response"

export type { DnsRecord }

export { DnsRecordTypes, DnsTTLValues }

interface DnsApiSpec {
    storeDnsTemplateRequest( parameters: StoreDnsTemplateParams, options?: RequestOptions ): Promise<Response<StoreDnsTemplateResponse>>
    storeDnsTemplateRecordRequest( template: string, parameters: DnsRecord, options?: RequestOptions ): Promise<Response<StoreDnsTemplateResponse>>
    dnsTemplatesRequest( options?: RequestOptions ): Promise<Response<DnsTemplate[]>>
    createDnsZoneRequest( parameters: CreateDnsZoneRequestParams, options?: RequestOptions ): Promise<Response<DnsRecord[]>>
    deleteDnsRecordRequest( parameters: DeleteDnsRecordRequestParams, options?: RequestOptions ): Promise<Response<DeleteDnsRecordResponse>>
    updateDnsRecordRequest( parameters: UpdateDnsRecordRequestParams, options?: RequestOptions ): Promise<Response<UpdateDnsRecordResponse>>
    dnsRequest( parameters: DnsRecordsRequestParams, options?: RequestOptions ): Promise<Response<DnsRecord[]>>
    storeDnsRecordRequest( parameters: StoreDnsRecordRequestParams, options?: RequestOptions ): Promise<Response<StoreDnsRecordResponse>>
    updateDnsTemplateRequest( templateId: string, payload: UpdateDnsTemplateParams ): Promise<Response<UpdateDnsTemplateResponse>>
    fetchDnsTemplateRequest( template: string, options?: RequestOptions ): Promise<Response<DnsTemplate>>
    fetchAvailableDomainsRequest( template: string, options?: RequestOptions ): Promise<Response<AvailableDomainsResponse>>
    linkDomainsToTemplateRequest( templateId: string, domains: string[], options?: RequestOptions ): Promise<Response<{ message: string}>>
    unlinkDomainsFromTemplateRequest( templateId: string, domains: string[], options?: RequestOptions ): Promise<Response<{ message: string}>>
}

export function useDnsApi (): DnsApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, postRequest, patchRequest, deleteRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retrieve the DNS records of an existing DNS zone. Gives a 404
     * if no DNS zone exists yet.
     */
    const dnsRequest = useCallback( ( parameters: DnsRecordsRequestParams, options?: RequestOptions ): Promise<Response<DnsRecord[]>> => {
        return getRequest<{ data: DnsRecord[] }>( `domain/${parameters.domain}/dns`, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    /**
     * Request to store a new DNS record in an existing DNS zone.
     */
    const storeDnsRecordRequest = useCallback( ( parameters: StoreDnsRecordRequestParams, options?: RequestOptions ): Promise<Response<StoreDnsRecordResponse>> => {
        return postRequest<StoreDnsRecordResponse>( `domain/${parameters.domain}/dns`, parameters, {}, options )
    }, [postRequest] )

    /**
     * Request to delete a DNS record from an existing DNS zone.
     */
    const deleteDnsRecordRequest = useCallback( ( parameters: DeleteDnsRecordRequestParams, options?: RequestOptions ): Promise<Response<DeleteDnsRecordResponse>> => {
        return deleteRequest<DeleteDnsRecordResponse>( `domain/${parameters.domain}/dns/1`, parameters, {}, options )
    }, [deleteRequest] )

    /**
     * Request to update a DNS record from an existing DNS zone.
     */
    const updateDnsRecordRequest = useCallback( ( parameters: UpdateDnsRecordRequestParams, options?: RequestOptions ): Promise<Response<UpdateDnsRecordResponse>> => {
        return patchRequest<UpdateDnsRecordResponse>( `domain/${parameters.domain}/dns/1`, parameters, {}, options )
    }, [patchRequest] )

    /**
     * Request to create a DNS zone for the given domain.
     */
    const createDnsZoneRequest = useCallback( ( parameters: CreateDnsZoneRequestParams, options?: RequestOptions ): Promise<Response<DnsRecord[]>> => {
        return postRequest<{ data: DnsRecord[] }>( `domain/${parameters.domain}/dns-zone`, parameters, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [postRequest] )

    /**
     * Request to update the dns template.
     */
    const updateDnsTemplateRequest = useCallback( ( templateId: string, payload: UpdateDnsTemplateParams, options?: RequestOptions ): Promise<Response<UpdateDnsTemplateResponse>> => {
        return patchRequest<UpdateDnsTemplateResponse>( `dns/templates/${templateId}`, payload, {}, options )
    }, [patchRequest] )

    /**
     * Request to store DNS template
     */
    const storeDnsTemplateRequest = useCallback( ( parameters: StoreDnsTemplateParams, options?: RequestOptions ): Promise<Response<StoreDnsTemplateResponse>> => {
        return postRequest<{ data: StoreDnsTemplateResponse }>( "dns/templates", parameters, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [postRequest] )

    /**
     * Request to store single record.
     */
    const storeDnsTemplateRecordRequest = useCallback( ( template: string, parameters: DnsRecord, options?: RequestOptions ): Promise<Response<StoreDnsTemplateResponse>> => {
        return postRequest<StoreDnsTemplateResponse>( `dns/templates/${template}/record`, parameters, {}, options )
    }, [postRequest] )

    /**
     * Request to retrieve DNS templates.
     */
    const dnsTemplatesRequest = useCallback( ( options?: RequestOptions ): Promise<Response<DnsTemplate[]>> => {
        return getRequest<{ data: DnsTemplate[] }>( "dns/templates", {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    /**
     * Request to retrieve a single DNS template.
     */
    const fetchDnsTemplateRequest = useCallback( ( template: string, options?: RequestOptions ): Promise<Response<DnsTemplate>> => {
        return getRequest< { data: DnsTemplate } >( `dns/templates/${template}`, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )


    /**
     * Request to retrieve available uncoupled domains.
     */
    const fetchAvailableDomainsRequest = useCallback( ( template: string, options?: RequestOptions ): Promise<Response<AvailableDomainsResponse>> => {
        return getRequest<AvailableDomainsResponse>( `dns/templates/${template}/available_domains`, {}, options )
    }, [getRequest] )

    const linkDomainsToTemplateRequest = useCallback( ( templateId: string, domains: string[], options?: RequestOptions ): Promise<Response<{ message: string}>> => {
        const payload = { domains: domains.map( domain => ( { domain } ) ) }
        return postRequest<{ message: string}>( `dns/templates/${templateId}/link`, payload, {}, options )
    }, [postRequest] )
    const unlinkDomainsFromTemplateRequest = useCallback( ( templateId: string, domains: string[], options?: RequestOptions ): Promise<Response<{ message: string}>> => {
        const payload = { domains: domains.map( domain => ( { domain } ) ) }
        return postRequest<{ message: string}>( `dns/templates/${templateId}/unlink`, payload, {}, options )
    }, [postRequest] )

    return {
        storeDnsTemplateRecordRequest,
        storeDnsTemplateRequest,
        dnsTemplatesRequest,
        createDnsZoneRequest,
        deleteDnsRecordRequest,
        updateDnsRecordRequest,
        dnsRequest,
        storeDnsRecordRequest,
        updateDnsTemplateRequest,
        fetchDnsTemplateRequest,
        fetchAvailableDomainsRequest,
        linkDomainsToTemplateRequest,
        unlinkDomainsFromTemplateRequest,
    }
}
