import { useTranslations } from "modules/translations"
import useConfig from "next/config"
import React, { ReactNode } from "react"
import PanelAction from "ui-kit/data-display/panel-action"
import LinkButton from "ui-kit/elements/link-button"
import Spinner from "ui-kit/elements/spinner"
import PageHeading from "ui-kit/headings/page-heading"
import Panel from "ui-kit/layout/panel"
import Tabs from "ui-kit/navigation/tabs"

interface TabPageContainerProps {
    domain: string
    tab: string
    loading?: boolean
    showDangerZone?: boolean
    children: ReactNode
}

function TabPageContainer ( props: TabPageContainerProps ): JSX.Element {

    const { publicRuntimeConfig } = useConfig() as { publicRuntimeConfig: { domainTabs: Array<[string, string]> } }
    const { translate } = useTranslations()
    const toPath = ( tab: string ): string => `/services/${props.domain}/${tab}`

    const current = toPath( props.tab )
    const tabs = publicRuntimeConfig.domainTabs.map( ( tab: [string, string] ): [string, string] => [ toPath( tab[0] ), translate( tab[1] ) ] )

    const loadingState = <div className="flex flex-row items-center justify-center h-32">
        <Spinner className="text-3xl text-brand-500" />
    </div>

    return <>
        <PageHeading text={props.domain} />
        <Panel>
            <div className="flex flex-row mb-5 sm:-mx-6">
                <div className="border-b border-gray-200 sm:w-6"></div>
                <Tabs items={tabs} currentPath={current} className="flex-1 sm:-mt-5" />
            </div>
            {props.loading ? loadingState : props.children}
        </Panel>

        {props.showDangerZone && <>
            <Panel title={translate( "general.tabs.danger_zone" )}>
                <PanelAction description={translate( "pages.domain.discontinue.discontinue_or_transfer" )} variant="grey" style="outline">
                    <LinkButton variant="danger" href={`/services/${props.domain}/discontinue`}>
                        {translate( "pages.domain.discontinue.discontinue_or_transfer" )}
                    </LinkButton>
                </PanelAction>
            </Panel>
        </>}
    </>
}

export { TabPageContainer }
