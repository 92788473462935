import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.domain.ssl.download.download": {
        "en-US": "Download",
        "nl-NL": "Download",
    },
    "pages.domain.ssl.download.certificate": {
        "en-US": "Certificate (.crt)",
        "nl-NL": "Certificaat (.crt)",
    },
    "pages.domain.ssl.download.private-key": {
        "en-US": "Private key (.key)",
        "nl-NL": "Private key (.key)",
    },
    "pages.domain.ssl.download.intermediate": {
        "en-US": "Intermediate (.intermediate)",
        "nl-NL": "Intermediate (.intermediate)",
    },
    "pages.domain.ssl.download.root": {
        "en-US": "Root (.root)",
        "nl-NL": "Root (.root)",
    },
    "pages.domain.ssl.download.title": {
        "en-US": "Download SSL certificate",
        "nl-NL": "Download SSL certificaat",
    },
} as TranslationDictionary
