import { Transition } from "@headlessui/react"
import { Check, ExclamationTriangle } from "icons/font-awesome/regular"
import { KeyboardEvent, ReactNode, useCallback, useEffect, useState } from "react"
import Button, { ButtonVariant } from "ui-kit/elements/button"

export type ModalType = "info" | "danger"

export interface ModalProps {
    /**
     * Style of modal to show
     */
    type: ModalType

    /**
     * Function to call after the notification is closed (leave animation finished)
     */
    onConfirm?: () => void

    /**
     * Function to call after the notification is closed (leave animation finished)
     */
    onDismiss?: () => void

    /**
     * Text used for confirmation button.
     */
    confirmText: string

    /**
     * Text used for dismiss button.
     */
    dismissText: string

    /**
     * Modal title
     */
    title: string

    /**
     * Contents of the notification
     */
    children: ReactNode
}

export default function Modal ( props: ModalProps ): JSX.Element {
    const [open, setOpen] = useState( false )

    /**
     * Close the modal as confirmation.
     */
    const confirm = useCallback( (): void => {
        setOpen( false )
        if ( props.onConfirm ) {
            setTimeout( () => props.onConfirm!(), 100 )
        }
    }, [setOpen, props.onConfirm] )

    /**
     * Close the modal as confirmation.
     */
    const dismiss = useCallback( (): void => {
        setOpen( false )
        if ( props.onDismiss ) {
            setTimeout( () => props.onDismiss!(), 100 )
        }
    }, [setOpen, props.onDismiss] )

    /**
     * To ensure the enter animation plays, only start showing the item
     * after the initial render.
     */
    useEffect( () => {
        setTimeout( () => setOpen( true ) )
    } )

    function keyDownHandler ( event: KeyboardEvent ): void {
        /**
         * Official keys are defined in the DOM Level 3 Event Spec
         * @see https://www.w3.org/TR/uievents-key/#named-key-attribute-values
         */
        const confirmKeys = ["Enter"]
        const dismissKeys = ["Escape"]

        if ( confirmKeys.includes( event.key ) ) {
            confirm()
        } else if ( dismissKeys.includes( event.key ) ) {
            dismiss()
        }
    }

    let icon: JSX.Element
    let buttonVariant: ButtonVariant
    let iconBackground: string
    switch ( props.type ) {
        case "info":
            icon = <Check className="h-4 w-4 text-brand-500" />
            buttonVariant = "primary"
            iconBackground = "bg-brand-100"
            break
        case "danger":
            icon = <ExclamationTriangle className="h-4 w-4 text-red-600" />
            buttonVariant = "danger"
            iconBackground = "bg-red-100"
            break
    }

    return <>
        <Transition
            show={open}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            {
                ( ref ) =>
                    <div ref={ref} className="fixed inset-0 transition-opacity" onClick={() => dismiss()} onKeyDown={keyDownHandler} role="document" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
            }
        </Transition>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

        <Transition
            show={open}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            {
                ( ref ) =>
                    <div ref={ref} className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        <div className="sm:flex sm:items-start">
                            <div className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 ${iconBackground}`}>
                                {icon}
                            </div>
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                    {props.title}
                                </h3>
                                <div className="mt-2">
                                    <p className="text-sm leading-5 text-gray-500">
                                        {props.children}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                            <span className="flex w-full rounded-md shadow-sm sm:w-auto">
                                <Button variant={buttonVariant} onClick={() => confirm()}>
                                    {props.confirmText}
                                </Button>
                            </span>
                            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto">
                                <Button variant="white" onClick={() => dismiss()}>
                                    {props.dismissText}
                                </Button>
                            </span>
                        </div>
                    </div>

            }
        </Transition>
    </>
}
