import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.transfers.introduction": {
        "en-US": "You can transfer your subscriptions to other customers. When transferring subscriptions, the receiving customer must also accept the transfer on their end. Only then, the subscriptions can be transferred.",
        "nl-NL": "Je kunt jouw abonnementen overdragen aan andere klanten. Bij een overdracht dient de ontvangende klant akkoord te gaan. Hierna wordt de overdracht gestart.",
    },
    "pages.transfers.no_subscriptions": {
        "en-US": "No subscriptions",
        "nl-NL": "Geen abonnementen",
    },
    "pages.transfers.start": {
        "en-US": "Start transfer",
        "nl-NL": "Start overdracht",
    },
    "pages.transfers.products": {
        "en-US": "Products",
        "nl-NL": "Producten",
    },
    "pages.transfers.cta": {
        "en-US": "Select the subscriptions you want to transfer, tell us where to, then start the proces.",
        "nl-NL": "Selecteer de abonnementen die je wilt overdragen, vertel ons waar heen, en start het proces.",
    },
    "pages.transfers.details_title": {
        "en-US": "Transfer to customer {customer_number}",
        "nl-NL": "Overdracht naar klant {customer_number}",
    },
    "pages.transfers.details_subtitle": {
        "en-US": "Started on {start_date}",
        "nl-NL": "Gestart op {start_date}",
    },
    "pages.transfers.details_step_requested": {
        "en-US": "Request",
        "nl-NL": "Aanvragen",
    },
    "pages.transfers.details_step_requested_done": {
        "en-US": "Requested",
        "nl-NL": "Aangevraagd",
    },
    "pages.transfers.details_step_requested_description": {
        "en-US": "Request a new transfer",
        "nl-NL": "Start een nieuwe overdracht",
    },
    "pages.transfers.details_step_accepted": {
        "en-US": "Wait for confermation",
        "nl-NL": "Wacht op bevestiging",
    },
    "pages.transfers.details_step_accepted_done": {
        "en-US": "Accepted",
        "nl-NL": "Geaccepteerd",
    },
    "pages.transfers.details_step_accepted_description": {
        "en-US": "The transfer must be approved by the receiving party.",
        "nl-NL": "De overdracht dient bevestigd te worden door de ontvangende partij.",
    },
    "pages.transfers.details_step_started": {
        "en-US": "Start transfer",
        "nl-NL": "Start transfer",
    },
    "pages.transfers.details_step_started_done": {
        "en-US": "Started",
        "nl-NL": "Gestart",
    },
    "pages.transfers.details_step_started_description": {
        "en-US": "We have started processing the transfer.",
        "nl-NL": "Wij zijn gestart met het uitvoeren van de overdracht.",
    },
    "pages.transfers.details_step_completed": {
        "en-US": "Finishing",
        "nl-NL": "Afronden",
    },
    "pages.transfers.details_step_completed_done": {
        "en-US": "Finished",
        "nl-NL": "Afgerond",
    },
    "pages.transfers.details_step_completed_description": {
        "en-US": "The transfer is completed, the subscriptions have been moved to the relevant customer.",
        "nl-NL": "De overdracht is afgerond, de abonnementen staan onder de relevante klant.",
    },
    "pages.transfers.details_pending": {
        "en-US": "Pending..",
        "nl-NL": "In afwachting..",
    },
    "pages.transfers.index.rejected": {
        "en-US": "Rejected",
        "nl-NL": "Afgekeurd",
    },
    "pages.transfers.index.completed": {
        "en-US": "Completed",
        "nl-NL": "Afgerond",
    },
    "pages.transfers.index.canceled": {
        "en-US": "Canceled",
        "nl-NL": "Geannuleerd",
    },
    "pages.transfers.index.failed": {
        "en-US": "Something went wrong",
        "nl-NL": "Er is iets verkeerd gegaan",
    },
    "pages.transfers.index.accepted": {
        "en-US": "Accepted",
        "nl-NL": "Bevestigd",
    },
    "pages.transfers.index.started": {
        "en-US": "Transfering..",
        "nl-NL": "Overdracht bezig..",
    },
    "pages.transfers.index.requested": {
        "en-US": "Requested",
        "nl-NL": "In afwachting",
    },
    "pages.transfers.type_incoming": {
        "en-US": "Incoming",
        "nl-NL": "Inkomend",
    },
    "pages.transfers.type_outgoing": {
        "en-US": "Outgoing",
        "nl-NL": "Uitgaand",
    },
    "pages.transfers.details_title.outgoing": {
        "en-US": "Incoming transfer from customer {customer_number}",
        "nl-NL": "Inkomende overdracht van klant {customer_number}",
    },
    "pages.transfers.old.transfers": {
        "en-US": "Closed transfers",
        "nl-NL": "Afgesloten overdrachten",
    },
    "pages.transfers.old.introduction": {
        "en-US": "Here you can see all your closed transfers.",
        "nl-NL": "Hier kunt u al uw gesloten overdrachten zien.",
    },
    "pages.transfers.accept": {
        "en-US": "Accept transfer",
        "nl-NL": "Accepteer overdracht",
    },
    "pages.transfers.cancel": {
        "en-US": "Cancel transfer",
        "nl-NL": "Annuleer overdracht",
    },
    "pages.transfers.actions.accepted": {
        "en-US": "Transfer accepted",
        "nl-NL": "Overdracht geaccepteerd",
    },
    "pages.transfers.actions.cancelled": {
        "en-US": "Transfer cancelled",
        "nl-NL": "Overdracht geannuleerd",
    },
    "pages.transfers.actions.cancel_failed": {
        "en-US": "Failure when cancelling the transfer",
        "nl-NL": "Fout bij annuleren van de overdracht",
    },
    "pages.transfers.reject": {
        "en-US": "Reject transfer",
        "nl-NL": "Weiger overdracht",
    },
    "pages.transfers.reject.modal_title": {
        "en-US": "Reject transfer",
        "nl-NL": "Overdracht weigeren",
    },
    "pages.transfers.reject.modal_rejection_button": {
        "en-US": "Reject",
        "nl-NL": "Weiger",
    },
    "pages.transfers.reject.modal_rejection" : {
        "en-US": "Are you sure you want to reject the transfer?",
        "nl-NL": "Weet u zeker dat u de overdracht wilt weigeren?",
    },
    "pages.transfers.actions.rejected": {
        "en-US": "Transfer rejected",
        "nl-NL": "Overdracht geweigerd",
    },
    "pages.transfers.actions.reject_failed": {
        "en-US": "Failure when rejecting transfer",
        "nl-NL": "Fout bij weigeren overdracht",
    },
    "pages.transfers.subscriptions.header.domain": {
        "en-US": "Domain",
        "nl-NL": "Domein",
    },
    "pages.transfers.subscriptions.header.product_group": {
        "en-US": "Product group",
        "nl-NL": "Productgroep",
    },
    "pages.transfers.subscriptions.header.product_name": {
        "en-US": "Product name",
        "nl-NL": "Productnaam",
    },
    "pages.transfers.subscriptions.header.price": {
        "en-US": "Price",
        "nl-NL": "Prijs",
    },
    "pages.transfers.subscriptions.total": {
        "en-US": "Total",
        "nl-NL": "Totaal",
    },
    "pages.transfers.modal.title": {
        "en-US": "Confirm transfer",
        "nl-NL": "Overdracht bevestigen",
    },
    "pages.transfers.modal.confirmation" : {
        "en-US": "Are you sure you want to confirm the transfer?",
        "nl-NL": "Weet u zeker dat u de overdracht wilt bevestigen?",
    },
    "pages.transfers.modal.confirmation-button": {
        "en-US": "Confirm transfer",
        "nl-NL": "Bevestig overdracht",
    },
    "pages.transfers.modal.cancel-button": {
        "en-US": "Close",
        "nl-NL": "Sluiten",
    },
    "pages.transfers.cancel.modal.title": {
        "en-US": "Cancel transfer",
        "nl-NL": "Overdracht annuleren",
    },
    "pages.transfers.cancel.modal.cancellation" : {
        "en-US": "Are you sure you want to confirm the transfer?",
        "nl-NL": "Weet u het zeker dat u de overdracht wilt annuleren?",
    },
    "pages.transfers.cancel.modal.cancellation-button": {
        "en-US": "Cancel transfer",
        "nl-NL": "Annuleer overdracht",
    },
    "pages.transfers.details.title": {
        "en-US": "Transfer details",
        "nl-NL": "Details van overdracht",
    },
    "pages.transfers.details.direction": {
        "en-US": "Direction",
        "nl-NL": "Richting",
    },
    "pages.transfers.details.status": {
        "en-US": "Latest status update",
        "nl-NL": "Laatste status update",
    },
    "pages.transfers.details.direction_description_incoming": {
        "en-US": "From customer {from} to you.",
        "nl-NL": "Van klant {from} naar jou.",
    },
    "pages.transfers.details.direction_description_outgoing": {
        "en-US": "From you to customer {to}.",
        "nl-NL": "Van jou naar klant {to}",
    },
    "pages.transfers.details.table_name": {
        "en-US": "Name",
        "nl-NL": "Naam",
    },
    "pages.transfers.details.table_product": {
        "en-US": "Product name",
        "nl-NL": "Productnaam",
    },
    "pages.transfers.details.table_price": {
        "en-US": "Price",
        "nl-NL": "Prijs",
    },
    "pages.transfers.details.table_enddate": {
        "en-US": "Enddate",
        "nl-NL": "Einddatum",
    },
    "pages.transfers.details.price_per_month": {
        "en-US": "{price} / {months} months",
        "nl-NL": "{price} / {months} maanden",
    },
    "pages.transfers.details.enddate_active": {
        "en-US": "{date} (renewal)",
        "nl-NL": "{date} (verlenging)",
    },
    "pages.transfers.details.enddate_inactive": {
        "en-US": "{date} (expires)",
        "nl-NL": "{date} (verloopt)",
    },

    "pages.transfers.create.title": {
        "en-US": "New transfer",
        "nl-NL": "Nieuwe overdracht",
    },
    "pages.transfers.create.customer_number": {
        "en-US": "Customer number",
        "nl-NL": "Klantnummer",
    },
    "pages.transfers.create.email": {
        "en-US": "Email",
        "nl-NL": "E-mail",
    },
    "pages.transfers.create.subscriptions": {
        "en-US": "Select subscriptions to transfer",
        "nl-NL": "Selecteer abonnementen om over te dragen",
    },
    "pages.transfers.create.date_expires_on": {
        "en-US": "Expires on:",
        "nl-NL": "Verloopt op:",
    },
    "pages.transfers.create.date_renews_on": {
        "en-US": "Gets renewed on:",
        "nl-NL": "Wordt verlengt op:",
    },
    "pages.transfers.create.discontinued": {
        "en-US": "Discontinued",
        "nl-NL": "Opgezegd",
    },
    "pages.services.in_transfer": {
        "en-US": "In transfer",
        "nl-NL": "In overdracht",
    },
    "pages.services.in_transfer_description": {
        "en-US": "This product is in transfer, this means that you cannot make any changes to it.",
        "nl-NL": "Dit product wordt overgedragen, dit betekent dat je geen wijzigingen kunt maken aan het product.",
    },

} as TranslationDictionary
