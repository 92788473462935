import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.reseller.index.reseller": {
        "en-US": "Reseller status",
        "nl-NL": "Reseller status",
    },
    "pages.reseller.index.account_status": {
        "en-US": "Account status",
        "nl-NL": "Account status",
    },
    "pages.reseller.index.costs": {
        "en-US": "As a reseller, you have advantageous domain name rates. The cost for this package is {value} per month.",
        "nl-NL": "Als reseller heeft u voordelige domeinnaam tarieven. De kosten voor dit pakket zijn {value} per maand.",
    },
    "pages.reseller.index.upgrade_date": {
        "en-US": "Upgrade date",
        "nl-NL": "Datum van upgrade",
    },
    "pages.reseller.index.next_invoice": {
        "en-US": "Next invoice",
        "nl-NL": "Volgende facturering",
    },
    "pages.reseller.index.subscription_end_date": {
        "en-US": "Subscription end date",
        "nl-NL": "Abbonement einddatum",
    },
    "pages.reseller.index.subscription_canceled": {
        "en-US": "Canceled",
        "nl-NL": "Opgezegd",
    },
    "pages.reseller.index.cancel_reseller": {
        "en-US": "Cancel reseller",
        "nl-NL": "Reseller opzeggen",
    },
    "pages.reseller.index.cancel_confirmation_text": {
        "en-US": "Are you sure you want to terminate the reseller program?",
        "nl-NL": "Weet u zeker dat het reseller programma wilt opzeggen?",
    },
    "pages.reseller.index.terminate": {
        "en-US": "Terminate",
        "nl-NL": "Opzeggen",
    },
    "pages.reseller.become_a_reseller": {
        "en-US": "Become a reseller",
        "nl-NL": "Word een reseller",
    },
    "pages.reseller.call-to-action.description": {
        "en-US": "You are not a reseller yet!",
        "nl-NL": "U bent nog geen reseller!",
    },
    "pages.reseller.paragraph-one-title": {
        "en-US": "Domain discounts",
        "nl-NL": "Domein kortingen",
    },
    "pages.reseller.paragraph-one": {
        "en-US": "As a Reseller for domainnames you get lucrative domain name prices. For {price} per month you’ll only need a handful of customers to make this profitable for you.",
        "nl-NL": "Als domeinnaam Reseller neemt u bij ons domeinnamen af tegen een scherp tarief. Voor slechts {price} per maand heeft u maar een handvol klanten nodig om dit rendabel te maken.",
    },
} as TranslationDictionary
