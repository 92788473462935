import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.domain-contact.details.page-header": {
        "en-US": "Manage contact",
        "nl-NL": "Domeinhouder",
    },
    "pages.domain-contact.details.page-header-named": {
        "en-US": "Domain contact: {first_name} {last_name}",
        "nl-NL": "Domeinhouder: {first_name} {last_name}",
    },
    "pages.domain-contact.details.configure-domain-contact": {
        "en-US": "Manage domain contact",
        "nl-NL": "Domeinhouder beheren",
    },
    "pages.domain-contact.details.attached-domain-contacts": {
        "en-US": "Linked domains",
        "nl-NL": "Gekoppelde domeinen",
    },
    "pages.domain-contact.details.modal.title":{
        "en-US": "Delete domain contact",
        "nl-NL": "Domein houder verwijderen",
    },
    "pages.domain-contact.details.modal.confirmation":{
        "en-US": "Are you sure you want to delete this? This action cannot be undone.",
        "nl-NL": "Weet u zeker dat u dit wilt verwijderen? Dit kan niet worden teruggedraaid.",
    },
    "pages.domain-contact.details.modal.confirmation-button":{
        "en-US": "Delete contact",
        "nl-NL": "Verwijder houder",
    },
    "pages.domain-contact.details.modal.cancel-button":{
        "en-US": "Cancel",
        "nl-NL": "Annuleren",
    },
    "pages.domain-contact.details.attached-domains-sub-title": {
        "en-US": "Domains that are coupled to this domain contact",
        "nl-NL": "Domeinen die gekoppeld zijn aan deze domein houder",
    },
    "pages.domain-contact.details.no-coupled-domains": {
        "en-US": "This domain contact has no coupled domains.",
        "nl-NL": "Er zijn geen domeinen gekoppeld aan deze domein houder.",
    },
    "pages.domain-contact.details.description": {
        "en-US": "When you link your contact to your domain, your whois information will be kept up-to-date with the changes in the domain contact. If you delete the contact, the whois information will not be modified.",
        "nl-NL": "Wanneer je een domeinhouder koppelt aan een domein, dan zullen de whois gegevens up to date gehouden worden met de veranderingen in de domeinhouder. Bij het verwijderen van een domeinhouder zullen de whois gegevens niet aangepast worden.",
    },
    "pages.domain-contact.details.couple-button": {
        "en-US": "Link domains",
        "nl-NL": "Koppel domeinen",
    },
} as TranslationDictionary
