import { Response, useServicesApi } from "modules/api-client"
import { Service, ServiceDetails } from "modules/api-client/src/services/types"
import { useAuth } from "modules/auth"
import { useGlobalSearchData } from "modules/cached-data/src/global-search-data/use-global-search-data"
import React, { createContext, useCallback, useEffect, useState } from "react"
import { ListItem } from "ui-kit/lists/stacked-list"

export interface ServicesDataContextType {
    services?: Service[]
    isLoading: boolean
    refresh(): void
    serviceByDomainAndType( domain: string, type: string ): ServiceDetails | undefined
}

export const ServicesDataContext = createContext<ServicesDataContextType>( {} as ServicesDataContextType )

interface ServicesDataContextProps {
    children: React.ReactNode
}

export function ServicesDataContextProvider ( { children }: ServicesDataContextProps ): JSX.Element {

    /**
     * Index related states
     */
    const { servicesRequest, serviceDetailsRequest } = useServicesApi()
    const { customer } = useAuth()

    const [isLoading, setIsLoading] = useState( false )
    const [services, setServices] = useState<Service[]>( [] )
    const { removeBlock, addBlock } = useGlobalSearchData()

    const fetchResource = useCallback( () => {
        setIsLoading( true )
        servicesRequest().then( ( response: Response<Service[]> ): void => {
            if ( response.success ) {
                setServices( response.data! )
            }
            setIsLoading( false )

            const searchItems: ListItem[] = response.data?.map( ( service ) => {
                return {
                    title: service.domain,
                    description: service.type,
                    location: `/services/${service.domain}/dns`,
                }
            } ) || []

            removeBlock( "services" )
            addBlock( { identifier: "services", items: searchItems } )
        } )
    }, [servicesRequest, addBlock, removeBlock] )

    /**
     * Fetch resource, also reload if customer changed.
     */
    useEffect( () => {
        if ( !isLoading ) {
            fetchResource()
        }
        // We ignore the isLoading dependency, as we do not want to
        // fetch if just that has changed.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer, fetchResource] )

    /**
     * Details related states
     */
    const [getDetails, setDetails] = useMapState<number, ServiceDetails>()
    const [getLoadingDetails, setLoadingDetails, loadingDetailsMap] = useMapState<number, boolean>()

    /**
     * Filter through the fetched list of services, resolve a service based on domain and type.
     */
    const serviceByDomainAndType = useCallback( ( domain: string, type: string ): ServiceDetails | undefined => {
        if ( ! services ) {
            return
        }
        const service = services.find( ( service: Service ): boolean => service.type === type && service.domain === domain )
        if ( ! service ) {
            return
        }
        const details = getDetails( service.id )
        const detailsLoading = getLoadingDetails( service.id, false )

        if ( typeof details !== "undefined" ) {
            return details
        }

        if ( ! details && ! detailsLoading ) {
            setLoadingDetails( service.id, true )
            serviceDetailsRequest( service.id ).then( response => {
                if ( response.success ) {
                    setDetails( service.id, response.data! )
                }
                setLoadingDetails( service.id, false )
            } )
        }

    }, [services, getDetails, getLoadingDetails, setDetails, setLoadingDetails, serviceDetailsRequest] )

    /**
     * If any of the loading states is true, either the main one, or any in the loading map, the context loading state
     * will be true.
     */
    const isLoadingAnything = Object.values( loadingDetailsMap ).reduce( ( previous, current ) => previous || current, isLoading )

    return (
        <ServicesDataContext.Provider
            value={{
                services,
                isLoading: isLoadingAnything,
                refresh: () => fetchResource(),
                serviceByDomainAndType,
            }}>
            {children}
        </ServicesDataContext.Provider>
    )
}

function useMapState<K extends string|number, T> (): [( key: K, fallback?: T ) => T | undefined, ( key: K, value: T ) => void, { [key in K]: T }] {
    type MapType = { [key in K]: T }
    const [state, setState] = useState<MapType>( {} as MapType )
    const getter = useCallback( ( key: K, fallback?: T ): T | undefined => {
        if ( typeof state[key] !== "undefined" ) {
            return state[key]
        }
        return fallback
    }, [state] )

    const setter = useCallback( ( key: K, value: T ): void => {
        setState( state => {
            const newState: MapType = { ...state }
            newState[key] = value
            return newState
        } )
    }, [setState] )

    return [getter, setter, state]
}
