import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "ui-kit.lists.interactive-table.search": {
        "en-US": "Search this table",
        "nl-NL": "Zoek in deze tabel",
    },
    "ui-kit.lists.interactive-table.sorted-ascending": {
        "en-US": "Sorted ascending",
        "nl-NL": "Oplopend gesorteerd",
    },
    "ui-kit.lists.interactive-table.sorted-descending": {
        "en-US": "Sorted descending",
        "nl-NL": "Aflopend gesorteerd",
    },

} as TranslationDictionary
