import { StoreCustomer } from "modules/api-client/src/customers/requests/store-request"
import { UpdateCustomerParams } from "modules/api-client/src/customers/requests/update-customer-params"
import { UpdateCustomerResponse } from "modules/api-client/src/customers/responses/update-customer-response"
import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { useAuth } from "modules/auth"
import { Customer } from "modules/auth/src/types"
import useConfig from "next/config"
import { useCallback } from "react"

interface CustomersApiSpec {
    customersRequest( options?: RequestOptions ): Promise<Response<Customer[]>>
    createCustomer( customer: StoreCustomer, options?: RequestOptions ): Promise<Response<Customer>>
    updateCustomerRequest ( customer: UpdateCustomerParams, options?: RequestOptions ): Promise<Response<UpdateCustomerResponse>>
    giveAccessToCustomerRequest( customerId: number, options?: RequestOptions ): Promise<Response<Customer>>
}

export function useCustomersApi (): CustomersApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, postRequest, patchRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retreive the own customers from the API.
     */
    const customersRequest = useCallback( ( options?: RequestOptions ): Promise<Response<Customer[]>> => {
        return getRequest<{data: Customer[]}>( "customers", {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    /**
     * Request to create a new customer via the API.
     */
    const createCustomer = useCallback( ( customer: StoreCustomer, options?: RequestOptions ) => {
        return postRequest<Customer>( "customers", customer, {}, options )
    }, [postRequest] )

    const updateCustomerRequest = useCallback( ( parameters: UpdateCustomerParams, options?: RequestOptions ): Promise<Response<UpdateCustomerResponse>> => {
        const customerId = parameters.uuid ?? customer?.uuid
        return patchRequest<UpdateCustomerResponse>( `customers/${customerId}`, parameters, {}, options )
    }, [patchRequest, customer?.uuid] )

    /**
     * Request to gives access to a customer.
     */
    const giveAccessToCustomerRequest = useCallback( ( customerId: number, options?: RequestOptions ): Promise<Response<Customer>> => {
        return postRequest<{data: Customer}>( `customers/${customerId}/give-access`, {}, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [postRequest] )

    return {
        customersRequest,
        createCustomer,
        updateCustomerRequest,
        giveAccessToCustomerRequest,
    }
}
