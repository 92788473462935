import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.domain.ssl.reissue.common-name": {
        "en-US": "Common name",
        "nl-NL": "Common name",
    },
    "pages.domain.ssl.reissue.country": {
        "en-US": "Country",
        "nl-NL": "Land",
    },
    "pages.domain.ssl.reissue.locality": {
        "en-US": "City",
        "nl-NL": "Stad",
    },
    "pages.domain.ssl.reissue.organization": {
        "en-US": "Organization",
        "nl-NL": "Organisatie",
    },
    "pages.domain.ssl.reissue.state": {
        "en-US": "Province/state",
        "nl-NL": "Provincie",
    },
    "pages.domain.ssl.reissue.organization-sector": {
        "en-US": "Sector",
        "nl-NL": "Branche",
    },
    "pages.domain.ssl.reissue.verify": {
        "en-US": "Verify",
        "nl-NL": "Controleer",
    },
    "pages.domain.ssl.reissue.upload": {
        "en-US": "Upload",
        "nl-NL": "Upload",
    },
    "pages.domain.ssl.reissue.title": {
        "en-US": "Upload de CSR",
        "nl-NL": "Upload the CSR",
    },
    "pages.domain.ssl.reissue.csr-field": {
        "en-US": "Fill in the CSR below",
        "nl-NL": "Voer hieronder de CSR in",
    },
    "pages.domain.ssl.reissue.success": {
        "en-US": "The certificate has been reissued.",
        "nl-NL": "Het certificaat is opnieuw aangevraagd.",
    },
    "pages.domain.ssl.reissue.explanation": {
        "en-US": "In order to reissue the SSL certificate a new certificate signing request (CSR) needs to be uploaded. The information within the CSR can be verified by clicking the verify button.",
        "nl-NL": "Om een heruitgifte van het SSL certificaat aan te vragen is een nieuw certificate signing request (CSR) nodig. De informatie binnen de CSR kan worden gecontroleerd door op de controleer knop te drukken.",
    },
} as TranslationDictionary
