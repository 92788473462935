
import { useTranslations } from "modules/translations/index"
import React, { ReactNode } from "react"
import Errors from "ui-kit/elements/errors"

/**
 * Actual implementations of FormField should extend this set of properties.
 */
export interface FormFieldBaseProps {
    /**
     * ID for this input, must be unique on the page
     */
    id: string

    /**
     * Optional name under which the value of this field gets submitted
     */
    name?: string

    /**
     * The current value of the field
     */
    value: string | undefined

    /**
     * Function to call when the value changes
     */
    onChange: ( newValue: string, id: string ) => void

    /**
     * Label for this field
     */
    label?: string

    /**
     * Optional hint text to explain the expected input to the user
     */
    hint?: string

    /**
     * Whether the input is required, default true.
     */
    required?: boolean

    /**
     * Optional single error or array of error messages to display at this field.
     * If errors are passed, the field is marked as invalid.
     */
    errors?: string | string[]

    /**
     * Optional max width for form element, defaults to "sm"
     */
    maxWidth?: "sm" | "none"

    /**
     * Additional CSS classes to add to this component
     */
    className?: string
}

export interface FormFieldProps extends FormFieldBaseProps {
    /**
     * The actual input element to project into this field.
     */
    children: ReactNode
}

/**
 * This is a base component for form fields. You should not be using this
 * directly. Instead, look at components like `<Input>` or `<Select>`.
 */
export default function FormField ( props: FormFieldProps ): JSX.Element {
    const { translate } = useTranslations()
    const optional = props.required === false
    const maxWidthClass: string = props.maxWidth === "none" ? "" : "max-w-sm"

    return (
        <div className={`w-full ${maxWidthClass} ${props.className || ""}`} data-testid="form-field">
            {props.label && <label htmlFor={props.id} className="flex justify-between text-sm font-medium leading-5 text-gray-700">
                <span>{`${props.label}:`}</span>
                {optional && <span className="text-gray-500">{translate( "ui-kit.forms.form-field.optional" )}</span>}
            </label>}

            {props.hint && <p className="mt-1 text-sm text-gray-500">{props.hint}</p>}

            <Errors id={`${props.id}_errors`} errors={props.errors} />

            {props.children}
        </div>
    )
}
