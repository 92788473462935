import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "general.units.year": {
        "en-US": "year",
        "nl-NL": "jaar",
    },
    "general.units.years": {
        "en-US": "years",
        "nl-NL": "jaren",
    },
    "general.units.month": {
        "en-US": "month",
        "nl-NL": "maand",
    },
    "general.units.months": {
        "en-US": "months",
        "nl-NL": "maanden",
    },
    "general.units.mb": {
        "en-US": "MB",
        "nl-NL": "MB",
    },
} as TranslationDictionary
