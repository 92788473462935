
import { Server } from "modules/api-client/src/servers/types"
import { can } from "modules/helpers"
import { CartItem, useCart } from "modules/orders"
import { useTranslations } from "modules/translations"
import React, { useCallback, useState } from "react"
import Select from "ui-kit/forms/select"

export function ConfigureServer ( { domain, item, servers }: {domain: string, item: CartItem, servers: Server[]} ): JSX.Element {
    const [server, setServer] = useState<string>()
    const { translate } = useTranslations()
    const { updateItem } = useCart()

    const changeServer = useCallback( ( server: string ) => {
        setServer( server )
        const newItem: CartItem = {
            ...item,
            product: {
                ...item.product,
                config: {
                    ...item.product.config,
                    server: server,
                },
            },
        }
        updateItem( domain, newItem )
    }, [domain, updateItem, item] )

    const serverOptions = Object.values( servers )
        .filter( server => can( server, "addNewSites" ) )
        .map( server => [String( server.id ), server.name ? `${server.name} (${server.hostname})` : server.hostname] )
    serverOptions.unshift( ["0", translate( "pages.order.shared-server" )] )

    return <div className="mb-8">
        <Select
            id="select-server"
            name="select-server"
            label={translate( "pages.order.server-select" )}
            onChange={( newValue ) => changeServer( newValue )}
            options={serverOptions as unknown as ReadonlyArray<[string, string]>}
            value={server}
        />
    </div>
}
