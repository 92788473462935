import { Check, PlusOctagon } from "icons/font-awesome/light"
import React, { Children, ReactElement, ReactNode } from "react"

export interface ProductGridItemProps {

    /**
     * Title for this product
     */
    title: string

    /**
     * A short (10-20 word) description of this product
     */
    description: string

    /**
     * An optional list of specs to list for this product
     */
    specs?: string[]

    /**
     * Optional title to show above specs list for this product
     */
    specsTitle?: string

    /**
     * The formatted price for this product
     */
    price: string

    /**
     * The period for which this product is bought
     */
    period: string

    /**
     * A button with which to buy this product
     */
    buyButton: ReactElement

    /**
     * Additional CSS classes to add to this product card.
     */
    className?: string

    /**
     * Upsell text to upsell a different product
     */
    upSellText?: string
}

/**
 * A product card for use in a ProductGrid.
 */
export function ProductGridItem ( props: ProductGridItemProps ): JSX.Element {
    return (
        <div className={`${props.className || ""} border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200`}>
            <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">{props.title}</h2>
                <p className="mt-4 text-sm text-gray-500" style={{ minHeight: "3rem" }}>{props.description}</p>
                <p className="my-8">
                    <span className="text-4xl font-extrabold text-gray-900">{props.price}</span>
                    <span className="text-base font-medium text-gray-500">
                        <span>/</span>
                        <span>{props.period}</span>
                    </span>
                </p>
                {props.buyButton}
            </div>

            {props.specs && <div className="pt-6 pb-8 px-6">
                {props.specsTitle && <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">{props.specsTitle}</h3>}
                <ul className="mt-6 space-y-4">
                    {props.specs.map( spec => <li className="flex space-x-3" key={spec}>
                        <Check className="text-green-500" />
                        <span className="text-sm text-gray-500">{spec}</span>
                    </li> )}
                </ul>
            </div>}
            {!! props.upSellText && <div className=" flex flex-row justify-between text-white text-sm py-2 pl-4 mt-4 bg-brand-500 rounded-b-lg">
                <span className="mx-6 my-1 font-semibold">
                    <PlusOctagon className="mr-2"/>
                    {props.upSellText}
                </span>
            </div>}
        </div>
    )
}

export interface ProductGridProps {
    /**
     * Only pass in ProductGridItem instances
     */
    children: ReactNode

    /**
     * Optionally limit number of product cards per row
     */
    maxPerRow?: 1 | 2 | 3 | 4 | 5

    /**
     * Additional CSS classes to add to this product grid.
     */
    className?: string
}

/**
 * Displays one or more products as cards in a flexible grid. This will show as
 * many product cards horizontally as will fit on the screen, though you can
 * also limit how many cards can be shown per row.
 */
export default function ProductGrid ( props: ProductGridProps ): JSX.Element {
    const maxPerRow = Math.min( Children.count( props.children ), props.maxPerRow ?? 5 )
    let layoutClasses = "space-y-4"
    if ( maxPerRow > 1 ) {
        layoutClasses += " sm:space-y-0 sm:grid sm:gap-6 sm:grid-cols-2"
    }
    if ( maxPerRow > 2 ) {
        layoutClasses += " md:grid-cols-3"
    }
    if ( maxPerRow > 3 ) {
        layoutClasses += " lg:grid-cols-4"
    }
    if ( maxPerRow > 4 ) {
        layoutClasses += " xl:grid-cols-5"
    }
    return (
        <div className={`${props.className || ""} ${layoutClasses}`}>
            {props.children}
        </div>
    )
}
