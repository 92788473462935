import { CartItem, useCart } from "modules/orders"
import { useTranslations } from "modules/translations"
import React, { useState } from "react"
import Input from "ui-kit/forms/input"

export function ConfigureTransferCode ( { domain, item }: {domain: string, item: CartItem} ): JSX.Element {
    const [transferCode, setTransferCodeState] = useState( "" )
    const { translate } = useTranslations()
    const { updateItem } = useCart()

    function setTransferCode ( newState: string ): void {
        setTransferCodeState( newState )
        const newItem: CartItem = {
            ...item,
            product: {
                ...item.product,
                config: {
                    ...item.product.config,
                    transfer_secret: newState,
                },
            },
        }
        updateItem( domain, newItem )
    }

    return <div className="mb-8">
        <Input
            id="transfercode"
            name="transfercode"
            label={translate( "pages.order.transfer_code" )}
            onChange={( newValue ) => setTransferCode( newValue )}
            value={transferCode}
            className="pr-1"
        />
    </div>
}
