import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "general.tabs.dns": {
        "en-US": "DNS",
        "nl-NL": "DNS",
    },
    "general.tabs.domain": {
        "en-US": "Domainname",
        "nl-NL": "Domeinnaam",
    },
    "general.tabs.hosting": {
        "en-US": "Hosting & email",
        "nl-NL": "Hosting & e-mail",
    },
    "general.tabs.ssl": {
        "en-US": "SSL Protection",
        "nl-NL": "SSL Beveiliging",
    },
    "general.tabs.danger_zone": {
        "en-US": "Danger zone",
        "nl-NL": "Gevarenzone",
    },

} as TranslationDictionary
