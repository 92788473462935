import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { DeleteUserParams } from "modules/api-client/src/user/requests/delete-user-params"
import { StoreUser } from "modules/api-client/src/user/requests/store-user-request"
import { UpdateUserParams } from "modules/api-client/src/user/requests/update-user-params"
import { DeleteUserResponse } from "modules/api-client/src/user/responses/delete-user-response"
import { UpdateUserResponse } from "modules/api-client/src/user/responses/update-user-response"
import { useAuth, User } from "modules/auth"
import { UserRole } from "modules/auth/src/types"
import useConfig from "next/config"
import { useCallback } from "react"

interface UserApiSpec {
    currentUserRequest(): Promise<Response<User>>
    usersRequest( options?: RequestOptions ): Promise<Response<User[]>>
    deleteUserRequest( parameters: DeleteUserParams, options?: RequestOptions ): Promise<Response<DeleteUserResponse>>
    createUserRequest( user: StoreUser, options?: RequestOptions ): Promise<Response<User>>
    userRolesRequest( options?: RequestOptions ): Promise<Response<UserRole[]>>
    updateUserRequest ( user: UpdateUserParams, options?: RequestOptions ): Promise<Response<UpdateUserResponse>>
}

export function useUserApi (): UserApiSpec {

    const { customer, user } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, deleteRequest, postRequest, patchRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retreive the current user from the API.
     */
    const currentUserRequest = useCallback( (): Promise<Response<User>> => {
        return getRequest<{ data: User }>( "users/current-user", undefined, { notifyOnError: false, logoutOnUnauthorized: false } )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const usersRequest = useCallback( ( options?: RequestOptions ): Promise<Response<User[]>> => {
        return getRequest<{ data: User[] }>( "users", {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const deleteUserRequest = useCallback( ( parameters: DeleteUserParams, options?: RequestOptions ): Promise<Response<DeleteUserResponse>> => {
        return deleteRequest<DeleteUserResponse>( `users/${parameters.userId}`, undefined, {}, options )
    }, [deleteRequest] )

    const createUserRequest = useCallback( ( user: StoreUser, options?: RequestOptions ): Promise<Response<User>> => {
        return postRequest<{ data: User }>( "users", user, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [postRequest] )

    const userRolesRequest = useCallback( ( options?: RequestOptions ) => {
        return getRequest<{ data: UserRole[] }>( "users/roles", {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    const updateUserRequest = useCallback( ( parameters: UpdateUserParams, options?: RequestOptions ): Promise<Response<UpdateUserResponse>> => {
        const userId = user?.id
        return patchRequest<UpdateUserResponse>( `users/${userId}`, parameters, {}, options )
    }, [patchRequest, user?.id] )

    return {
        currentUserRequest,
        usersRequest,
        deleteUserRequest,
        createUserRequest,
        userRolesRequest,
        updateUserRequest,
    }
}
