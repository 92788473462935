import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.dns-templates.new.page-header": {
        "en-US": "DNS templates",
        "nl-NL": "DNS templates",
    },
    "pages.dns-templates.new.create-successful": {
        "en-US": "DNS template: {value} successfully created.",
        "nl-NL": "DNS template: {value} succesvol aangemaakt.",
    },
    "pages.dns-templates.new.link-domains": {
        "en-US": "Link domains to template",
        "nl-NL": "Koppel domeinen aan template",
    },
    "pages.dns-templates.new.create-template": {
        "en-US": "Create template",
        "nl-NL": "Template maken",
    },
    "pages.dns-templates.new.template-name": {
        "en-US": "Template name",
        "nl-NL": "Template naam",
    },
    "pages.dns-templates.new.error-card-title": {
        "en-US": "We have found some errors",
        "nl-NL": "We hebben een aantal fouten ontdenkt.",
    },

} as TranslationDictionary
