import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "general.navigation.order": {
        "en-US": "Order",
        "nl-NL": "Bestellen",
    },
    "general.navigation.services": {
        "en-US": "Services",
        "nl-NL": "Beheren",
    },
    "general.navigation.subscriptions": {
        "en-US": "Subscriptions",
        "nl-NL": "Abonnementen",
    },
    "general.navigation.servers": {
        "en-US": "Servers",
        "nl-NL": "Servers",
    },
    "general.navigation.users": {
        "en-US": "Users",
        "nl-NL": "Gebruikers",
    },
    "general.navigation.customers": {
        "en-US": "Your customers",
        "nl-NL": "Eigen klanten",
    },
    "general.navigation.affiliate": {
        "en-US": "Affiliate program",
        "nl-NL": "Partnerprogramma",
    },
    "general.navigation.profile": {
        "en-US": "My profile",
        "nl-NL": "Mijn gegevens",
    },
    "general.navigation.logout":{
        "en-US": "Sign out",
        "nl-NL": "Uitloggen",
    },
    "general.navigation.customer-switch":{
        "en-US": "Switch customer",
        "nl-NL": "Switch customer",
    },
    "general.navigation.security": {
        "en-US": "Security",
        "nl-NL": "Beveiliging",
    },
    "general.navigation.contact": {
        "en-US": "Contact",
        "nl-NL": "Contact",
    },
    "general.navigation.invoices": {
        "en-US": "Invoices",
        "nl-NL": "Facturen",
    },
    "general.navigation.dns-templates": {
        "en-US": "DNS templates",
        "nl-NL": "DNS templates",
    },
    "general.navigation.domain-contact": {
        "en-US": "Domain contacts",
        "nl-NL": "Domein houders",
    },
    "general.navigation.configuration": {
        "en-US": "Configuration",
        "nl-NL": "Configuratie",
    },
    "general.navigation.transfer": {
        "en-US": "Transfers",
        "nl-NL": "Overdrachten",
    },
    "general.navigation.section.product": {
        "en-US": "My products",
        "nl-NL": "Mijn producten",
    },
    "general.navigation.section.account": {
        "en-US": "My account",
        "nl-NL": "Mijn account",
    },
    "general.navigation.category.settings": {
        "en-US": "Settings",
        "nl-NL": "Instellingen",
    },
    "general.navigation.category.products": {
        "en-US": "Products",
        "nl-NL": "Producten",
    },
    "general.navigation.category.reselling": {
        "en-US": "Reselling",
        "nl-NL": "Reselling",
    },
    "general.navigation.version": {
        "en-US": "Version {value}",
        "nl-NL": "Versie {value}",
    },
    "general.navigation.reseller": {
        "en-US": "Reseller program",
        "nl-NL": "Resellerprogramma",
    },

} as TranslationDictionary
