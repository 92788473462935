import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.dns-templates.link-domains.page-header": {
        "en-US": "Link domain names",
        "nl-NL": "Koppel domeinnamen",
    },
    "pages.dns-templates.link-domains.form-header": {
        "en-US": "Select all domains that should be linked",
        "nl-NL": "Selecteer domeinnamen om te koppelen",
    },
    "pages.dns-templates.link-domains.description": {
        "en-US": "All linked domains will be updated when the template is updated.",
        "nl-NL": "Alle gekoppelde domeinen worden geüpdate wanneer er wijzigingen worden aangebracht in de template.",
    },
    "pages.dns-templates.link-domains.save": {
        "en-US": "Save changes",
        "nl-NL": "Wijzigingen opslaan",
    },
    "pages.dns-templates.link-domains.modal.title": {
        "en-US": "Link domainnames",
        "nl-NL": "Koppel domeinnamen",
    },
    "pages.dns-templates.link-domains.modal.description": {
        "en-US": "In the process of linking the selected domainnames, the records in your DNS zones will be updated as well. Are you sure?",
        "nl-NL": "Tijdens het koppelen van de domeinnamen worden ook de records van uw DNS zones aangepast. Weet u het zeker?",
    },
    "pages.dns-templates.link-domains.modal.confirmation-button": {
        "en-US": "Yes I want to link the selected domainnames and update the corresponding DNS zones automatically",
        "nl-NL": "Ja ik wil de geselecteerde domeinnamen koppelen en de bijbehorende DNS zones automatisch laten aanpassen.",
    },

} as TranslationDictionary
