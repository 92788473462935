import { useModals } from "modules/modals"
import Modal from "ui-kit/overlays/modal"

function ModalContainer (): JSX.Element {

    const { modal, confirmModal, dismissModal } = useModals()

    let modalElement: JSX.Element | undefined

    if ( modal ) {
        modalElement = <Modal {...modal} onConfirm={() => confirmModal()} onDismiss={() => dismissModal()} />
    }

    return modalElement ?
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                {modalElement}
            </div>
        </div>
        : <></>
}

export { ModalContainer }
