import { NotificationContainer } from "modules/notifications"
import { useTranslations } from "modules/translations"
import useConfig from "next/config"
import { ReactNode } from "react"
import Logo from "ui-kit/elements/logo"

interface UnauthorizedPageContainerProps {
    children: ReactNode
}

function UnauthorizedPageContainer ( { children }: UnauthorizedPageContainerProps ): JSX.Element {
    const { coverImage } = useConfig().publicRuntimeConfig as { coverImage: string }
    const { versionNumber } = useConfig().publicRuntimeConfig as { versionNumber: string}
    const { translate } = useTranslations()
    return <>
        <NotificationContainer />
        <div className="fixed top-0 left-0 h-full w-full p-8" style={{
            backgroundImage: `url(${coverImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            zIndex: -1,
        }}><Logo /></div>
        <div className="min-h-screen flex justify-end">
            <div className="w-full md:w-1/2 xl:w-1/3 bg-white pb-24">
                <div className="bg-brand-500 p-8 block md:hidden">
                    <Logo />
                </div>
                <div className="h-full flex items-center justify-center px-8 py-16">
                    <div className="flex-1">{children}</div>
                </div>
                <div className="text-center">
                    <span className="text-xs text-gray-400 uppercase border border-gray-300 rounded-sm px-1">
                        {translate( "general.navigation.version", { value: versionNumber } )}
                    </span>
                </div>
            </div>
        </div>
    </>
}

export { UnauthorizedPageContainer }
