import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "general.product-group.extension": {
        "en-US": "Domain name",
        "nl-NL": "Domeinnaam",
    },
    "general.product-group.hosting": {
        "en-US": "Webhosting",
        "nl-NL": "Webhosting",
    },
    "general.product-group.server": {
        "en-US": "Server",
        "nl-NL": "Server",
    },
    "general.product-group.server-addon": {
        "en-US": "Server add-on",
        "nl-NL": "Server uitbreiding",
    },
    "general.product-group.ssl": {
        "en-US": "SSL",
        "nl-NL": "SSL",
    },
    "general.product-group.dns": {
        "en-US": "DNS",
        "nl-NL": "DNS",
    },
    "general.product-group.other": {
        "en-US": "Other",
        "nl-NL": "Overige",
    },
    "general.product-group.vps": {
        "en-US": "VPS",
        "nl-NL": "VPS",
    },
    "general.product-group.vps_addons": {
        "en-US": "VPS add-ons",
        "nl-NL": "VPS uitbreidingen",
    },
    "general.product-group.domein-uitbreiding": {
        "en-US": "Domain add-on",
        "nl-NL": "Domein uitbreiding",
    },
    "general.product-group.partnerprogramma": {
        "en-US": "Partnerprogram",
        "nl-NL": "Partnerprogramma",
    },
} as TranslationDictionary
