import classNames from "classnames"
import React, { ReactNode } from "react"

export interface ContainerProps {
    /**
     * The maximum size of the container. Default `normal`.
     */
    size?: "normal" | "small"

    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    children: ReactNode
}

/**
 * A simple wrapper that shows its content in the center with a maximum width.
 */
export default function Container ( props: ContainerProps ): JSX.Element {
    const classes = classNames(
        "mx-auto px-4 sm:px-6 lg:px-8",
        props.className,
        {
            "max-w-7xl": !props.size || props.size === "normal",
            "max-w-md": props.size === "small",
        } )
    return (
        <div className={classes}>
            {props.children}
        </div>
    )
}
