import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.customers.button_add_customer": {
        "en-US": "Add customer",
        "nl-NL": "Klant toevoegen",
    },
    "pages.customers.button_download_csv": {
        "en-US": "Download CSV",
        "nl-NL": "Download CSV",
    },
    "pages.customers.fetch_error": {
        "en-US": "Currently unable to retrieve the customers data. Please try again later.",
        "nl-NL": "Fout bij het ophalen van de klantgegevens. Probeer het later opnieuw.",
    },
    "pages.customers.page_heading": {
        "en-US": "Customers",
        "nl-NL": "Eigen klanten",
    },
    "pages.customers.table_header.email": {
        "en-US": "Email address",
        "nl-NL": "E-mailadres",
    },
    "pages.customers.table_header.first_name": {
        "en-US": "First name",
        "nl-NL": "Voornaam",
    },
    "pages.customers.table_header.has_user": {
        "en-US": "Access klantlogin.nl",
        "nl-NL": "Toegang klantlogin.nl",
    },
    "pages.customers.table_header.last_name": {
        "en-US": "Last name",
        "nl-NL": "Achternaam",
    },
    "pages.customers.table_header.organization": {
        "en-US": "Organization",
        "nl-NL": "Bedrijfsnaam",
    },
    "pages.customers.table.give_access": {
        "en-US": "Give access",
        "nl-NL": "Geef toegang",
    },
    "pages.customers.give_access_error": {
        "en-US": "Failed to give access",
        "nl-NL": "Toegang verlenen mislukt",
    },
    "pages.customers.give_access_succeeded": {
        "en-US": "Access given",
        "nl-NL": "Toegang verleend",
    },
    "pages.customers.update.header": {
        "en-US": "Update customer",
        "nl-NL": "Bewerk klant",
    },
} as TranslationDictionary
