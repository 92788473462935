import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    //Hosting
    "general.product-hosting.hosting_starter": {
        "en-US": "Starter",
        "nl-NL": "Starter",
    },
    "general.product-hosting.hosting_starter+": {
        "en-US": "Starter+",
        "nl-NL": "Starter+",
    },
    "general.product-hosting.hosting_premium": {
        "en-US": "Premium",
        "nl-NL": "Premium",
    },
    "general.product-hosting.hosting_premium+": {
        "en-US": "Premium+",
        "nl-NL": "Premium+",
    },
    "general.product-hosting.hosting_professional": {
        "en-US": "Professional",
        "nl-NL": "Professional",
    },
    "general.product-hosting.hosting_professional+": {
        "en-US": "Professional+",
        "nl-NL": "Professional+",
    },
    "general.product-hosting.hosting_doorsturen": {
        "en-US": "Forwarding",
        "nl-NL": "Doorsturen",
    },
    "general.product-hosting.hosting_extreme": {
        "en-US": "Extreme",
        "nl-NL": "Extreme",
    },
    "general.product-hosting.hosting_extreme+": {
        "en-US": "Extreme+",
        "nl-NL": "Extreme+",
    },
    "general.product-hosting.hosting_brons": {
        "en-US": "Brons",
        "nl-NL": "Brons",
    },
    "general.product-hosting.hosting_zilver": {
        "en-US": "Zilver",
        "nl-NL": "Zilver",
    },
    "general.product-hosting.hosting_groot": {
        "en-US": "Groot",
        "nl-NL": "Groot",
    },
    "general.product-hosting.hosting_email": {
        "en-US": "E-mail only",
        "nl-NL": "E-mail only",
    },
    "general.product-hosting.hosting_email_only": {
        "en-US": "E-mail only",
        "nl-NL": "E-mail only",
    },
    "general.product-hosting.hosting_e-mail_only": {
        "en-US": "E-mail only",
        "nl-NL": "E-mail only",
    },
    "general.product-hosting.hosting_basic": {
        "en-US": "Basic",
        "nl-NL": "Basic",
    },
    "general.product-hosting.hosting_forwarding": {
        "en-US": "Forwarding",
        "nl-NL": "Forwarding",
    },
    "general.product-hosting.hosting_mail_only": {
        "en-US": "Mail and spam",
        "nl-NL": "Mail en spam",
    },
    "general.product-hosting.hosting_sitebuilder": {
        "en-US": "Sitebuilder",
        "nl-NL": "Sitebuilder",
    },

    //SSL
    "general.product-ssl.ssl_standaard": {
        "en-US": "Standard",
        "nl-NL": "Standaard",
    },
    "general.product-ssl.ssl_wildcard": {
        "en-US": "Wildcard",
        "nl-NL": "Wildcard",
    },
    "general.product-ssl.ssl_uitgebreid_(groene balk)": {
        "en-US": "Extended (green bar)",
        "nl-NL": "Uitgebreid (groene balk)",
    },
    "general.product-ssl.ssl_extended_validation": {
        "en-US": "Extended validation",
        "nl-NL": "Extended validation",
    },
    "general.product-ssl.ssl_single_domain": {
        "en-US": "Single domain",
        "nl-NL": "Single domain",
    },
    "general.product-ssl.ssl_premiumssl": {
        "en-US": "PremiumSSL",
        "nl-NL": "PremiumSSL",
    },
} as TranslationDictionary
