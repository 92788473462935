import { Order } from "modules/api-client/src/orders/types"
import { Product, ProductPrice } from "modules/api-client/src/product/types"
import { useAuth } from "modules/auth"
import { Customer } from "modules/auth/src/types"
import { copyObject, usePersistentState } from "modules/helpers"
import { Cart, CartItem, CartItemGroup } from "modules/orders"
import useCartOrderFactory from "modules/orders/src/cart-context/use-order-factory"
import React, { createContext, useCallback, useMemo } from "react"

export interface CartContextType {
    /**
     * The current cart contents
     */
    cart: Cart

    /**
     * The current total price of the cart contents
     */
    totalPrice: number

    /**
     * Adds the given item for the given domain to the cart
     */
    addItem( domain: string, item?: CartItem ): void

    /**
     * Removes up to one item from the given domain in the cart with the same
     * name as the given item.
     */
    removeItem( domain: string, item: CartItem ): void

    updateItem( domain: string, item: CartItem ): void

    /**
     * Set the ID of the customer that this order should be placed for, if this
     * is not the currently logged in customer. This can be the case when
     * resellers place orders for their own customers.
     */
    setCustomerId( customerId: number ): void

    orderPayload: Order|undefined

    setVouchers( voucherCodes: string[] ): void

    setInSingleProductMode ( inSingleProductMode: boolean ): void

    /**
     * Removes all contents from the cart
     */
    clearCart(): void
}

export const CartContext = createContext<CartContextType>( {} as CartContextType )

interface CartContextProps {
    children: React.ReactNode
}

export function CartContextProvider ( { children }: CartContextProps ): JSX.Element {

    const { customer } = useAuth()
    const [ cart, setCart ] = usePersistentState<Cart>( `orders.cart.${customer!.id}`, createEmptyCart( customer! ) )

    const addItem = useCallback( ( domain: string, item?: CartItem ): void => {
        setCart( ( currentCart: Cart ) => {
            const newCart: Cart = copyObject( currentCart )
            if ( ! newCart.domains[domain] ) {
                newCart.domains[domain] = { items: [] } as CartItemGroup
            }
            if ( item ) {
                newCart.domains[domain].items.push( item )
            }
            return newCart
        } )
    }, [setCart] )

    const removeItem = useCallback( ( domain: string, item: CartItem ): void => {
        setCart( ( currentCart: Cart ) => {
            if ( ! ( domain in currentCart.domains ) ) {
                return currentCart
            }

            const newCart: Cart = copyObject( currentCart )
            const index = newCart.domains[domain].items.findIndex( i => i.name === item.name )
            if ( index > -1 ) {
                newCart.domains[domain].items.splice( index, 1 )
            }
            if ( newCart.domains[domain].items.length === 0 ) {
                delete newCart.domains[domain]
            }
            return newCart
        } )
    }, [setCart] )

    const updateItem = useCallback( ( domain: string, item: CartItem ): void => {
        setCart( ( currentCart: Cart ): Cart => {
            const newCart: Cart = copyObject( currentCart )
            if ( ! ( domain in newCart.domains ) ) {
                return currentCart
            }
            const index = newCart.domains[domain].items.findIndex( i => i.name === item.name )
            if ( ~index ) {
                newCart.domains[domain].items[index] = item
            }
            return newCart
        } )
    }, [setCart] )

    const setVouchers = useCallback( ( vouchers: string[] ): void => {
        setCart( ( currentCart: Cart ) => {
            const newCart: Cart = copyObject( currentCart )
            newCart.vouchers = vouchers
            return newCart
        } )
    }, [setCart] )

    const setCustomerId = useCallback( ( customerId: number ): void => {
        setCart( ( currentCart: Cart ) => {
            const newCart: Cart = copyObject( currentCart )
            newCart.customerId = customerId
            return newCart
        } )
    }, [setCart] )

    const setInSingleProductMode = useCallback( ( inSingleProductMode: boolean ): void => {
        setCart( ( currentCart: Cart ) => {
            const newCart: Cart = copyObject( currentCart )
            newCart.inSingleProductMode = inSingleProductMode
            return newCart
        } )
    }, [setCart] )

    const clearCart = useCallback( (): void => {
        setCart( createEmptyCart( customer! ) )
    }, [setCart, customer] )

    const totalPrice = useMemo( (): number => {
        let total = 0
        Object.entries( cart.domains || {} ).forEach( ( domainAndGroup: [string, CartItemGroup] ): void => {
            const group = domainAndGroup[1]
            group.items.forEach( ( cartItem: CartItem ): void => {
                total += Math.round( getPrice( cartItem.price, "net" ) )
                cartItem.addons?.forEach( addon => {
                    total += Math.round( getAddonPrice( cartItem, addon, "net" ) )
                } )
            } )
        } )
        return total
    }, [cart] )

    const [orderPayload] = useCartOrderFactory( cart, totalPrice )

    return (
        <CartContext.Provider
            value={{
                cart: cart!,
                totalPrice,
                addItem,
                removeItem,
                updateItem,
                clearCart,
                setCustomerId,
                orderPayload,
                setVouchers,
                setInSingleProductMode,
            }}>
            {children}
        </CartContext.Provider>
    )
}

function createEmptyCart ( customer: Customer ): Cart {
    return {
        domains: {},
        customerId: customer.id,
        inSingleProductMode: false,
    }
}

export function getPrice ( price: ProductPrice, type: "gross"|"net" ): number {
    if ( type === "gross" ) {
        return price.promotion_price ?? price.regular_price
    }
    return price.promotion_price ?? price.discount_price ?? price.regular_price
}


export function getAddonPrice ( item: CartItem, addon: Product, type: "gross"|"net" ): number {
    const price = addon.prices.find( x => item.price.type === x.type && item.price.period === x.period )
    return price ? getPrice( price, type ) : 0
}
