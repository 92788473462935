import React, { ReactNode } from "react"

export type BannerVariant = "danger" | "info"

export interface BannerProps {
    /**
     * The text to show in the banner when the viewport is small.
     */
    shortText?: string

    /**
     * The optional button for taking action on the banner message (if desired).
     */
    button?: ReactNode

    /**
     * Variant of banner to show, default is 'danger'. The variant
     * sets the color.
     */
    variant?: BannerVariant

    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    children: ReactNode
}

/**
 * A banner component, consisting of some text, a color and optionally a button.
 * Use it for displaying important information a user must see. E.g. website maintenance.
 */
export default function Banner ( props: BannerProps ): JSX.Element {
    const variant = props.variant || "danger"

    return (
        <div className={`relative ${variant === "danger" ? "bg-red-600" : "bg-blue-600"} ${props.className || ""}`}>
            <div className="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between flex-wrap">
                    <div className="w-0 flex-1 flex items-center">
                        <p className="ml-3 font-medium text-white truncate">
                            {props.shortText &&
                                <span className="md:hidden">
                                    {props.shortText}
                                </span>}
                            <span className="hidden md:inline" data-variant={variant}>
                                {props.children}
                            </span>
                        </p>
                    </div>
                    {props.button &&
                        <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                            <div className="rounded-md shadow-sm">
                                {props.button}
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}
