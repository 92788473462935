import { AddonSubscription, ExtensionSubscription, ExtensionSubscriptionStatus, GeneralSubscriptionStatus, HostingSubscription, Order, SslSubscription, Subscription, Voucher } from "modules/api-client/src/orders/types"
import { ProductPriceType } from "modules/api-client/src/product/types"
import { CartItem } from "modules/orders"
import { getAddonPrice, getPrice } from "modules/orders/src/cart-context/cart-context"
import { Cart, CartItemGroup } from "modules/orders/src/cart-context/types"
import { isPrivacyProduct } from "modules/orders/src/components/order-overview-step"
import { useMemo } from "react"

function compileOrder ( cart: Cart, total: number ): Order | undefined {
    if( !cart.customerId || !cart.domains ) {
        return
    }

    const extensionSubscriptions: ExtensionSubscription[] = []
    const hostingSubscriptions: HostingSubscription[] = []
    const sslSubscriptions: SslSubscription[] = []
    const addonSubscriptions: AddonSubscription[] = []

    forEachCartItem( cart, ( domain, value ) => {
        const config = value.product.config
        const subscription: Subscription = {
            domain: domain,
            name: value.product.name,
            period: value.price.period,
            price: getPrice( value.price, "net" ),
            gross_price: getPrice( value.price, "gross" ),
        }

        const priceType = value.price.type
        switch( value.product.type ) {
            case "extension": {
                const sub: ExtensionSubscription = {
                    status: defineExtensionPriceType( priceType ),
                    ...subscription,
                }
                if ( config?.transfer_secret && priceType === "transfer" ) {
                    sub.transfer_secret = String( config.transfer_secret )
                }
                if ( value.addons?.find( item => isPrivacyProduct( item.name ) ) ) {
                    sub.private_whois = true
                }
                if ( config?.contact_id && config.contact_id !== "0" ) {
                    sub.contact_id = String( config.contact_id )
                }
                extensionSubscriptions.push( sub )

                break
            }
            case "hosting":{
                hostingSubscriptions.push( {
                    status: definePriceType( priceType ),
                    ...subscription,
                    server: config && config.server ? config.server as number : 0,
                    forwarding_url: config && config.forwardingUrl ? config.forwardingUrl as string : "",
                    wordpress: config && config.installWordPress ? config.installWordPress as boolean : false,
                } )

                break
            }
            case "ssl":{
                sslSubscriptions.push( {
                    status: definePriceType( priceType ),
                    ...subscription,
                    generateCsr: config && config.generateCsr ? config.generateCsr as boolean : true,
                    csr: config && config.csr ? config.csr as string : "",
                } )

                break
            }
            default:{
                throw new Error( `Product with type ${value.product.type} cannot be added to the cart.` )
            }
        }

        // Add addon subscriptions to order
        value.addons?.forEach( addon => {
            addonSubscriptions.push( {
                status: "registration",
                description: addon.description,
                descriptors: addon.descriptors,
                type: addon.type,
                domain: domain,
                name: addon.name,
                period: value.price.period,
                price: getAddonPrice( value, addon, "net" ),
                gross_price: getAddonPrice( value, addon, "gross" ),
                prices: addon.prices,
            } )
        } )
    } )

    const voucherCodes = cart.vouchers ?? []
    const vouchers: Voucher[] = voucherCodes.map( voucher => ( { "code": voucher } ) )

    return {
        total_price: total,
        customer_id: cart.customerId,
        subscriptions: {
            extension: extensionSubscriptions,
            hosting: hostingSubscriptions,
            ssl: sslSubscriptions,
            addons: addonSubscriptions,
        },
        vouchers: vouchers,
    } as Order
}

function definePriceType ( priceType: ProductPriceType ): GeneralSubscriptionStatus {
    return priceType === "prolongation" || priceType === "transfer" ? "registration" : priceType
}

function defineExtensionPriceType ( priceType: ProductPriceType ): ExtensionSubscriptionStatus {
    return priceType !== "prolongation" ? priceType : "registration"
}

function forEachCartItem ( cart: Cart, callback: ( domain: string, item: CartItem ) => void ): void {
    Object.entries( cart.domains ).forEach( ( [domain, group]: [string, CartItemGroup] ): void => {
        group.items.forEach( ( value: CartItem ): void => callback( domain, value ) )
    } )
}

export default function useCartOrderFactory ( cart: Cart, total: number ): [ Order|undefined ] {
    return [
        useMemo( () => compileOrder( cart, total ), [cart, total] ),
    ]
}
