import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.domain.domain.whois-privacy.back_to_domain": {
        "en-US": "Back to {domain}",
        "nl-NL": "Terug naar {domain}",
    },
    "pages.domain.domain.whois-privacy.enabled-successfully": {
        "en-US": "Privacy protection enabled for your domain {domain}.",
        "nl-NL": "Privacybescherming is geactiveerd voor je domein {domain}.",
    },
    "pages.domain.domain.whois-privacy.disabled-successfully": {
        "en-US": "Privacy protection disabled for your domain {domain}.",
        "nl-NL": "Privacybescherming is gedeactiveerd voor je domein {domain}.",
    },
    "pages.domain.domain.whois-privacy.form.accept_deactivation": {
        "en-US": "I agree to cancel the privacy protection",
        "nl-NL": "Ik ga akkoord met het stopppen van de privacy bescherming",
    },
    "pages.domain.domain.whois-privacy.form.accept_invoice": {
        "en-US": "I agree to receive an invoice for these costs",
        "nl-NL": "Ik ga akkoord met de facturatie van deze kosten",
    },
    "pages.domain.domain.whois-privacy.form.error.fill_all_fields": {
        "en-US": "All fields have to be filled in correctly.",
        "nl-NL": "Alle velden dienen correct ingevuld te zijn.",
    },
    "pages.domain.domain.whois-privacy.form.error.required": {
        "en-US": "This field is required.",
        "nl-NL": "Dit veld is verplicht.",
    },
    "pages.domain.domain.whois-privacy.form.save_disable": {
        "en-US": "Disable protection",
        "nl-NL": "Bescherming uitschakelen",
    },
    "pages.domain.domain.whois-privacy.form.save_enable": {
        "en-US": "Enable protection",
        "nl-NL": "Bescherming inschakelen",
    },
    "pages.domain.domain.whois-privacy.intro_active": {
        "en-US": "Currently, your personal data of the registration of {domain} are not publicly available. Do you want to make them available?",
        "nl-NL": "Op dit moment zijn je gegevens via de registratie van het domein {domain} niet publiek. Wil je deze publiek beschikbaar maken?",
    },
    "pages.domain.domain.whois-privacy.intro_inactive": {
        "en-US": "Currently, your personal data of the registration of {domain} are publicly available. Do you want to make this private?",
        "nl-NL": "Op dit moment zijn je gegevens via de registratie van het domein {domain} publiek beschikbaar. Wil je deze privé maken?",
    },
    "pages.domain.domain.whois-privacy.one_year": {
        "en-US": "1 year",
        "nl-NL": "1 jaar",
    },
    "pages.domain.domain.whois-privacy.page_heading": {
        "en-US": "Whois privacy protection",
        "nl-NL": "Whois privacybescherming",
    },
    "pages.domain.domain.whois-privacy.period": {
        "en-US": "Period",
        "nl-NL": "Periode",
    },
    "pages.domain.domain.whois-privacy.price": {
        "en-US": "Price",
        "nl-NL": "Prijs",
    },
    "pages.domain.domain.whois-privacy.server-error": {
        "en-US": "An error occurred while updating your whois privacy protection. Please try again later.",
        "nl-NL": "Er is een fout opgetreden bij het bijwerken van je whois privacybescherming. Probeer het later nog eens.",
    },

} as TranslationDictionary
