import React from "react"

export interface SpinnerProps {
    /**
     * Additional CSS classes to add to this component.
     */
    className?: string
}

/**
 * An animation of a spinning circle, used to indicate that something is being
 * processed or loaded.
 */
export default function Spinner ( props: SpinnerProps ): JSX.Element {
    return (
        <svg className={`animate-spin ${props.className || ""}`} style={{ stroke: "none" }} viewBox="0 0 24 24" data-testid="spinner">
            <circle className="opacity-25" cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    )
}
