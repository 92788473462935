import { Save } from "icons/font-awesome/light"
import React, { FC, ReactNode } from "react"
import { ButtonSize, ButtonVariant } from "ui-kit/elements/button"
import Spinner from "ui-kit/elements/spinner"

export interface SubmitButtonProps {
    /**
     * Whether to show the loading indicator
     */
    loading?: boolean

    /**
     * Icon to show in the button (default `<Save />` from font-awesome/light).
     * The icon will be hidden while the loading indicator is shown.
     */
    icon?: FC<{ className?: string }>

    /**
     * ID of the form to bind this submit to if it is not a direct descendant
     */
    form?: string

    /**
     * Variant of button to show, default is 'primary'
     */
    variant?: ButtonVariant

    /**
     * Size of button to show, default is 'md'
     */
     size?: ButtonSize

    /**
     * Button can be disabled if necessary.
     */
    disabled?: boolean

    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    children?: ReactNode
}

/**
 * A submit button (`type="submit"`) for use with forms. Can show a loading
 * indicator ({@see ui-kit/elements/spinner}) while the form is being processed.
 */
export default function SubmitButton ( props: SubmitButtonProps ): JSX.Element {
    let icon: ReactNode
    if ( props.loading ) {
        icon = <Spinner className="-ml-1 mr-3" />
    } else if ( props.icon ) {
        icon = <props.icon className="-ml-1 mr-3" />
    } else {
        icon = <Save className="-ml-1 mr-3" />
    }

    return (
        <button type="submit" disabled={props.loading || props.disabled} aria-busy={( props.loading || props.disabled ) ?? false}
            className={`btn btn-${props.variant || "primary"} btn-${props.size || "md"} ${props.className || ""}`} form={props.form}>
            {icon}
            {props.children}
        </button>
    )
}
