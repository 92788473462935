import { useCallback, useState } from "react"

export interface FetchedResource<T> {
    resource: T|undefined
    setResource: ( resource: T ) => void
    isLoading: boolean
    error: unknown | undefined
    clearError: () => void
    fetchResource: ( options?: {reload?: boolean, force?: boolean} ) => Promise<void>
}

/**
 * Utility function for dealing with asynchronously fetched resources. Given a
 * function that returns a promise for a resource, it will ensure the data is
 * not reloaded (unless specifically told to) on subsequent calls, and deal with
 * errors while loading. It returns some stateful abstractions:
 *  - `resource`: the resource itself
 *  - `isLoading`: a boolean indicating if the resource is currently being retrieved
 *  - `error`: any error thrown while loading
 *  - `clearError`: function that clears a previous error for the next fetch attempt
 *  - `fetchResource`: a new function that should be called to load the data (see below)
 *  - `setResource`: a function to manually update the resource
 *
 * The returned `fetchResource` function accepts an optional object argument
 * with two boolean options: `reload` (default false) to reload existing data
 * and `force` (default false) to force reloading, even if data is already
 * loaded or an error was thrown on a previous attempt.
 */
export default function useFetchedResource <T> ( fetchCallback: () => Promise<T|undefined> ): FetchedResource<T> {

    const [resource, setResource] = useState<T>()
    const [isLoading, setIsLoading] = useState<boolean>( false )
    const [error, setError] = useState<unknown | undefined>()

    const fetchResource = useCallback( async ( options?: {reload?: boolean, force?: boolean} ) => {
        const { reload, force } = { reload: false, force: false, ...options }
        if ( isLoading || error && ! force || resource !== undefined && ! reload && ! force ) {
            return
        }

        setIsLoading( true )
        try {
            const result = await fetchCallback()
            setError( undefined )
            if ( result !== undefined || force ) {
                setResource( result )
            }
        } catch ( error ) {
            setError( error )
        } finally {
            setIsLoading( false )
        }
    }, [error, isLoading, resource, setIsLoading, fetchCallback, setResource] )

    const clearError = useCallback( () => {
        setError( undefined )
    }, [setError] )

    return {
        resource,
        setResource,
        isLoading,
        error,
        clearError,
        fetchResource,
    }
}
