import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "ui-kit.layout.page-container.impersonation_header_short": {
        "en-US": "Logged in as ",
        "nl-NL": "Ingelogd als ",
    },
    "ui-kit.layout.page-container.impersonation_header": {
        "en-US": "Watch out! You are logged in as ",
        "nl-NL": "Let op! Je bent ingelogd als ",
    },
    "ui-kit.layout.page-container.nova_back_button": {
        "en-US": "Back to Admin panel",
        "nl-NL": "Terug naar admin paneel",
    },
    "ui-kit.layout.page-container.user-menu": {
        "en-US": "User menu",
        "nl-NL": "Gebruikersmenu",
    },

} as TranslationDictionary
