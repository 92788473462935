import classNames from "classnames"
import React, { ReactNode, useEffect, useState } from "react"

export interface ToggleProps {

    /**
     * Whether this toggle is initially enabled
     */
    enabled: boolean

    /**
     * Function to call when this control is toggled between states
     */
    onToggle?: ( newState: boolean ) => void

    /**
     * Function to call when this control is changed to enabled
     */
    onEnable?: () => void

    /**
     * Function to call when this control is changed to disabled
     */
    onDisable?: () => void

    /**
     * The name under which this control is submitted with the form
     */
    name?: string

    /**
     * The value submitted from this control if it is enabled (default `on`)
     */
    value?: string

    /**
     * Whether the label should be to the left or right (default) of the toggle
     */
    labelPosition?: "left" | "right"

    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    /**
     * Optional id used for element.
     */
    id?: string

    /**
     * Whether the input may be modified.
     */
    inputDisabled?: boolean

    /**
     * Contents of the label associated with this toggle
     */
    children: ReactNode
}

/**
 * A toggle form element with two states: enabled or disabled.
 */
export default function Toggle ( props: ToggleProps ): JSX.Element {
    const disabled = props.inputDisabled || false
    const [ enabled, setEnabled ] = useState( props.enabled )

    /**
     * To ensure the enter animation plays, only start showing the item
     * after the initial render.
     *
     * We only can setEnabled after the first cycle.
     */
    useEffect( () => {
        setTimeout( () => setEnabled( props.enabled ) )
    } )

    const onChange = (): void => {
        const newState = !enabled
        setEnabled( newState )
        if ( props.onToggle ) {
            props.onToggle( newState )
        }
        if ( newState && props.onEnable ) {
            props.onEnable()
        }
        if ( !newState && props.onDisable ) {
            props.onDisable()
        }
    }
    const label = <span className={props.labelPosition === "left" ? "mr-4" : "ml-4"}>
        {props.children}
    </span>

    return (
        <label id={props.id} className="text-gray-900 h-6 inline-flex items-center">
            {props.labelPosition === "left" && label}

            <div className={`${enabled ? "bg-brand-600" : "bg-gray-200"} ${disabled ? "opacity-75" : ""} relative inline-flex flex-shrink-0 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus-within ${props.className || ""}`}>
                <input type="checkbox" className="sr-only" disabled={disabled} checked={enabled} name={props.name} value={props.value} onChange={onChange} />
                <span aria-hidden="true" className={`${enabled ? "translate-x-5" : "translate-x-0"} relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}>
                    <span className={classNames( {
                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity": true,
                        "opacity-0 ease-out duration-100": enabled,
                        "opacity-100 ease-in duration-200": !enabled,
                    } )}>
                        <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                            <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                    <span className={classNames( {
                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity": true,
                        "opacity-100 ease-in duration-200": enabled,
                        "opacity-0 ease-out duration-100": !enabled,
                    } )}>
                        <svg className="h-3 w-3 text-brand-600" fill="currentColor" viewBox="0 0 12 12">
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                    </span>
                </span>
            </div>

            {props.labelPosition !== "left" && label}
        </label>
    )
}
