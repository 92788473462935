import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { Subscription } from "modules/api-client/src/subscriptions/types"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface SubscriptionsApiSpec {
    subscriptionsRequest( options?: RequestOptions ): Promise<Response<Subscription[]>>
}

export function useSubscriptionsApi (): SubscriptionsApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retreive the subscriptions from the API.
     */
    const subscriptionsRequest = useCallback( ( options?: RequestOptions ): Promise<Response<Subscription[]>> => {
        return getRequest<{ data: Subscription[] }>( "subscriptions", {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    return {
        subscriptionsRequest,
    }
}
