/**
 * Group an array based on a given key.
 */
export default function groupBy<T> ( list: T[], getKey: ( item: T ) => string ): {[group: string]: T[]} {
    const map: {[group: string]: T[]} = {}

    list.forEach( ( item ) => {
        const key = getKey( item )
        const collection = map[key]
        if ( !collection ) {
            map[key] = [item]
        } else {
            collection.push( item )
        }
    } )

    return map
}
