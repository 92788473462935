import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.servers.download_CSV": {
        "en-US": "Download CSV",
        "nl-NL": "CSV downloaden",
    },
    "pages.servers.page_heading": {
        "en-US": "Servers",
        "nl-NL": "Servers",
    },
    "pages.servers.table.hostname": {
        "en-US": "Hostname",
        "nl-NL": "Hostnaam",
    },
    "pages.servers.table.ip_addresses": {
        "en-US": "IP addresses",
        "nl-NL": "IP-adressen",
    },
    "pages.servers.table.ipv4": {
        "en-US": "IPv4",
        "nl-NL": "IPv4",
    },
    "pages.servers.table.ipv6": {
        "en-US": "IPv6",
        "nl-NL": "IPv6",
    },
    "pages.servers.table.login_as_admin": {
        "en-US": "Login as admin",
        "nl-NL": "Login als admin",
    },
    "pages.servers.table.login_as_admin.not_possible": {
        "en-US": "Not possible",
        "nl-NL": "Niet mogelijk",
    },
    "pages.servers.table.maximum_websites": {
        "en-US": "Maximum number of websites",
        "nl-NL": "Maximaal aantal websites",
    },
    "pages.servers.table.name": {
        "en-US": "Name",
        "nl-NL": "Naam",
    },
    "pages.servers.table.number_of_websites": {
        "en-US": "Number of websites",
        "nl-NL": "Aantal websites",
    },
    "pages.servers.table.websites": {
        "en-US": "Websites",
        "nl-NL": "Websites",
    },

} as TranslationDictionary
