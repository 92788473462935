import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.domain.discontinue.title": {
        "en-US": "Cancel service(s)",
        "nl-NL": "Dienst(en) opzeggen",
    },
    "pages.domain.discontinue.discontinue_or_transfer": {
        "en-US": "Discontinue or transfer",
        "nl-NL": "Opzeggen of verhuizen",
    },
    "pages.domain.discontinue.banner": {
        "en-US": "Caution, you are about to cancel {domain}, move away or services that fall under this hostname!",
        "nl-NL": "Let op, je staat op het punt om {domain} op te zeggen, weg te verhuizen of diensten die onder deze hostname vallen!",
    },
    "pages.domain.discontinue.domain": {
        "en-US": "Domain: {domain}",
        "nl-NL": "Domein: {domain}",
    },
    "pages.domain.discontinue.transfer": {
        "en-US": "Send transfer code",
        "nl-NL": "Verhuiscode opsturen",
    },
    "pages.domain.discontinue.hosting": {
        "en-US": "Hosting & email: {hosting}",
        "nl-NL": "Hosting & email: {hosting}",
    },
    "pages.domain.discontinue.immediately": {
        "en-US": "Cancel immmediately (website/email is removed immediately)",
        "nl-NL": "Direct opzeggen (website/email wordt meteen verwijderd)",
    },
    "pages.domain.discontinue.ssl": {
        "en-US": "SSL: {ssl}",
        "nl-NL": "SSL: {ssl}",
    },
    "pages.domain.discontinue.end_date": {
        "en-US": "Cancel per {date}",
        "nl-NL": "Opzeggen per {date}",
    },
    "pages.domain.discontinue.discontinued_by": {
        "en-US": "Expires at {date}",
        "nl-NL": "Verloopt op {date}",
    },

} as TranslationDictionary
