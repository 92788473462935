import classNames from "classnames"
import React from "react"
import FormField, { FormFieldBaseProps } from "ui-kit/forms/form-field"

export interface TextareaProps extends FormFieldBaseProps {

    /**
     * Optional hint for the type of data to suggest for auto-completing this
     * field
     */
    autoComplete?: string

    /**
     * Number of rows to show. Default 3.
     */
    rows?: number

    /**
     * Additional classes to apply to the textarea itself
     */
    textareaClassname?: string

    /**
     * Optional ref object to connect to the textarea element
     */
    textareaRef?: React.RefObject<HTMLTextAreaElement>
}

/**
 * This is a multi-line input field that users can type into.
 */
export default function Textarea ( props: TextareaProps ): JSX.Element {
    const hasErrors = props.errors ? props.errors.length > 0 : false

    return (
        <FormField {...props}>
            <div className="mt-1 rounded-md shadow-sm">
                <textarea ref={props.textareaRef} id={props.id} name={props.name} value={props.value}
                    onChange={ev => props.onChange( ev.target.value, props.id )}
                    required={props.required ?? true}
                    autoComplete={props.autoComplete} rows={props.rows || 3}
                    aria-invalid={hasErrors}
                    aria-describedby={`${props.id}_errors`}
                    className={classNames( [
                        props.textareaClassname,
                        "form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                        { "border-red-500": hasErrors },
                    ] )}>
                </textarea>
            </div>
        </FormField>
    )
}
