/**
 * Carbon copy an object, don't keep any references.
 */
export default function copyObject (): undefined
export default function copyObject <T> ( obj: T ): T
export default function copyObject <T> ( obj?: T ): T | undefined {
    const json = JSON.stringify( obj ) as string | undefined

    if ( typeof json === "undefined" || typeof obj === "undefined" ) {
        return undefined
    }

    return JSON.parse( json ) as T
}
