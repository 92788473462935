import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.create_customer.back_to_overview": {
        "en-US": "Back to the overview",
        "nl-NL": "Terug naar het overzicht",
    },
    "pages.create_customer.create_succesful": {
        "en-US": "Your customer has been created succesfully.",
        "nl-NL": "Je eigen klant is aangemaakt.",
    },
    "pages.create_customer.form.city": {
        "en-US": "City",
        "nl-NL": "Plaats",
    },
    "pages.create_customer.form.country": {
        "en-US": "Country",
        "nl-NL": "Land",
    },
    "pages.create_customer.form.create_user": {
        "en-US": "Can log in to klantlogin.nl",
        "nl-NL": "Kan inloggen in klantlogin.nl",
    },
    "pages.create_customer.form.department": {
        "en-US": "Department",
        "nl-NL": "Afdeling",
    },
    "pages.create_customer.form.vat_number": {
        "en-US": "VAT code",
        "nl-NL": "BTW nummer",
    },
    "pages.create_customer.form.email": {
        "en-US": "Email address",
        "nl-NL": "E-mailadres",
    },
    "pages.create_customer.form.first_name": {
        "en-US": "First name",
        "nl-NL": "Voornaam",
    },
    "pages.create_customer.form.last_name": {
        "en-US": "Last name",
        "nl-NL": "Achternaam",
    },
    "pages.create_customer.form.organization": {
        "en-US": "Organization",
        "nl-NL": "Bedrijfsnaam",
    },
    "pages.create_customer.form.phone_number": {
        "en-US": "Phone number",
        "nl-NL": "Telefoonnummer",
    },
    "pages.create_customer.form.street_name": {
        "en-US": "Street name",
        "nl-NL": "Straatnaam",
    },
    "pages.create_customer.form.street_number": {
        "en-US": "Street number",
        "nl-NL": "Huisnummer",
    },
    "pages.create_customer.form.zip_code": {
        "en-US": "Zip code",
        "nl-NL": "Postcode",
    },
    "pages.create_customer.title": {
        "en-US": "Add own customer",
        "nl-NL": "Eigen klant toevoegen",
    },

} as TranslationDictionary
