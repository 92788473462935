import { useAuth } from "modules/auth"
import { CustomerAction } from "modules/auth/src/types"
import { can } from "modules/helpers"
import { ModalContainer } from "modules/modals"
import { NotificationContainer } from "modules/notifications"
import getConfig from "next/config"
import { ReactNode } from "react"
import { PageContainer } from "ui-kit/layout/page-container"
import { NavItem } from "ui-kit/navigation/sidebar-navigation"

const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: { navigation: NavItem[] } }

interface AuthorizedPageContainerProps {
    children: ReactNode
}

function AuthorizedPageContainer ( props: AuthorizedPageContainerProps ): JSX.Element {
    const { user, customer } = useAuth()

    const navigation = publicRuntimeConfig.navigation.filter( ( nav: NavItem ) => {
        if ( nav.permission && customer ) {
            return can( customer, nav.permission as CustomerAction )
        }
        return nav.display
    } )

    if ( customer && can( customer, "viewFieldInvoiceHistoryUrl" ) && customer.invoice_history_url ) {
        navigation.push( {
            icon: "FileInvoice",
            category: "general.navigation.category.settings",
            title: "general.navigation.invoices",
            location: customer.invoice_history_url,
            external: true,
        } as NavItem )
    }

    return user ?
        <PageContainer navigation={navigation}>
            <NotificationContainer />
            <ModalContainer />
            {props.children}
        </PageContainer>
        : <></>
}

export { AuthorizedPageContainer }
