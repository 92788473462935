import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.dns-templates.template.details.page-header": {
        "en-US": "DNS template beheren",
        "nl-NL": "Manage DNS template",
    },
    "pages.dns-templates.template.details.page-header-named": {
        "en-US": "DNS template: {name}",
        "nl-NL": "DNS template: {name}",
    },
    "pages.dns-templates.template.details.description": {
        "en-US": "When you link your zones to your template, your zones will be kept up-to-date with the changes in your template. If you delete the template, the linked zones will not be modified.",
        "nl-NL": "Wanneer je zones koppelt aan je template, zullen deze zones up to date gehouden worden met de veranderingen aan je template. Bij het verwijderen van jouw template zullen de onderliggende zones niet aangepast worden.",
    },
    "pages.dns-templates.template.details.uncoupled": {
        "en-US": "There are currently no coupled domains.",
        "nl-NL": "Er zijn nog geen domeinen gekoppeld.",
    },
    "pages.dns-templates.template.details.couple-button": {
        "en-US": "Link/Unlink domains",
        "nl-NL": "Koppel/Ontkoppel domeinen",
    },
    "pages.dns-templates.template.details.configure-template": {
        "en-US": "Manage template",
        "nl-NL": "Template beheren",
    },
    "pages.dns-templates.template.explain-@": {
        "en-US": "When configuring a template, you can use the '@' as a replacement for the domain. This way, we can apply any changes to multiple DNS zones if relevant.",
        "nl-NL": "Bij het instellen van een template, is '@' te gebruiken als vervanger van het domein. Zo kunnen wij, wanneer er meerdere domeinen gekoppeld zijn aan het template, alle wijzigingen overal toepassen.",
    },
    "pages.dns-templates.template.details.coupled-domains-header": {
        "en-US": "Coupled domains",
        "nl-NL": "Gekoppelde domeinen",
    },

} as TranslationDictionary
