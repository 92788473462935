import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { PrivateKey } from "modules/api-client/src/ssl/requests/upload-private-key-request"
import { Csr } from "modules/api-client/src/ssl/requests/validate-csr-request"
import { ReissueResponse } from "modules/api-client/src/ssl/responses/reissue-response"
import { PrivateKeyUploadResponse } from "modules/api-client/src/ssl/responses/upload-private-key-response"
import { CsrValidationResponse } from "modules/api-client/src/ssl/responses/validate-csr-response"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface SslApiSpec {
    uploadPrivateKeyRequest( privateKey: PrivateKey, options?: RequestOptions ): Promise<Response<PrivateKeyUploadResponse>>
    validateCsrRequest( csr: Csr, options?: RequestOptions ): Promise<Response<CsrValidationResponse>>
    reissueRequest( csr: Csr, options?: RequestOptions ): Promise<Response<ReissueResponse>>
}

export function useSslApi (): SslApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { postRequest, putRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to upload a private key for an ssl certificate from the API.
     */
    const uploadPrivateKeyRequest = useCallback( ( privateKey: PrivateKey, options?: RequestOptions ): Promise<Response<PrivateKeyUploadResponse>> => {
        return putRequest<PrivateKeyUploadResponse>( "ssl/privatekey", privateKey, {}, options )
    }, [putRequest] )

    /**
     * Request to validate a csr for an ssl certificate from the API.
     */
    const validateCsrRequest = useCallback( ( csr: Csr, options?: RequestOptions ): Promise<Response<CsrValidationResponse>> => {
        return postRequest<CsrValidationResponse>( "ssl/csr/validate", csr, {}, options )
    }, [postRequest] )

    /**
     * Request to reissue an ssl certificate from the API.
     */
    const reissueRequest = useCallback( ( csr: Csr, options?: RequestOptions ): Promise<Response<ReissueResponse>> => {
        return postRequest<ReissueResponse>( "ssl/reissue", csr, {}, options )
    }, [postRequest] )

    return {
        uploadPrivateKeyRequest,
        validateCsrRequest,
        reissueRequest,
    }
}
