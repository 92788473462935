import classNames from "classnames"
import { useTranslations } from "modules/translations"
import React, { useState } from "react"
import HyperLink from "ui-kit/elements/hyper-link"
import LinkButton from "ui-kit/elements/link-button"

export interface TabsProps {
    /**
     * An array of `[ path, name ]` pairs to show as tabs.
     */
    items: Array<[string, string]>

    /**
     * The path of the tab that is shown as selected.
     */
    currentPath: string

    /**
     * Additional CSS classes to add to this component.
     */
    className?: string
}

/**
 * A list of tabs to navigate to one of the sub pages.
 */
export default function Tabs ( props: TabsProps ): JSX.Element {
    const { translate } = useTranslations()
    const [ selectedPath, setSelectedPath ] = useState( props.currentPath )

    return (
        <div className={props.className}>
            <div className="sm:hidden">
                <label htmlFor="tab-navigation" className="block text-sm font-medium leading-5 text-gray-700">
                    {translate( "ui-kit.navigation.tabs.choose-tab" )}
                </label>
                <div className="mt-1 flex gap-2">
                    <select defaultValue={selectedPath} id="tab-navigation"
                        onBlur={( event ) => setSelectedPath( event.target.value )}
                        className="flex-1 form-select block pl-3 pr-10 py-2 text-base leading-6 border-gray-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150">
                        {props.items.map( ( [ path, name ] ) =>
                            <option value={path} key={path}>{name}</option>
                        )}
                    </select>
                    <LinkButton href={selectedPath}>
                        {translate( "ui-kit.navigation.tabs.go" )}
                    </LinkButton>
                </div>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex gap-8">
                        {props.items.map( ( [ path, name ] ) => {
                            const current = path === props.currentPath
                            const classList = classNames( {
                                "whitespace-no-wrap py-4 px-1 border-b-2 font-medium text-sm leading-5": true,
                                "border-brand-500 text-brand-600 focus:text-brand-800 focus:border-brand-700": current,
                                "border-transparent text-gray-500 focus:text-gray-700 focus:border-gray-300 hover:text-gray-700 hover:border-gray-300": !current,
                            } )
                            return <HyperLink href={path} key={path} className={classList} variant="unstyled">
                                {name}
                            </HyperLink>
                        } )}
                    </nav>
                </div>
            </div>
        </div>
    )
}
