import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "ui-kit.navigation.tabs.choose-tab": {
        "en-US": "Choose a section",
        "nl-NL": "Kies een onderdeel",
    },
    "ui-kit.navigation.tabs.go": {
        "en-US": "Go",
        "nl-NL": "Ga",
    },

} as TranslationDictionary
