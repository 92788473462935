import React from "react"

export interface PanelDividerProps {
    /**
     * Additional CSS classes to add to this component.
     */
    className?: string
}

/**
 * The `PanelDivider` is a standard horizontal ruler that fits great inside a panel.
 */
export default function PanelDivider ( props: PanelDividerProps ): JSX.Element {
    return <hr className={`my-5 -mx-4 sm:-mx-6 border-gray-200 ${props.className || ""}`} />
}
