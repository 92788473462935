import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.affiliate.status-title": {
        "en-US": "Affiliate program",
        "nl-NL": "Partnerprogramma",
    },
    "pages.affiliate.status-introduction": {
        "en-US": "With the affiliate program from {brand}, you will get a percentage of the purchases that have been done through your partner link. This is also the case for renewals and upgrades!",
        "nl-NL": "Met het partnerprogramma van {brand} krijgt u een percentage van aankopen die via uw partner link zijn gedaan. Dit geldt ook voor verlengingen en upgrades!",
    },
    "pages.affiliate.status-introduction-step-1": {
        "en-US": "Send the link to your close friends, colleagues, business partners or put the link on your website, blog or app.",
        "nl-NL": "Stuur de link door naar uw kennissen, collega's, zakenrelaties of plaats de link op uw website, blog of app.",
    },
    "pages.affiliate.status-introduction-step-2": {
        "en-US": "People and companies that sign up using your link will be linked to your partner account.",
        "nl-NL": "Personen en bedrijven die zich via de link als klant aanmelden worden gekoppeld aan uw parter account.",
    },
    "pages.affiliate.status-introduction-step-3": {
        "en-US": "On every purchase and future purchases that those customers do, you will receive a fair percentage.",
        "nl-NL": "Van alle aankopen en toekomstige aankopen die door deze klanten worden gedaan, krijgt u een fraai percentage uitgekeerd.",
    },
    "pages.affiliate.status-introduction-step-4": {
        "en-US": "You will be paid at the end of each month automatically, just the way that you want it.",
        "nl-NL": "U wordt aan het eind van diedere maand automatisch uitbetaald op de wijze die u wenst.",
    },
    "pages.affiliate.request": {
        "en-US": "Sign up for affiliateprogram",
        "nl-NL": "Aanmelden voor partnerprogramma",
    },
    "pages.affiliate.show-rate": {
        "en-US": "Kickback rates",
        "nl-NL": "Kickback tarieven",
    },
    "pages.affiliate.show-url": {
        "en-US": "URL",
        "nl-NL": "URL",
    },
    "pages.affiliate.copy-url": {
        "en-US": "Copy URL",
        "nl-NL": "Kopieer URL",
    },
    "pages.affiliate.url-copied": {
        "en-US": "URL copied to clipboard",
        "nl-NL": "URL gekopieerd naar klembord",
    },
    "pages.affiliate.statistic-customers": {
        "en-US": "Customers",
        "nl-NL": "Klanten",
    },
    "pages.affiliate.statistic-earnings": {
        "en-US": "Earnings",
        "nl-NL": "Verdiensten",
    },
    "pages.affiliate.statistic-transactions": {
        "en-US": "Transactions",
        "nl-NL": "Transacties",
    },
    "pages.affiliate.inactive-warning": {
        "en-US": "Your participation in the affiliate program is being verified.",
        "nl-NL": "Uw deelname aan het partnerprogramma wordt op dit moment geverifieerd.",
    },
    "pages.affiliate.field-earnings": {
        "en-US": "Earnings",
        "nl-NL": "Inkomsten",
    },
    "pages.affiliate.field-date": {
        "en-US": "Date",
        "nl-NL": "Datum",
    },
    "pages.affiliate.field-customer": {
        "en-US": "Customer",
        "nl-NL": "Klant",
    },
} as TranslationDictionary
