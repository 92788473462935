import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.forwards.section.title": {
        "en-US": "Forwards",
        "nl-NL": "Doorsturingen",
    },
    "pages.forwards.table_header.source": {
        "en-US": "Source",
        "nl-NL": "Bron",
    },
    "pages.forwards.table_header.target": {
        "en-US": "Target",
        "nl-NL": "Bestemming",
    },
    "pages.forwards.table_header.actions": {
        "en-US": "Actions",
        "nl-NL": "Acties",
    },
    "pages.forwards.button.add": {
        "en-US": "Add forward",
        "nl-NL": "Voeg een doorsturing toe",
    },
    "pages.forwards.no_forwards": {
        "en-US": "You don't have any forwards yet",
        "nl-NL": "Er zijn geen doorsturingen",
    },
} as TranslationDictionary
