import { useTranslations } from "modules/translations"
import getConfig from "next/config"
import React, { createContext, useCallback, useState } from "react"
import { ListItem } from "ui-kit/lists/stacked-list"
import { NavItem } from "ui-kit/navigation/sidebar-navigation"

export type SearchBlockIdentifier = "services" | "menu"

export interface SearchDataBlock {
    identifier: SearchBlockIdentifier
    items: ListItem[]
}

export interface GlobalSearchDataContextType {
    blocks: SearchDataBlock[]
    addBlock( block: SearchDataBlock ): void
    removeBlock( identifier: SearchBlockIdentifier ): void
}

export const GlobalSearchDataContext = createContext<GlobalSearchDataContextType>( {} as GlobalSearchDataContextType )

interface GlobalSearchDataContextProps {
    children: React.ReactNode
}

export function GlobalSearchDataContextProvider ( { children }: GlobalSearchDataContextProps ): JSX.Element {
    const { translate } = useTranslations()
    const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: { navigation: NavItem[] } }

    const navItems: ListItem[] = publicRuntimeConfig.navigation.filter( navItem => navItem.display ).map( navItem => {return { title: translate( navItem.title ), description: translate( "general.page" ), location: navItem.location }} )
    const [blocks, setBlocks] = useState<SearchDataBlock[]>( [{
        identifier : "menu",
        items: navItems,
    }] )

    const addBlock = useCallback( ( block: SearchDataBlock ): void => {
        setBlocks( ( existingBlocks ) => {
            if ( existingBlocks.find( b => b.identifier === block.identifier ) ) {
                return existingBlocks
            } else {
                return existingBlocks.concat( block )
            }
        } )
    }, [setBlocks] )

    const removeBlock = useCallback( ( identifier: SearchBlockIdentifier ): void => {
        setBlocks( ( existingBlocks ) => {
            return existingBlocks.filter( ( block ) => block.identifier !== identifier )
        } )
    }, [setBlocks] )

    // const addItemsToBlock = useCallback( ( identifier: SearchBlockIdentifier, items: ListItem[] ): void => {
    //     setBlocks( ( existingBlocks ) => {
    //         const block = existingBlocks.find( block => block.identifier === identifier )
    //
    //         if ( block === undefined ) {
    //             return existingBlocks
    //         }
    //
    //         return existingBlocks.filter( ( block ) => block.identifier !== identifier ).concat( block )
    //     } )
    // }, [setBlocks] )

    return (
        <GlobalSearchDataContext.Provider
            value={{
                blocks,
                addBlock,
                removeBlock,
            }}>
            {children}
        </GlobalSearchDataContext.Provider>
    )
}
