import { CartPlus, Check } from "icons/font-awesome/solid"
import { Product, ProductPrice } from "modules/api-client/src/product/types"
import { useProductsData } from "modules/cached-data"
import { formatCurrency, stripDomainName } from "modules/helpers"
import { CartItem, useCart } from "modules/orders"
import { getPrice } from "modules/orders/src/cart-context/cart-context"
import { useTranslations } from "modules/translations"
import React, { useCallback, useMemo, useState } from "react"
import Button from "ui-kit/elements/button"
import Spinner from "ui-kit/elements/spinner"
import SectionHeading from "ui-kit/headings/section-heading"
import ProductGrid, { ProductGridItem } from "ui-kit/pricing/product-grid"

export default function OrderHostingStep (): JSX.Element {

    const { translate } = useTranslations()
    const { cart } = useCart()
    const domains = Object.keys( cart.domains )

    return <>
        {domains.length === 0 && <p>{translate( "modules.orders.error-no-domain-chosen" )}</p>}

        {domains.map( domain => <DomainHostingChooser key={domain} domain={domain} /> )}
    </>
}


function DomainHostingChooser ( { domain }: { domain: string } ): JSX.Element {
    const hostingProductType = "hosting"
    const hostingPriceType = "registration"
    const hostingPeriod = 12

    const { addItem, cart, removeItem, updateItem } = useCart()
    const { translate } = useTranslations()
    const { products, isLoading } = useProductsData()

    const [selected, setSelected] = useState<CartItem | undefined>( () =>
        cart.domains[domain].items.find( item => item.product.type === hostingProductType ) )

    const hostingProducts = useMemo( () => ( products || [] ).filter( p => p.type === hostingProductType ), [products] )

    const getHostingProductPrice = ( hostingProduct: Product ): ProductPrice => {
        return hostingProduct.prices.find( p => p.type === hostingPriceType && p.period === hostingPeriod )!
    }

    const unselect = useCallback( () => {
        if ( selected ) {
            removeItem( domain, selected )
            setSelected( undefined )
        }
    }, [selected, domain, removeItem] )

    const selectHostingProduct = ( hostingProduct: Product ): void => {
        unselect()
        const newItem: CartItem = {
            name: hostingProduct.name,
            slug: hostingProduct.slug,
            product: hostingProduct,
            price: getHostingProductPrice( hostingProduct ),
        }
        addItem( domain, newItem )
        setSelected( newItem )

        if ( hostingProduct.productCouplings ) {
            addCoupledProductToCart( hostingProduct.productCouplings, domain )
        }
    }

    function addCoupledProductToCart ( coupledProducts: Product[], queriedDomain: string ): void
    {
        coupledProducts.forEach( ( coupledProduct ) => {

            const price = coupledProduct.prices.find( ( price: ProductPrice ): boolean => {
                return price.type === "conditional" && price.period === 12
            } )

            if( ! price ){
                return
            }

            const domain = coupledProduct.type === "extension" ? stripDomainName( queriedDomain ) + coupledProduct.name : queriedDomain

            const cartItem = {
                name: coupledProduct.type === "extension" ? translate( "pages.order.cart.domain" ) : coupledProduct.name,
                slug: coupledProduct.slug,
                product: coupledProduct,
                price: price,
            }

            if( Object.keys( cart.domains ).includes( domain ) ){
                updateItem( domain, cartItem )
            } else {
                addItem( domain, cartItem )
            }
        } )
    }

    return <>
        <SectionHeading>
            {translate( "modules.orders.hosting-step.select-hosting-for-domain", { domain } )}
        </SectionHeading>

        {isLoading ? <>
            <div className="flex flex-row items-center justify-center h-32">
                <Spinner className="text-3xl text-brand-500" />
            </div>
        </> : <>
            <ProductGrid maxPerRow={3}>
                {hostingProducts.map( ( hostingProduct: Product ) => {
                    const buyButton = selected?.product.name === hostingProduct.name
                        ? <Button onClick={() => unselect()} icon={Check} variant="secondary">
                            {translate( "modules.orders.hosting-step.hosting-product-chosen", { product: hostingProduct.name } )}
                        </Button>
                        : <Button onClick={() => selectHostingProduct( hostingProduct )} icon={CartPlus} variant="primary">
                            {translate( "modules.orders.hosting-step.button-choose-hosting-product", { product: hostingProduct.name } )}
                        </Button>

                    const specs = Object.entries( hostingProduct.descriptors.main )
                        .concat( Object.entries( hostingProduct.descriptors.small ) )
                        .filter( ( [, value] ) => value !== false )
                        .map( ( [key, value] ) => {
                            if ( value === true ) {
                                return translate( key )
                            }
                            return `${translate( key )}: ${value}`
                        } )

                    const productCouplings = hostingProduct.productCouplings
                    const ProductCoupling = productCouplings ? translate(
                        "pages.order.order.product_coupling_upsell", {
                            parentProductName:hostingProduct.name,
                            parentProductType: translate( `general.product-group.${hostingProduct.type}` ),
                            coupledProductName: productCouplings[0].name,
                            coupledProductType:translate( `general.product-group.${productCouplings[0].type}` ),
                        }
                    ) : undefined

                    const monthlyAmount = getPrice( getHostingProductPrice( hostingProduct ), "net" ) / hostingPeriod / 100

                    return <ProductGridItem
                        className="rounded-lg"
                        key={hostingProduct.name}
                        title={translate( `general.product-hosting.${hostingProduct.slug}` )}
                        description={hostingProduct.description}
                        price={formatCurrency( monthlyAmount, "EUR" )}
                        period={translate( "general.units.month" )}
                        buyButton={buyButton}
                        specsTitle={translate( "modules.orders.hosting-step.specs-title" )}
                        specs={specs}
                        upSellText={ProductCoupling}
                    />
                } )}
            </ProductGrid>
        </>}
    </>
}
