import { Transition } from "@headlessui/react"
import md5 from "crypto-js/md5"
import { ChevronRight } from "icons/font-awesome/light"
import { Bell, Building, CheckCircle, Search as SearchIcon, TimesCircle, TimesOctagon, Trash } from "icons/font-awesome/solid"
import { useAuthApi } from "modules/api-client"
import { useAuth } from "modules/auth"
import { useGlobalSearchData } from "modules/cached-data"
import { useNotifications } from "modules/notifications"
import { HistoricNotificationProps } from "modules/notifications/src/notification-context"
import { useTranslations } from "modules/translations"
import useConfig from "next/config"
import { useRouter } from "next/router"
import React, { ReactNode, useCallback, useEffect, useState } from "react"
import Banner from "ui-kit/elements/banner"
import Button from "ui-kit/elements/button"
import HyperLink from "ui-kit/elements/hyper-link"
import Logo from "ui-kit/elements/logo"
import Container from "ui-kit/layout/container"
import { ListItem, StackedListItem } from "ui-kit/lists/stacked-list"
import SidebarNavigation, { NavItem } from "ui-kit/navigation/sidebar-navigation"
import Search from "ui-kit/overlays/search"
import SliderOver from "ui-kit/overlays/slide-over"
interface PageContainerProps {
    children: ReactNode
    navigation: NavItem[]
}

/**
 * The container for a standard page layout.
 */
export function PageContainer ( props: PageContainerProps ): JSX.Element {

    const { pathname } = useRouter()
    const { originalUser, user } = useAuth()
    const [sidebarOpen, setSidebarOpen] = useState( false )
    const { welcomeBannerRoute } = useConfig().publicRuntimeConfig as { welcomeBannerRoute: string}

    useEffect( () => {
        // When the pathname changes, close the menus.
        setSidebarOpen( false )
    }, [pathname] )

    return (
        <div className="flex h-screen overflow-hidden bg-gray-100">
            <MobileSidebar navigation={props.navigation} open={sidebarOpen} setOpen={setSidebarOpen} />
            <DesktopSidebar navigation={props.navigation} />

            <div className="flex flex-col flex-1 w-0 overflow-hidden">
                <Navbar open={sidebarOpen} setOpen={setSidebarOpen} />

                {/* In the case of an employee impersonating a user, show a banner. */}
                {user && originalUser && <>
                    <ImpersonationBanner username={user.name} />
                </>}
                {welcomeBannerRoute === pathname && <>
                    <WelcomeBanner />
                </>}

                <main className="relative flex flex-col flex-1 overflow-y-auto">
                    <Container className="flex-1 w-full pt-2 pb-6 mt-4 md:py-6 lg:mt-8">
                        {props.children}
                    </Container>
                </main>
            </div>
        </div>
    )
}
interface MobileSidebarProps {
    open: boolean
    setOpen: ( open: boolean ) => void
    navigation: NavItem[]
}
function MobileSidebar ( { open, setOpen, navigation }: MobileSidebarProps ): JSX.Element {
    return <div className="md:hidden">
        <Transition show={open}>
            <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    {
                        ( ref ) =>
                            <div ref={ref} className="fixed inset-0">
                                <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
                            </div>
                    }
                </Transition.Child>

                <Transition.Child
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                    {
                        ( ref ) =>
                            <div ref={ref} className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-gray-700">
                                <div className="absolute top-0 right-0 p-1 -mr-14">
                                    <button className="flex items-center justify-center w-12 h-12 rounded-full focus:bg-gray-600" aria-label="Close sidebar" onClick={() => setOpen( !open )}>
                                        <svg className="w-6 h-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="flex items-center flex-shrink-0 px-4">
                                    <Logo />
                                </div>
                                <div className="flex-1 h-0 mt-5 overflow-y-auto">
                                    <SidebarNavigation items={navigation} />
                                </div>
                            </div>
                    }
                </Transition.Child>
            </div>
        </Transition>
        <div className="fixed inset-0 flex">
            <div className="flex-shrink-0 w-14">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
        </div>
    </div>
}

function DesktopSidebar ( { navigation }: { navigation: NavItem[] } ): JSX.Element {
    const { defaultRoute } = useConfig().publicRuntimeConfig as { defaultRoute: string }

    return <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-grow h-16 overflow-y-auto border-r border-brand-600 bg-brand-500">
                <div className="flex items-center flex-shrink-0 h-16 px-4">
                    <HyperLink href={defaultRoute}>
                        <Logo />
                    </HyperLink>
                </div>
                <div className="flex flex-col flex-grow pt-5 bg-gray-700 border-t border-gray-800">
                    <SidebarNavigation items={navigation} />
                </div>
            </div>
        </div>
    </div>
}

function Navbar ( { open, setOpen }: { open: boolean, setOpen: ( open: boolean ) => void } ): JSX.Element {
    const { blocks } = useGlobalSearchData()
    const [showSearchOverlay, setShowSearchOverlay] = useState<boolean>( false )
    const [showNotificationBar, setShowNotificationBar] = useState<boolean>( false )
    function toggle ( ): void {
        setShowSearchOverlay( !showSearchOverlay )
    }

    const searchData = blocks.map( block => block.items ).flat()

    function openOrCloseNotifcationBar (): void {
        setShowNotificationBar( !showNotificationBar )
    }

    return <div className="relative z-10 flex flex-shrink-0 h-16 shadow bg-brand-500">
        {/* Button for opening the sidebar on mobile devices */}
        <button className="px-4 border-r border-brand-600 text-brand-200 focus:bg-brand-600 focus:text-brand-200 md:hidden" aria-label="Open sidebar" onClick={() => setOpen( !open )}>
            <svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
            </svg>
        </button>
        <div className="flex justify-end flex-1 px-4 text-brand-50">
            <div className="flex flex-row items-center justify-between ml-4 md:ml-6">
                {showSearchOverlay && <Search data={searchData} maxResults={5} show={showSearchOverlay} onClose={() => setShowSearchOverlay( false )} ></Search>}
                {showNotificationBar && <Notifications showNotificationBar={showNotificationBar} setShowNotificationBar={setShowNotificationBar}></Notifications>}
                <Button icon={SearchIcon} className="relative" onClick={toggle}>Search</Button>
                <div className="text-center cursor-pointer hover:bg-brand-600 focus:bg-brand-600 ml-3 relative" aria-label="Notifications" aria-hidden="true" aria-haspopup="true" onClick={openOrCloseNotifcationBar}><Bell /></div>
                <ProfileMenu />
            </div>
        </div>
    </div>
}

function Notifications ( props: { showNotificationBar: boolean, setShowNotificationBar: ( newState: boolean ) => void } ): JSX.Element {
    const { history, clearHistory } = useNotifications()
    const { translate } = useTranslations()
    const title = translate( "general.notifications.notification" )

    const button = <button
        type="button"
        className="mr-6 text-gray-400 bg-white rounded-md focus:ring-2 focus:ring-indigo-500"
        title={translate( "general.notifications.delete-notifications" )}
        onClick={() => clearHistory()}
        aria-label="Delete notifications"
    >

        <span className="sr-only">{translate( "general.notifications.delete-notifications" )}</span>
        <Trash className="w-5 h-5 " aria-hidden="true" />
    </button>

    const cleanHistory = history.filter( item => item.children !== translate( "modules.api-client.http_unauthorized_notification" ) )

    return <SliderOver
        title={title}
        show={props.showNotificationBar}
        set={props.setShowNotificationBar}
        button={button}
        items={cleanHistory.map( ( notification, index ) => <NotificationHistoryItem notification={notification} key={index} /> )} />
}

function NotificationHistoryItem ( props: { notification: HistoricNotificationProps } ): JSX.Element {
    const time = new Date( props.notification.time )

    const titleElement = <span className="break-words whitespace-normal">{props.notification.children}</span>

    const historyData: ListItem = {
        title: titleElement,
        detail: props.notification.type === "success" ? <CheckCircle className="w-5 h-5 text-green-500" /> : <TimesOctagon className="w-5 h-5 text-red-500" />,
        description: time.toLocaleString().replace( ",", "" ),
    }

    return <StackedListItem item={historyData} />
}

function ProfileMenu (): JSX.Element {
    const { pathname } = useRouter()
    const { user, customer, customers } = useAuth()
    const { translate } = useTranslations()
    const [menuOpen, setMenuOpen] = useState( false )
    const avatarUrl = `https://www.gravatar.com/avatar/${md5( customer!.email )}?d=mp`
    const showCustomerSwitch = customers.length > 1
    useEffect( () => {
        setMenuOpen( false )
    }, [pathname] )

    return <div className="relative ml-3">
        <div>
            <button className="flex items-center max-w-xs px-4 my-1 -mr-3 space-x-4 text-sm rounded h-14 hover:bg-brand-600 focus:bg-brand-600" id="user-menu"
                aria-label={translate( "ui-kit.layout.page-container.user-menu" )}
                aria-haspopup="true" onClick={() => setMenuOpen( !menuOpen )}
            >
                <img className="w-8 h-8 rounded-full" src={avatarUrl} alt="" />
                <div className="flex flex-col text-left">
                    <div className="font-semibold">{customer?.first_name} {customer?.last_name}</div>
                    {( user?.customers ?? [] ).length > 1 &&
                        <div className="text-brand-300">{customer?.full_name}</div>}
                </div>
            </button>
        </div>
        <Transition
            show={menuOpen}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            {
                ( ref ) =>
                    <div ref={ref} className="absolute right-0 w-48 mt-2 origin-top-right rounded-md shadow-lg">
                        <div className="py-1 bg-white rounded-md shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                            {showCustomerSwitch && <HyperLink href="/switch-customer" className="block px-4 py-2 text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100" variant="unstyled">
                                {translate( "general.navigation.customer-switch" )}
                            </HyperLink>}


                            <HyperLink href="/profile" className="block px-4 py-2 text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100" variant="unstyled">
                                {translate( "general.navigation.profile" )}
                            </HyperLink>
                            <HyperLink href="/logout" className="block px-4 py-2 text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100" variant="unstyled">
                                {translate( "general.navigation.logout" )}
                            </HyperLink>
                        </div>
                    </div>
            }
        </Transition>
    </div>
}

function ImpersonationBanner ( { username }: { username: string } ): JSX.Element {
    const { translate } = useTranslations()
    const { push } = useRouter()
    const { novaUrl } = useConfig().publicRuntimeConfig as { novaUrl: string }
    const { logout } = useAuth()
    const { logoutRequest } = useAuthApi()

    function backToNova (): void {
        doLogout()
        push( novaUrl )
    }
    const doLogout = useCallback( (): void => {
        logoutRequest().then( () => {
            logout()
        } ).catch( () => {
            logout()
        } )
    }, [logout, logoutRequest] )

    const backButton = <>
        <Button icon={ChevronRight} onClick={backToNova} className="ml-3">
            {translate( "ui-kit.layout.page-container.nova_back_button" )}
        </Button>
    </>

    return <>
        <Banner button={backButton} shortText={translate( "ui-kit.layout.page-container.impersonation_header_short" ) + username}>
            {translate( "ui-kit.layout.page-container.impersonation_header" ) + username}
        </Banner>
    </>
}


function WelcomeBanner (): JSX.Element {
    const { customer } = useAuth()
    const { translate } = useTranslations()
    const avatarUrl = `https://www.gravatar.com/avatar/${md5( customer!.email )}?d=mp`

    return <div className="px-4 py-8 bg-white border-b border-gray-200 shadow-md">
        <Container size="normal">
            <div className="md:flex md:items-center md:justify-between">
                <div className="flex items-start space-x-5">
                    <div className="flex-shrink">
                        <div className="relative">
                            <img className="w-16 h-16 rounded-full" src={avatarUrl} alt="" />
                            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></span>
                        </div>
                    </div>
                    <div className="pt-1.5">
                        <h1 className="text-2xl font-bold text-gray-900">{translate( "pages.services.welcome-banner", { first_name: customer?.first_name ? customer.first_name : "" } )}</h1>
                        <p className="text-sm font-medium text-gray-500">
                            {customer?.organization ? <span className="mr-4"><Building className="text-gray-500" /> {customer.organization}</span> : <></>}
                            {customer?.is_validated
                                ? <span>
                                    <CheckCircle className="mr-1 text-green-500" />{translate( "pages.services.verified-customer" )}
                                </span>
                                : <span>
                                    <TimesCircle className="text-red-500" /> {translate( "pages.services.unverified-customer" )}
                                </span>}
                            {customer?.is_reseller && <span className="ml-4"><CheckCircle className="mr-1 text-green-500" />{translate( "pages.services.reseller" )}</span>}
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    </div>
}
