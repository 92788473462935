import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.hosting-mail.mail_addresses": {
        "en-US": "Mail addresses",
        "nl-NL": "Mailadressen",
    },
    "pages.hosting-mail.connection_data": {
        "en-US": "Connection data",
        "nl-NL": "Verbindingsgegevens",
    },

    "pages.hosting-mail.see": {
        "en-US": "Show details",
        "nl-NL": "Inzien",
    },
    "pages.hosting-mail.manage": {
        "en-US": "Manage accounts",
        "nl-NL": "Beheren",
    },
    "pages.hosting-mail.reset-user": {
        "en-US": "Reset password",
        "nl-NL": "Wachtwoord instellen",
    },

    "pages.hosting-mail.imap": {
        "en-US": "IMAP",
        "nl-NL": "IMAP",
    },
    "pages.hosting-mail.imap_server": {
        "en-US": "IMAP server",
        "nl-NL": "IMAP server",
    },
    "pages.hosting-mail.imap_port": {
        "en-US": "IMAP port",
        "nl-NL": "IMAP poort",
    },
    "pages.hosting-mail.pop3": {
        "en-US": "POP3",
        "nl-NL": "POP3",
    },
    "pages.hosting-mail.pop3_server": {
        "en-US": "POP3 server",
        "nl-NL": "POP3 server",
    },
    "pages.hosting-mail.pop3_port": {
        "en-US": "POP3 port",
        "nl-NL": "POP3 poort",
    },
    "pages.hosting-mail.smtp": {
        "en-US": "SMTP",
        "nl-NL": "SMTP",
    },
    "pages.hosting-mail.smtp_server": {
        "en-US": "SMTP server",
        "nl-NL": "SMTP server",
    },
    "pages.hosting-mail.smtp_port": {
        "en-US": "SMTP port",
        "nl-NL": "SMTP poort",
    },
    "pages.hosting-mail.username": {
        "en-US": "Username",
        "nl-NL": "Gebruikersnaam",
    },
    "pages.hosting-mail.password": {
        "en-US": "Password",
        "nl-NL": "Wachtwoord",
    },
    "pages.hosting-mail.encryption": {
        "en-US": "Encryption",
        "nl-NL": "Encryptie",
    },
    "pages.hosting-mail.connection": {
        "en-US": "Connection",
        "nl-NL": "Verbinding",
    },
    "pages.hosting-mail.your-email": {
        "en-US": "your-email@{domain}",
        "nl-NL": "jouw-email@{domain}",
    },

    "pages.hosting-mail.send-and-receive": {
        "en-US": "Send and receive mail",
        "nl-NL": "Mail verzenden en ontvangen",
    },
    "pages.hosting-mail.dns-configuration": {
        "en-US": "DNS configuration",
        "nl-NL": "DNS instellingen",
    },

    "pages.hosting-mail.spamexperts-cta": {
        "en-US": "Manage spam filter",
        "nl-NL": "Spam filter instellen",
    },
    "pages.hosting-mail.spamexperts": {
        "en-US": "SpamExperts",
        "nl-NL": "SpamExperts",
    },

    "pages.hosting-mail.column-type": {
        "en-US": "Type",
        "nl-NL": "Type",
    },
    "pages.hosting-mail.column-name": {
        "en-US": "Name",
        "nl-NL": "Naam",
    },
    "pages.hosting-mail.column-prio": {
        "en-US": "Priority",
        "nl-NL": "Prioriteit",
    },
    "pages.hosting-mail.column-value": {
        "en-US": "Value",
        "nl-NL": "Waarde",
    },

    "pages.hosting-mail.users": {
        "en-US": "Users",
        "nl-NL": "Gebruikers",
    },
    "pages.hosting-mail.user": {
        "en-US": "User",
        "nl-NL": "Gebruiker",
    },
    "pages.hosting-mail.add-user": {
        "en-US": "Add user",
        "nl-NL": "Gebruiker toevoegen",
    },
    "pages.hosting-mail.confirm-delete": {
        "en-US": "Are you sure?",
        "nl-NL": "Weet je het zeker?",
    },
    "pages.hosting-mail.confirm-delete-question": {
        "en-US": "Are you sure you want to delete {mail}? All data will be deleted, including e-mails.",
        "nl-NL": "Weet je zeker dat je {mail} wilt verwijderen? Alle data zal worden verwijderd, inclusief e-mails.",
    },

} as TranslationDictionary
