import { Transition } from "@headlessui/react"
import { ChevronDown, ChevronUp } from "icons/font-awesome/solid"
import { useTranslations } from "modules/translations"
import React, { ReactNode, useCallback, useEffect, useState } from "react"
import Button from "ui-kit/elements/button"
import Spinner from "ui-kit/elements/spinner"

export type collapseType = "button" | "icon"

export interface PanelProps {
    /**
     * An optional title, which will be shown as an `<h2>`
     */
    title?: ReactNode

    /**
     * An optional subtitle (use only with title), which will be shown as an `<p>`
     */
    subTitle?: ReactNode

    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    /**
     * Children that are displayed in the panel
     */
    children: ReactNode

    /**
     * Allows the panel to be collapsed
     */
    collapseable?: boolean

    /**
     * If the panel is collapseable this determines the style of the collapse activation
     */
    collapseType?: collapseType

    /**
     * Displays a spinner
     */
    loading?: boolean

}

/**
 * The `Panel` is the standard container for a section of the main content. A
 * typical page will have one or more of these. It can have an optional `<h2>`
 * title.
 */
export default function Panel ( props: PanelProps ): JSX.Element {
    const { translate } = useTranslations()
    const [isExpanded, setIsExpanded] = useState<boolean>( false )
    const [expandButtonText, setExpandButtonText] = useState<string>( translate( "general.expand" ) )

    let titleElement: JSX.Element | undefined = undefined
    let subTitleElement: JSX.Element | undefined = undefined
    if ( props.subTitle ) {
        subTitleElement = <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-700">
            {props.subTitle}
        </p>
    }

    let collapseElement: JSX.Element | undefined = undefined

    useEffect( () => {
        /**
         * To ensure the enter animation plays, only start showing the item
         * after the initial render.
         * TODO:: Check if this is still required after updating Tailwind, react, and-or headlessui.
         * TICKET:: https://sandwaveio.atlassian.net/browse/WATER-52
         */
        setTimeout( () => {setIsExpanded( true ); setExpandButtonText( translate( "general.collapse" ) )} )
    }, [setIsExpanded, setExpandButtonText, translate] )

    /**
     * expand the panel so its content is visible
     */
    const expandPanel = useCallback( (): void => {
        setIsExpanded( true )
        setExpandButtonText( translate( "general.collapse" ) )
    }, [setIsExpanded, setExpandButtonText, translate] )

    /**
     * Collapse the panel so its content is not visible
     */
    const collapsePanel = useCallback( (): void => {
        setIsExpanded( false )
        setExpandButtonText( translate( "general.expand" ) )
    }, [setIsExpanded, setExpandButtonText, translate] )

    function collapseToggle (): void{
        if( !isExpanded ){
            expandPanel()
        } else {
            collapsePanel()
        }
    }

    if( props.collapseable ) {
        switch( props.collapseType ){
            case "icon":
                collapseElement =
                    <div onClick={collapseToggle} aria-hidden="true">{
                        isExpanded ?
                            <ChevronUp aria-label={expandButtonText} className="text-brand-500" />
                            :
                            <ChevronDown aria-label={expandButtonText} className="text-brand-500" />
                    }</div>
                break
            case "button":
                collapseElement = <Button variant="white" size="sm" onClick={collapseToggle}>{expandButtonText}</Button>
                break
        }
    }

    if ( props.title ) {
        titleElement =
            <div className="border-b border-gray-200 px-4 py-5 sm:px-6">
                <div className="flex flex-row justify-between">
                    <div className="flex-none">
                        <h2 className="text-lg leading-6 font-medium text-text-gray-800">
                            {props.title}
                        </h2>
                        {subTitleElement}
                    </div>
                    {props.collapseable &&
                        <div className="flex-shrink px-3">
                            {collapseElement}
                        </div>}
                </div>
            </div>
    }

    return (
        <div className={`bg-white overflow-hidden shadow rounded-lg mb-8 ${props.className || ""}`}>
            {titleElement}
            {props.collapseable ?
                <Transition
                    show={isExpanded}
                    as="div"
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <PanelContent loading={props.loading}>{props.children}</PanelContent>
                </Transition>
                :
                <PanelContent loading={props.loading}>{props.children}</PanelContent>}


        </div>
    )
}

// This contains the content of the panel and the loading component.
export function PanelContent ( props: { children?: ReactNode, loading?: boolean } ): JSX.Element {
    const childElement = props.children

    return <div className="px-4 py-5 sm:p-6">
        {props.loading ? <LoadingComponent /> : childElement}
    </div>
}

export function LoadingComponent (): JSX.Element {
    return <div className="flex flex-row items-center justify-center h-32">
        <Spinner className="text-3xl text-brand-500" />
    </div>
}
