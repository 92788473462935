import React, { ReactNode } from "react"

export interface ParagraphProps {
    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    children: ReactNode
}

/**
 * A simple paragraph.
 */
export default function Paragraph ( props: ParagraphProps ): JSX.Element {
    return (
        <p className={`mb-4 max-w-xl text-gray-500 ${props.className || ""}`}>
            {props.children}
        </p>
    )
}
