import { ChevronRight } from "icons/font-awesome/light"
import Link from "next/link"
import React from "react"

export interface StackedListProps {
    /**
     *  List with items which are used to build the stacked list.
     */
    items: ListItem[]

    /**
     * This controls the loading state of the component
     */
    loading?: boolean
}

/**
 * This function maps over the propped items and displays them in a stacked list.
 */
export default function StackedList ( props: StackedListProps ): JSX.Element {
    return <div className="bg-white shadow overflow-hidden sm:rounded-md col-span-2">
        <ul className="divide-y divide-gray-200">
            {! props.loading && props.items.length >= 1 ?
                props.items.map( ( item, key ) => {
                    return <StackedListItem item={item} key={key} />
                } )
                :
                <li className="animate-pulse">
                    <div className="block hover:bg-gray-50">
                        <div className="px-4 py-4 sm:px-6">
                            <div className="flex flex-row space-x-2">
                                <div className="flex w-8/12 h-2 bg-gray-300 opacity-75 rounded-md"></div>
                            </div>
                            <div className="mt-2 sm:flex sm:justify-between">
                                <div className="flex w-6/12 h-2 bg-gray-300 opacity-70 rounded-md"></div>
                            </div>
                            <div className="mt-2 sm:flex sm:justify-between">
                                <div className="flex w-full h-2 bg-gray-300 opacity-65 rounded-md"></div>
                            </div>
                        </div>
                    </div>
                </li>}
        </ul>
    </div>
}


export interface ListItem {
    title: JSX.Element|string
    location?: string
    description?: JSX.Element|string
    detail?: JSX.Element|string
}
export function StackedListItem ( props: {item: ListItem} ): JSX.Element{
    return <li className="block hover:bg-gray-50 cursor-pointer">
        <Link href={props.item.location ?? ""}>
            <div className="px-4 py-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                        <div className="flex text-sm font-medium text-brand-600 truncate">
                            <p>{props.item.title}</p>
                        </div>
                        <div className="mt-2 flex">
                            <div className="flex items-center text-sm text-gray-500 h-3">
                                <span className="truncate">
                                    {props.item.description ?? ""}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 flex-shrink-0 sm:mt-0">
                        <div className="flex overflow-ellipsis truncate text-sm text-gray-500">
                            {props.item.detail}
                        </div>
                    </div>
                </div>
                <div className="ml-5 flex-shrink-0">
                    {props.item.location ? <ChevronRight className="text-brand-500"/> : ""}
                </div>
            </div>
        </Link>
    </li>
}
