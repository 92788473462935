
import { DomainContact } from "modules/api-client/src/domain-contact/types"
import { CartItem, useCart } from "modules/orders"
import { useTranslations } from "modules/translations"
import React, { useCallback, useState } from "react"
import Select from "ui-kit/forms/select"

export function ConfigureContactHandle ( { domain, item, contactHandles }: {domain: string, item: CartItem, contactHandles: DomainContact[]} ): JSX.Element {
    const [contactHandle, setContactHandle] = useState<string>()
    const { translate } = useTranslations()
    const { updateItem } = useCart()

    const changeContactHandle = useCallback( ( contactHandle: string ) => {
        setContactHandle( contactHandle )
        const newItem: CartItem = {
            ...item,
            product: {
                ...item.product,
                config: {
                    ...item.product.config,
                    contact_id: contactHandle,
                },
            },
        }
        updateItem( domain, newItem )
    }, [domain, updateItem, item] )


    const contactOptions = Object.values( contactHandles )
        .map( contactHandle => [String( contactHandle.id ), contactHandle.organization ? `${contactHandle.organization}` : `${contactHandle.first_name} ${contactHandle.last_name}`] )
    contactOptions.unshift( ["0", translate( "pages.order.handle-select" ) ] )

    return <div className="mb-8">
        <Select
            className="py-2"
            id="select-contact-handle"
            name="select-contact-handle"
            label={translate( "pages.order.handle-select" )}
            onChange={( newValue ) => changeContactHandle( newValue )}
            options={contactOptions as unknown as ReadonlyArray<[string, string]>}
            value={contactHandle}
        />
    </div>
}
