import { CreateDomainContactParams } from "modules/api-client/src/domain-contact/requests/create-domain-contact-params"
import { AvailableDomainsContactResponse } from "modules/api-client/src/domain-contact/responses/available-domains-contact-response"
import { DeleteDomainContactResponse } from "modules/api-client/src/domain-contact/responses/delete-domain-contact-response"
import { DomainLinkContactResponse } from "modules/api-client/src/domain-contact/responses/domain-link-contact-response"
import { SetDefaultContactResponse } from "modules/api-client/src/domain-contact/responses/set-default-contact-response"
import { StoreDomainContactResponse } from "modules/api-client/src/domain-contact/responses/store-domain-contact-response"
import { DomainContact, DomainContactLink } from "modules/api-client/src/domain-contact/types"
import { RequestOptions, Response, useHttpClient } from "modules/api-client/src/http-client"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface DomainContactSpec {
    domainContactRequest( options?: RequestOptions ): Promise<Response<DomainContact[]>>
    fetchDomainContactRequest( domainContactId: number, options?: RequestOptions ): Promise<Response<DomainContact>>
    deleteDomainContactRequest( domainContactId: number, options?: RequestOptions ): Promise<Response<DeleteDomainContactResponse>>
    storeDomainContactRequest( parameters: CreateDomainContactParams, options?: RequestOptions ): Promise<Response<StoreDomainContactResponse>>
    setDefaultContactRequest( domainContactId: number, options?: RequestOptions ): Promise<Response<SetDefaultContactResponse>>
    fetchAvailableDomainsContactRequest( domainContactId: number, options?: RequestOptions ): Promise<Response<AvailableDomainsContactResponse>>
    linkDomainContactRequest( domainContactId: number, domains: DomainContactLink[], options?: RequestOptions ): Promise<Response<DomainLinkContactResponse>>
}

export function useDomainContactApi (): DomainContactSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { getRequest, postRequest, deleteRequest, patchRequest } = useHttpClient( baseUrl, customer )

    /**
     * Request to retrieve domain contacts.
     */
    const domainContactRequest = useCallback( ( options?: RequestOptions ): Promise<Response<DomainContact[]>> => {
        return getRequest<{ data: DomainContact[] }>( "domain-contact/contacts", {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    /**
     * Request to retrieve a single domain contact.
     */
    const fetchDomainContactRequest = useCallback( ( domainContactId: number, options?: RequestOptions ): Promise<Response<DomainContact>> => {
        return getRequest< { data: DomainContact } >( `domain-contact/contacts/${domainContactId}`, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [getRequest] )

    /**
     * Request to delete a single domain contact.
     */
    const deleteDomainContactRequest = useCallback( ( domainContactId: number, options?: RequestOptions ): Promise<Response<DeleteDomainContactResponse>> => {
        return deleteRequest<DeleteDomainContactResponse>( `domain-contact/contacts/${domainContactId}`, {}, {}, options )
    }, [deleteRequest] )

    /**
     * Request to create a single domain contact.
     */
    const storeDomainContactRequest = useCallback( ( parameters: CreateDomainContactParams, options?: RequestOptions ): Promise<Response<StoreDomainContactResponse>> => {
        return postRequest<{ data: StoreDomainContactResponse }>( "domain-contact/contacts", parameters, {}, options )
            .then( r => ( { ...r, data: r.data?.data } ) )
    }, [postRequest] )

    /**
     * Request to make contact handle default
     */
    const setDefaultContactRequest = useCallback( ( domainContactId: number, options?: RequestOptions ): Promise<Response<SetDefaultContactResponse>> => {
        return patchRequest<SetDefaultContactResponse>( `domain-contact/contacts/${domainContactId}/default`, undefined, {}, options )
    }, [patchRequest] )

    /**
     * Request to link a domain to a domain contact
     */
    const fetchAvailableDomainsContactRequest = useCallback( ( domainContactId: number, options?: RequestOptions ): Promise<Response<AvailableDomainsContactResponse>> => {
        return getRequest<AvailableDomainsContactResponse>( `domain-contact/contacts/${domainContactId}/available-domains`, {}, options )
    }, [getRequest] )

    const linkDomainContactRequest = useCallback( ( domainContactId: number, domains: DomainContactLink[], options?: RequestOptions ): Promise<Response<DomainLinkContactResponse>> => {
        const payload = { domains: domains }
        return postRequest<DomainLinkContactResponse>( `domain-contact/contacts/${domainContactId}/link`, payload, {}, options )
    }, [postRequest] )

    return {
        domainContactRequest,
        fetchDomainContactRequest,
        deleteDomainContactRequest,
        storeDomainContactRequest,
        setDefaultContactRequest,
        fetchAvailableDomainsContactRequest,
        linkDomainContactRequest,
    }
}
