import { CheckDomainNameParams } from "modules/api-client/src/domain-name/requests/check-domain-name-params"
import { DomainCheckResult } from "modules/api-client/src/domain-name/types"
import { Response, useHttpClient } from "modules/api-client/src/http-client"
import { useAuth } from "modules/auth"
import useConfig from "next/config"
import { useCallback } from "react"

interface DomainNameApiSpec {
    checkDomainRequest( parameters: CheckDomainNameParams ): Promise<Response<DomainCheckResult>>
}

export function useDomainNameApi (): DomainNameApiSpec {

    const { customer } = useAuth()
    const baseUrl = ( useConfig()?.publicRuntimeConfig?.apiBaseUrl as string | undefined ) || "http://localhost/api"
    const { postRequest } = useHttpClient( baseUrl, customer )

    const checkDomainRequest = useCallback( ( parameters: CheckDomainNameParams ): Promise<Response<DomainCheckResult>> => {
        return postRequest<DomainCheckResult>( "domain-name/check", parameters )
    }, [postRequest] )


    return {
        checkDomainRequest,
    }
}
