import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "pages.domain-contact.index.page-header": {
        "en-US": "Domain Contacts",
        "nl-NL": "Domeinhouders",
    },
    "pages.domain-contact.index.table-header": {
        "en-US": "Domain Contact",
        "nl-NL": "Domeinhouder",
    },
    "pages.domain-contact.index.button-add-domain-contact": {
        "en-US": "Add domain contact",
        "nl-NL": "Domeinhouder aanmaken",
    },
    "pages.domain-contact.index.domain-contact-name": {
        "en-US": "Name",
        "nl-NL": "Naam",
    },
    "pages.domain-contact.index.amount-domains-linked": {
        "en-US": "Domains attached",
        "nl-NL": "Gekoppelde domeinen",
    },
    "pages.domain-contact.index.default": {
        "en-US": "Default",
        "nl-NL": "Standaard",
    },
    "pages.domain-contact.table_header.actions": {
        "en-US": "Actions",
        "nl-NL": "Acties",
    },
    "pages.domain-contact.index.table-default": {
        "en-US": "Make Default",
        "nl-NL": "Maak standaard",
    },
    "pages.domain-contact.index.modal.title": {
        "en-US": "Change default contact",
        "nl-NL": "Verander standaard houder",
    },
    "pages.domain-contact.index.modal.description": {
        "en-US": "You are about to change your default domain contact. Are you sure?",
        "nl-NL": "U staat op het punt om uw standaard domein houder te veranderen. Weet u het zeker?",
    },
    "pages.domain-contact.modal.make-default": {
        "en-US": "Make default",
        "nl-NL": "Maak standaard",
    },
} as TranslationDictionary
