import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {
    "general.notifications.notification": {
        "en-US": "Notifications",
        "nl-NL": "Notificaties",
    },
    "general.notifications.delete-notifications": {
        "en-US": "Delete all notifications",
        "nl-NL": "Verwijder alle notificaties",
    },
    "general.notifications.close-panel": {
        "en-US": "Close panel",
        "nl-NL": "Paneel sluiten",
    },
} as TranslationDictionary
