import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.users.page_heading": {
        "en-US": "Users",
        "nl-NL": "Gebruiker",
    },
    "pages.users.retrieval_error": {
        "en-US": "Currently unable to retrieve users data. Please try again later.",
        "nl-NL": "Momenteel niet mogelijk om gebruikersgevens op te halen. Probeer het later nog eens.",
    },
    "pages.users.button_download_csv": {
        "en-US": "Download CSV",
        "nl-NL": "Download CSV",
    },
    "pages.users.button_add": {
        "en-US": "Add",
        "nl-NL": "Toevoegen",
    },
    "pages.users.table_header_fname": {
        "en-US": "First name",
        "nl-NL": "Voornaam",
    },
    "pages.users.table_header_lname": {
        "en-US": "Last name",
        "nl-NL": "Achternaam",
    },
    "pages.users.table_header_email": {
        "en-US": "Email",
        "nl-NL": "Email",
    },
    "pages.users.table_header_role": {
        "en-US": "Role",
        "nl-NL": "Rol",
    },
    "pages.users.table_delete_user": {
        "en-US": "Delete",
        "nl-NL": "Verwijder",
    },
    "pages.users.modal_delete_confirm_title": {
        "en-US": "Delete user",
        "nl-NL": "Verwijder gebruiker",
    },
    "pages.users.modal_delete_confirm_text": {
        "en-US": "Are you sure you want to delete this user?",
        "nl-NL": "Weet je zeker dat je deze gebruiker wilt verwijderen?",
    },
    "pages.users.modal_delete_confirm_confirm": {
        "en-US": "Delete",
        "nl-NL": "Verwijder",
    },
    "pages.users.modal_delete_confirm_dismiss": {
        "en-US": "Cancel",
        "nl-NL": "Terug",
    },
    "pages.users.user_deleted": {
        "en-US": "User successfully deleted",
        "nl-NL": "Gebruiker succesvol verwijderd",
    },
    "pages.users.user_deleted_error": {
        "en-US": "Unable to delete user",
        "nl-NL": "Gebruiker verwijderen mislukt",
    },
    "pages.users.table_header_two_fa": {
        "en-US": "2FA",
        "nl-NL": "2FA",
    },

} as TranslationDictionary
