import { TranslationDictionary } from "modules/translations/src/translation-context"

export default {

    "pages.domain.ssl.index.subscription-status": {
        "en-US": "Subscription status",
        "nl-NL": "Abonnementstatus",
    },
    "pages.domain.ssl.index.renewal-date": {
        "en-US": "Renewal date",
        "nl-NL": "Verlengdatum",
    },
    "pages.domain.ssl.index.period": {
        "en-US": "Duration",
        "nl-NL": "Looptijd",
    },
    "pages.domain.ssl.index.period-suffix": {
        "en-US": "months",
        "nl-NL": "maanden",
    },
    "pages.domain.ssl.index.status-active": {
        "en-US": "Active",
        "nl-NL": "Actief",
    },
    "pages.domain.ssl.index.status-canceled": {
        "en-US": "Canceled",
        "nl-NL": "Opgezegd",
    },
    "pages.domain.ssl.index.status-deleted": {
        "en-US": "Deleted",
        "nl-NL": "Verwijderd",
    },
    "pages.domain.ssl.index.upgrade_text": {
        "en-US": "The domain is unprotected. Take your website to the next level!",
        "nl-NL": "Het domein is onbeveiligd. Haal meer uit je website!",
    },
    "pages.domain.ssl.index.ssl-type": {
        "en-US": "SSL type",
        "nl-NL": "Type SSL",
    },
    "pages.domain.ssl.index.order": {
        "en-US": "Order an SSL certificate",
        "nl-NL": "Bestel een SSL certificaat",
    },
    "pages.domain.ssl.index.download": {
        "en-US": "Download SSL certificate",
        "nl-NL": "Download SSL certificaat",
    },
    "pages.domain.ssl.index.install": {
        "en-US": "Automatically install certificate",
        "nl-NL": "Certificaat automatisch installeren",
    },
    "pages.domain.ssl.index.private-key": {
        "en-US": "Upload private key",
        "nl-NL": "Private key uploaden",
    },
    "pages.domain.ssl.index.reissue": {
        "en-US": "Reissue the certificate",
        "nl-NL": "Heruitgifte van het certificaat",
    },
    "pages.domain.ssl.index.reissue-button": {
        "en-US": "Reissue",
        "nl-NL": "Heruitgifte aanvragen",
    },
    "pages.domain.ssl.index.download-button": {
        "en-US": "Download",
        "nl-NL": "Download",
    },
} as TranslationDictionary
