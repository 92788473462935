import { Tasks } from "icons/font-awesome/regular"
import { ResponseError, useSslApi } from "modules/api-client"
import { useNotifications } from "modules/notifications"
import { CartItem, useCart } from "modules/orders"
import { useTranslations } from "modules/translations"
import React, { useCallback, useState } from "react"
import Button from "ui-kit/elements/button"
import Errors from "ui-kit/elements/errors"
import Textarea from "ui-kit/forms/textarea"
import Toggle from "ui-kit/forms/toggle"

export function ConfigureSsl ( { domain, item }: {domain: string, item: CartItem} ): JSX.Element {
    const [manualCsr, setManualCsr] = useState( false )
    const { validateCsrRequest } = useSslApi()
    const [error, setError] = useState<ResponseError>()
    const [csr, setCsr] = useState( "" )
    const { translate } = useTranslations()
    const { pushNotification } = useNotifications()
    const { updateItem } = useCart()

    const validateCsr = useCallback( () => {
        validateCsrRequest( { domain, csr } ).then( response => {
            if ( !response.success ) {
                setError( response.error )
            } else {
                setError( undefined )
                const newItem: CartItem = {
                    ...item,
                    product: {
                        ...item.product,
                        config: {
                            ...item.product.config,
                            csr: csr,
                            generateCsr: false,
                        },
                    },
                }
                updateItem( domain, newItem )
                pushNotification( {
                    type: "success",
                    children: translate( "pages.order.verify-csr-success" ),
                } )
            }
        } )
    }, [validateCsrRequest, domain, csr, pushNotification, translate, updateItem, item] )

    return <div className="mb-8">
        <Toggle enabled onToggle={() => setManualCsr( !manualCsr )}>
            {translate( "pages.order.ssl-automatic-install" )}
        </Toggle>

        {manualCsr &&
            <div className="mt-4">
                <Errors id="upload_ssl_errors" errors={error ? [error.message] : []} />
                <Textarea
                    id="csr"
                    name="csr"
                    label={translate( "pages.order.ssl-csr-input" )}
                    onChange={( newValue ) => setCsr( newValue )}
                    value={csr}
                />

                <Button icon={Tasks} onClick={validateCsr} className="mt-5">
                    {translate( "pages.order.ssl-csr-validate" )}
                </Button>
            </div>}

    </div>
}
