import { ExclamationCircle } from "icons/font-awesome/solid"
import React from "react"

export interface ErrorsProps {

    /**
     * A unique identifier for this list of errors
     */
    id?: string

    /**
     * The single error or list of errors to display. Can be empty.
     */
    errors?: string | string[]

    /**
     * Additional CSS classes to add to this component.
     */
    className?: string
}

/**
 * Shows a list of errors as a vertical list of red text preceeded by
 * exclamation icons. Uses an assertive ARIA live region to ensure the errors
 * are announced. Uses a transition to prevent sudden layout shifts when error
 * messages first appear.
 */
export default function Errors ( props: ErrorsProps ): JSX.Element {

    const errors = !props.errors ? [] : typeof props.errors === "string" ? [props.errors] : props.errors

    return <div id={props.id} aria-live="assertive" aria-atomic="true" data-testid="errors">
        <ul className={`overflow-y-hidden text-red-600 text-sm ${props.className || ""}`}>
            {errors.map( ( error, i ) => <li key={i} className={`${i === 0 ? "mt-2" : ""} ${i === errors.length - 1 ? "mb-2" : ""}`} data-testid="error">
                <ExclamationCircle className="mr-2" style={{ fill: "currentColor" }} aria-hidden="true" />
                {error}
            </li> )}
        </ul>
    </div>
}
